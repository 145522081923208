define('webapp/mixins/invalidate-authentication', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Mixin.create({

    session: inject.service(),

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      if (this.get('session.isAuthenticated')) {
        this.set('session.invalidateReloadUrl', '' + window.location.pathname + window.location.search);
        this.get('session').invalidate();
      }
    }
  });
});