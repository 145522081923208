define('webapp/models/location', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _validator;

  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    organization: {
      descriptionKey: "organizationMenuLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'),
      // Don't include belongs-to for validation
      (0, _emberCpValidations.validator)('presence', true)]
    },

    address: {
      descriptionKey: "addressLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('belongs-to', {
        disabled: computed.not('model.address.country')
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true
      })]
    },

    source_type: {
      descriptionKey: "typeHeader",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', { in: ['pickup', 'dropoff', 'both'] })]
    },

    company_name: {
      descriptionKey: "companyNameLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 150 })]
    },

    contact_name: {
      descriptionKey: "contactNameLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 100 })]
    },

    contact_number: {
      descriptionKey: "contactNumberLabel",
      value: function value(model, attribute) {
        var contact_number = this.get('model.contact_number');
        // Format the original value before passing it into the validator
        return contact_number && contact_number.replace(/[^0-9]/gi, '');
      },

      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 10,
        max: 12
      })]
    },

    instructions: {
      descriptionKey: "instructionLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', { max: 500 })]
    },

    has_lifting_equipment: {
      descriptionKey: "hasLiftingEquipmentLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', { in: [true, false] })]
    },

    has_loading_dock: {
      descriptionKey: "hasLoadingDockLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', { in: [true, false] })]
    },

    appointment_required: {
      descriptionKey: "appointmentRequired",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', { in: [true, false] })]
    },

    appointment_contact_name: {
      descriptionKey: "appointmentContactName",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', { max: 100 }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed.not('model.appointment_required'),
        dependentKeys: ['appointment_required']
      })]
    },

    appointment_contact_number: {
      descriptionKey: "appointmentContactNumber",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed.not('model.appointment_required'),
        dependentKeys: ['appointment_required']
      })]
    },

    'undeleted_operating_hours.length': {
      descriptionKey: "operationHours",
      validators: [(0, _emberCpValidations.validator)('number', (_validator = {
        gt: 0,
        messageKey: "errors.descriptionRequired",
        dependentKeys: ['undeleted_operating_hours.[]'],
        disabled: computed.not('model.appointment_required')
      }, _validator['dependentKeys'] = ['appointment_required'], _validator))]
    }
  });

  exports.default = _model.default.extend(Validations, {

    source_type: (0, _attr.default)(),
    company_name: (0, _attr.default)(),
    contact_name: (0, _attr.default)(),
    contact_number: (0, _attr.default)(),
    misc_fee: (0, _attr.default)('number', { defaultValue: 0 }),
    late_fee: (0, _attr.default)('number', { defaultValue: 0 }),
    entrance_fee: (0, _attr.default)('number', { defaultValue: 0 }),
    unloading_fee: (0, _attr.default)('number', { defaultValue: 0 }),
    pallet_exchange_fee: (0, _attr.default)('number', { defaultValue: 0 }),
    instructions: (0, _attr.default)(),
    appointment_required: (0, _attr.default)('boolean', { defaultValue: false }),
    appointment_contact_name: (0, _attr.default)(),
    appointment_contact_number: (0, _attr.default)(),
    has_lifting_equipment: (0, _attr.default)('boolean', { defaultValue: false }),
    has_loading_dock: (0, _attr.default)('boolean', { defaultValue: false }),
    created_at: (0, _attr.default)('datetime-utc'),
    updated_at: (0, _attr.default)('datetime-utc'),
    status: (0, _attr.default)({ defaultValue: 'active' }),

    organization: (0, _relationships.belongsTo)({ async: false }),
    address: (0, _relationships.belongsTo)('organization-address', { async: false }),
    operating_hours: (0, _relationships.hasMany)('location-operating-hour'),
    operations: (0, _relationships.hasMany)('load-operation', { async: false }),

    // computed properties

    only_pickup: computed.equal('source_type', 'pickup'),

    only_dropoff: computed.equal('source_type', 'dropoff'),

    both: computed.equal('source_type', 'both'),

    is_pickup: computed.or('only_pickup', 'both'),

    is_dropoff: computed.or('only_dropoff', 'both'),

    undeleted_operating_hours: computed.filterBy('operating_hours', 'isDeleted', false)

  });
});