define('webapp/pods/organization/locations/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    controllerName: 'organization.locations.edit',
    templateName: 'organization.locations.edit',

    model: function model() {
      return Ember.RSVP.hash({
        location: this.store.createRecord('location', {
          organization: this.get('session.currentOrganization'),
          address: this.store.createRecord('organization-address', {
            organization: this.get('session.currentOrganization'),
            country: this.get('session.currentOrganization.address.country')
          })
        })
      });
    },
    setupController: function setupController(controller, hash) {
      controller.set('location', hash.location);
    },


    actions: {
      willTransition: function willTransition(transition) {
        var isDirty = Object.keys(this.get('controller.location').changedAttributes()).length;
        if (isDirty) {
          transition.abort();
          this.send('open_modal', 'confirm-leave', {
            model: this.get('controller.location'),
            route_transition: transition
          });
        }
      }
    }

  });
});