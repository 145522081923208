define('webapp/validators/cargo-quantities', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = _base.default.extend({

    intl: inject.service(),

    validate: function validate(_, options, cargo, attribute) {
      var description = this.get('intl').t('cargoItems');

      if (cargo.get('total_value') < cargo.get('total_selected')) {
        return this.get('intl').t('errors.hasMoreAssigedToDropoff', { description: description });
      }

      if (cargo.get('total_selected') === 0) {
        return this.get('intl').t('errors.needsAtLeastOneAssigned', { description: description });
      }

      if (cargo.get('total_value') > cargo.get('total_selected')) {
        return this.get('intl').t('errors.hasLessAssignedDropOff', { description: description });
      }

      return true;
    }
  });
});