define('webapp/pods/organization/locations/view/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.query('location', {
        filter: {
          id: params.location_id,
          status: "active"
        },
        include: ['operating-hours', 'address'].join(',')
      }).then(function (results) {
        return results.objectAt(0);
      });
    }
  });
});