define('webapp/models/log-line', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'webapp/mixins/log_line_mappings'], function (exports, _model, _attr, _relationships, _emberCpValidations, _log_line_mappings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    comment: {
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    date: {
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    time: {
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    }
  });

  exports.default = _model.default.extend(Validations, _log_line_mappings.default, {

    sequence_id: (0, _attr.default)('string'),
    event_type: (0, _attr.default)('number'),
    event_code: (0, _attr.default)('number'),
    origin: (0, _attr.default)('number'),
    status: (0, _attr.default)('number'),
    source: (0, _attr.default)('number'),
    time: (0, _attr.default)('string'),
    event_data: (0, _attr.default)('string'),
    odometer: (0, _attr.default)('number'),
    engine_hours: (0, _attr.default)('number'),
    vin_number: (0, _attr.default)('string'),
    latitude: (0, _attr.default)('number'),
    longitude: (0, _attr.default)('number'),
    distance_since_last: (0, _attr.default)('number'),
    malfunction_indicator_status: (0, _attr.default)('boolean'),
    diagnostic_event_indicator_status: (0, _attr.default)('boolean'),
    comment: (0, _attr.default)('string'),
    location_description: (0, _attr.default)('string'),
    derived_city: (0, _attr.default)('string'),
    derived_state: (0, _attr.default)('string'),
    checksum_value: (0, _attr.default)('string'),
    checksum_data_value: (0, _attr.default)('string'),
    datetime: (0, _attr.default)('datetime_utc'),
    last_edited_at: (0, _attr.default)('datetime_utc'),
    driver_id: (0, _attr.default)('number'),
    source_id: (0, _attr.default)('string'),
    version: (0, _attr.default)('number'),
    date: (0, _attr.default)('date'),

    driver: (0, _relationships.belongsTo)(),
    eld_device: (0, _relationships.belongsTo)(),
    eld_alert: (0, _relationships.belongsTo)(),
    haul: (0, _relationships.belongsTo)()

  });
});