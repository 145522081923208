define('webapp/adapters/itm-device', ['exports', 'ember-data', 'webapp/pods/application/adapter'], function (exports, _emberData, _adapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend(_emberData.default.BuildURLMixin, {
    findRecord: function findRecord(store, type, id, snapshot) {
      var url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
      if (url.indexOf('!~!') > 0) {
        url = url.replace('/itm-devices/', '/itm-devices/track/').replace('!~!', '/');
      }
      return this.ajax(url, 'GET');
    }
  });
});