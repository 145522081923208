define('webapp/helpers/show-length', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.showLength = showLength;
  function showLength(params, namedArgs) {
    if (namedArgs.country == 'CA' || namedArgs.country == 'MX') {
      var meters = Math.round(namedArgs.feet * 0.3048 * 10) / 10;
      return namedArgs.feet + '\' (' + meters + 'm)';
    } else {
      return namedArgs.feet + '\'';
    }
  }

  exports.default = Ember.Helper.helper(showLength);
});