define('webapp/models/offer', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'ember-data-copyable'], function (exports, _model, _attr, _relationships, _emberCpValidations, _emberDataCopyable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      _isEqual = Ember.isEqual;


  var Validations = (0, _emberCpValidations.buildValidations)({
    bid: {
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    payment_terms: {
      descriptionKey: 'payment_terms',
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    amount: {
      descriptionKey: 'amount',
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', { gt: 0 })]
    }

  });

  exports.default = _model.default.extend(Validations, _emberDataCopyable.default, {

    amount: (0, _attr.default)('number'),
    payment_terms: (0, _attr.default)('number'),
    is_counteroffer: (0, _attr.default)('boolean'),
    amount_countered: (0, _attr.default)('boolean'),
    payment_terms_countered: (0, _attr.default)('boolean'),
    created_at: (0, _attr.default)('datetime_utc'),
    updated_at: (0, _attr.default)('datetime_utc'),
    pickup_load_operations: (0, _attr.default)('array'),
    dropoff_load_operations: (0, _attr.default)('array'),

    bid: (0, _relationships.belongsTo)(),
    load_operation_offer_details: (0, _relationships.hasMany)(),

    organization: computed('bid.organization', 'bid.listing.organization', function () {
      if (this.get('is_counteroffer')) {
        return this.get('bid.listing.organization');
      } else {
        return this.get('bid.organization');
      }
    }),

    isEqual: function isEqual(offer) {
      var _this = this;

      var loadOpsEq = this.get('load_operation_offer_details').reduce(function (result, loadOp, index) {
        return result && loadOp.isEqual(offer.get('load_operation_offer_details').objectAt(index));
      }, true);

      var offerEq = ['amount', 'payment_terms'].reduce(function (result, prop) {
        return result && _isEqual(offer.get(prop), _this.get(prop));
      }, true);

      return loadOpsEq && offerEq;
    }
  });
});