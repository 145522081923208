define('webapp/pods/organization/dispatch/index/controller', ['exports', 'common/mixins/map-colors', 'common/mixins/colors', 'common/mixins/icons'], function (exports, _mapColors, _colors, _icons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      on = Ember.on;
  exports.default = Ember.Controller.extend(_mapColors.default, _colors.default, _icons.default, {

    current_latitude: computed('session.currentOrganization.address.latitude', function () {
      return this.get('session.currentOrganization.address.latitude') || 34.0800;
    }),

    current_longitude: computed('session.currentOrganization.address.longitude', function () {
      return this.get('session.currentOrganization.address.longitude') || -118.2500;
    }),

    current_zoom: 10,
    current_style: null,
    loads_for_today: [],

    initialize: on('init', function () {
      this.set('current_style', this.get('map_colors.gray'));
    }),

    onlineUnassignedDrivers: computed('unassigned_drivers.@each.online', 'hauls.@each.active_driver', 'hauls.@each.status', function () {
      var _this = this;

      return this.get('unassigned_drivers') && this.get('unassigned_drivers').filter(function (driver) {
        return driver.get('online') && !_this.get('hauls').mapBy('active_driver').includes(driver);
      });
    }),

    offlineUnassignedDrivers: computed('unassigned_drivers.@each.online', 'hauls.@each.active_driver', 'hauls.@each.status', function () {
      return this.get('unassigned_drivers') && this.get('unassigned_drivers').filter(function (driver) {
        return driver.get('online') == false;
      });
    }),

    driverMap: computed('hauls', function () {
      var map = {};
      // grab all active hauls

      this.get('hauls').forEach(function (haul) {
        // get loads for each haul
        haul.get('loads').forEach(function (load) {
          // get operations for each load
          load.get('operations').forEach(function (operation) {
            // use lat-lon as key, and an array of drivers as value
            var latlon = operation.get('latitude') + '-' + operation.get('longitude');

            if (!map[latlon]) {
              map[latlon] = [];
            }

            haul.get('driver_assignments').forEach(function (assignment) {
              var driver = assignment.get('driver');
              if (!map[latlon].includes(driver)) {
                map[latlon].push(driver);
              }
            });
          });
        });
      });

      return map;
    }),

    actions: {
      set_coordinates: function set_coordinates(latitude, longitude) {
        this.set('current_latitude', latitude);
        this.set('current_longitude', longitude);
      }
    }

  });
});