define('webapp/models/payment-method', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    kind: (0, _attr.default)('string'),
    created_at: (0, _attr.default)('datetime'),
    updated_at: (0, _attr.default)('datetime'),
    image_url: (0, _attr.default)('string'),

    organization: (0, _relationships.belongsTo)()

  });
});