define('webapp/components/log-lines-report', ['exports', 'common/components/log-lines-report'], function (exports, _logLinesReport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _logLinesReport.default;
    }
  });
});