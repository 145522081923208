define('webapp/helpers/transition-to-external', ['exports', 'ember-route-helpers/helpers/transition-to-external'], function (exports, _transitionToExternal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _transitionToExternal.default;
    }
  });
  Object.defineProperty(exports, 'transitionToExternal', {
    enumerable: true,
    get: function () {
      return _transitionToExternal.transitionToExternal;
    }
  });
});