define('webapp/components/dropzone-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ui-dropzone'],
    classNameBindings: ['dragClass'],
    dragClass: 'deactivated',

    dragLeave: function dragLeave(event) {
      event.preventDefault();
      this.set('dragClass', 'deactivated');
    },
    dragOver: function dragOver(event) {
      if (this.allowDrop()) {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
      }
    },
    dragEnter: function dragEnter(event) {
      if (this.allowDrop()) {
        event.preventDefault();
        this.set('dragClass', 'activated');
      }
    },
    drop: function drop(event) {
      if (this.allowDrop()) {
        this.dropped();
      }
      this.set('dragClass', 'deactivated');
    },
    allowDrop: function allowDrop(data) {
      return true;
    },
    dropped: function dropped(data) {}
  });
});