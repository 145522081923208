define('webapp/pods/organization/loads/index/operation/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    load_operation_tasks: Ember.inject.service(),

    model: function model(params) {
      return this.get('load_operation_tasks.for_sidebar').perform(params.load_operation_id);
    }
  });
});