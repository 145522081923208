define('webapp/utils/prune-object', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (obj) {
    return function prune(current) {
      _lodash.default.forOwn(current, function (value, key) {
        if (_lodash.default.isUndefined(value) || _lodash.default.isNull(value) || _lodash.default.isNaN(value) || _lodash.default.isString(value) && _lodash.default.isEmpty(value) || _lodash.default.isObject(value) && _lodash.default.isEmpty(prune(value))) {
          delete current[key];
        }
      });
      // remove any leftover undefined values from the delete
      // operation on an array
      if (_lodash.default.isArray(current)) {
        _lodash.default.pull(current, undefined);
      }

      return current;
    }(_lodash.default.cloneDeep(obj));
  };
});