define('webapp/helpers/map-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mapIcon = mapIcon;
  function mapIcon(params) {
    var point = params[0];
    var color = 'orange';

    if (point.type === 'operation') {
      color = 'black';
    } else if (point.sub_type === 'snapped') {
      color = 'red';
    } else if (point.sub_type === 'interpolated') {
      color = 'blue';
    } else if (point.type === 'tracking') {
      color = 'red';
    }

    return {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: color,
      fillOpacity: 0.4,
      scale: 4.5,
      strokeColor: 'white',
      strokeWeight: 1
    };
  }

  exports.default = Ember.Helper.helper(mapIcon);
});