define('webapp/models/eld-alert', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'webapp/mixins/event-codes'], function (exports, _model, _attr, _relationships, _eventCodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend(_eventCodes.default, {

    alert_type: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    event_code: (0, _attr.default)('number'),
    date: (0, _attr.default)('datetime_utc'),
    location: (0, _attr.default)('string'),
    vin_number: (0, _attr.default)('string'),
    extra_hours: (0, _attr.default)('string'),
    created_at: (0, _attr.default)('datetime_utc'),

    eld_device: (0, _relationships.belongsTo)(),
    user: (0, _relationships.belongsTo)({ inverse: null }),
    driver: (0, _relationships.belongsTo)(),
    log_lines: (0, _relationships.hasMany)({ inverse: null }),

    event_code_name: computed('event_code', function () {
      if (this.get('event_code')) {
        return this.event_codes.findBy("value", this.get('event_code')).name;
      }
    }),

    is_unidentifed_driver: computed('alert_type', function () {
      return Ember.computed.equal(this.get('alert_type'), 'unidentifed driver');
    })

  });
});