define('webapp/helpers/available-time', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.availableTime = availableTime;


  // params[0] is the date
  // param[1] is the time
  function availableTime(params) {
    var css_class = 'item';
    var time = moment(params[1], 'h:mm A');
    var selected_datetime = moment(params[0]).set({ hour: time.hour(), minute: time.minute() }).toDate();
    if (new Date() >= selected_datetime) {
      css_class = 'item time-disabled';
    }
    return Ember.String.htmlSafe('<div class="' + css_class + '" data-value="' + params[1] + '">' + params[1] + '</div>');
  }

  exports.default = Ember.Helper.helper(availableTime);
});