define('webapp/pods/organization/view/route', ['exports', 'uncharted-ajax'], function (exports, _unchartedAjax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  exports.default = Ember.Route.extend({

    routeClasses: 'base',

    model: function model(params) {
      var organization = this.get('session.currentOrganization');
      return RSVP.hash({
        model: this.store.findRecord('organization', organization.id, {
          include: 'address,drivers,users,listings,power-units'
        }),
        transactions: organization.get('ifta_reporting') && (0, _unchartedAjax.default)({
          method: 'GET',
          url: '/api/v1/organizations/' + organization.id + '/transactions',
          dataType: 'json'
        })
      });
    },
    setupController: function setupController(controller, models) {
      this._super.apply(this, arguments);
      controller.set('model', models.model);
      controller.set('transactions', models.transactions);
    }
  });
});