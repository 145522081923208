define('webapp/initializers/showdown-extensions', ['exports', 'showdown'], function (exports, _showdown) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    _showdown.default.extension("targetBlank", function () {
      return [{
        type: 'output',
        regex: '<a(.*)a>',
        replace: function replace(match, content) {
          return '<a target="_blank"' + content + 'a>';
        }
      }];
    });
  }

  exports.default = {
    name: 'showdown-extensions',
    initialize: initialize
  };
});