define('webapp/pods/user/record-fuel-purchase/step-three/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('fuel-stop', params.fuel_stop_id).then(function (fuel_stop) {
        fuel_stop.set('step', 'three');
        return fuel_stop;
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      // Clear cache
      controller.set('file', { url: '', name: '' });
    },


    actions: {
      backToFuelReport: function backToFuelReport() {
        this.transitionTo('carrier.reports.fuel');
      }
    }

  });
});