define('webapp/components/draggable-haul/component', ['exports', 'webapp/components/draggable-item/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    calendar_data: Ember.inject.service(),
    drag_type: "haul",
    haul: null,

    draggable: Ember.computed('haul.status', function () {
      // don't allow any dragging at the moment
      if (true) {
        return false;
      }

      if (this.get('haul.isCompletedOrCancelled')) {
        return false;
      }
      return true;
    }),

    content: Ember.computed('drag_type', 'haul', function () {
      return {
        type: this.get('drag_type'),
        haul: this.get('haul')
      };
    }),

    set_data: function set_data() {
      this.get('calendar_data').set('current', this.get('content'));
    }
  });
});