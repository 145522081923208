define('webapp/pods/user/components/profile-avatar-modal/component', ['exports', 'semantic-ui-ember/components/ui-modal'], function (exports, _uiModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiModal.default.extend({
    observeChanges: true,
    classNames: ['profile-avatar-modal'],

    upload_url: Ember.computed('session.currentUser.id', function () {
      return '/api/v1/users/' + this.get('session.currentUser.id') + '/avatar';
    }),

    actions: {
      upload_success: function upload_success() {
        return this.get('session.currentUser').reload();
      }
    }
  });
});