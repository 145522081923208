define('webapp/components/events-graph-row', ['exports', 'common/components/events-graph-row'], function (exports, _eventsGraphRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _eventsGraphRow.default;
    }
  });
});