define('webapp/helpers/mpg-to-metric', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mpgToMetric = mpgToMetric;
  function mpgToMetric(params) {
    var mpg = params[0];
    if (params[1] == 'metric') {
      if (mpg == 0 || mpg == undefined) {
        return 0;
      } else {
        return 235.21 / mpg;
      }
    } else {
      return mpg;
    }
  }

  exports.default = Ember.Helper.helper(mpgToMetric);
});