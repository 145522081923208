define('webapp/pods/users/confirmation/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    user_confirmation: Ember.inject.service(),

    model: function model(params) {
      return this.get('user_confirmation.confirm').perform(params.confirmation_token);
    },
    afterModel: function afterModel(model) {
      if (model) {
        return this.transitionTo('users.login', { queryParams: { confirmed: true } });
      }
    }
  });
});