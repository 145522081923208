define('webapp/components/load-tracking-info', ['exports', 'common/components/load-tracking-info'], function (exports, _loadTrackingInfo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _loadTrackingInfo.default;
    }
  });
});