define('webapp/pods/organization/notifications/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      page: {
        refreshModel: true
      },
      pinned: {
        refreshModel: true
      },
      archived: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var filter = {
        user_id: this.get('session.currentUser.id')
      };

      if (params.search) {
        filter.search = params.search;
      }

      if (params.pinned) {
        filter.pinned = true;
      } else if (params.archived) {
        filter.archived = true;
      } else {
        filter.pinned = false;
        filter.archived = false;
      }

      return this.store.query('user-notification', {
        filter: filter,
        sort: '-created_at',
        page: {
          number: params.page || 1,
          size: params.page_size || 20
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('selected_all', false);

      Ember.run.schedule('afterRender', this, function () {
        model.forEach(function (notification) {
          notification.set('is_selected', false);
        });
      });
    }
  });
});