define('webapp/pods/user/subscription/route', ['exports', 'uncharted-ajax'], function (exports, _unchartedAjax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var organization = this.modelFor('user');
      return (0, _unchartedAjax.default)({
        url: '/api/v1/organizations/' + organization.id + '/ifta_subscription'
      }).then(function (subscription) {
        organization.set('ifta_subscription', subscription);
        return organization;
      });
    }
  });
});