define('webapp/components/confirm-leave/component', ['exports', 'semantic-ui-ember/components/ui-modal'], function (exports, _uiModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _uiModal.default.extend({
    classNames: ['confirm-leave'],
    actions: {
      discard: function discard() {
        this.get('model').rollbackAttributes();
        this.execute('hide');
        this.get('route_transition').retry();
      },
      cancel: function cancel() {
        this.execute('hide');
      }
    }

  });
});