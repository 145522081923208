define('webapp/components/load-path', ['exports', 'common/components/load-path'], function (exports, _loadPath) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _loadPath.default;
    }
  });
});