define('webapp/pods/organization/loads/components/confirm-eld-exemption-modal/component', ['exports', 'semantic-ui-ember/components/ui-modal', 'webapp/utils/catch-real', 'ember-concurrency'], function (exports, _uiModal, _catchReal, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiModal.default.extend({

    closable: false,
    observeChanges: true,
    classNames: ['confirm-eld-exemption-modal'],

    actions: {
      cancel: function cancel() {
        var load = this.get('load');
        load.set('eld_exempt', false);
        this.execute('hide');
      }
    }

  });
});