define('webapp/pods/organization/loads/components/load-title/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('inSetup', this.get('load.in_setup'));
    }
  });
});