define('webapp/components/load-tracking', ['exports', 'common/components/load-tracking'], function (exports, _loadTracking) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _loadTracking.default;
    }
  });
});