define("webapp/mixins/event-codes", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var event_codes = [{ name: "offDuty", value: 1 }, { name: "sleeperBerth", value: 2 }, { name: "driving", value: 3 }, { name: "onDutyNotDriving", value: 4 }];

  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('event_codes', event_codes);
    }
  });
});