define('webapp/models/load-invoice', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'webapp/mixins/model-request', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _modelRequest, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    organization: {
      descriptionKey: "organizationMenuLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },

    billing_address: {
      descriptionKey: "billingAddress",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },

    amount_due: {
      descriptionKey: "amountDueLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', { gt: 0 })]
    },

    date_due: {
      descriptionKey: "dueDateLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },

    date_paid: {
      descriptionKey: "datePaid",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed.not('model.is_paid')
      })]
    },

    status: {
      descriptionKey: "status",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', { in: ['open', 'paid'] })]
    }
  });

  exports.default = _model.default.extend(Validations, _modelRequest.default, {

    amount_due: (0, _attr.default)('number'),
    date_due: (0, _attr.default)('datetime'),
    date_paid: (0, _attr.default)('datetime_utc'),
    status: (0, _attr.default)('string', { defaultValue: 'open' }),
    sent_at: (0, _attr.default)('datetime_utc'),
    invoice_number: (0, _attr.default)('string'),
    created_at: (0, _attr.default)('datetime_utc'),
    updated_at: (0, _attr.default)('datetime_utc'),

    organization: (0, _relationships.belongsTo)(),
    load: (0, _relationships.belongsTo)({ async: false }),
    sender_address: (0, _relationships.belongsTo)('organization-address'),
    billing_address: (0, _relationships.belongsTo)('organization-billing-address'),

    is_open: computed.equal('status', 'open'),
    is_paid: computed.equal('status', 'paid'),

    deliver: function deliver() {
      return this.do_post('deliver');
    }
  });
});