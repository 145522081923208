define('webapp/pods/drivers/password/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    controllerName: 'drivers.password',
    templateName: 'drivers.password.edit',

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('is_confirmation', false);
    }
  });
});