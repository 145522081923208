define('webapp/pods/organization/loads/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Controller.extend({

    queryParams: ['page', 'page_size', 'status', 'search', 'sort_by', 'direction'],

    page: 1,
    page_size: 10,
    status: 'active',
    search: null,
    sort_by: null,
    direction: 'asc',

    isPending: computed.equal('status', 'pending'),
    isActive: computed.equal('status', 'active'),
    isCompleted: computed.equal('status', 'completed'),
    isCancelled: computed.equal('status', 'cancelled'),

    actions: {
      sort: function sort(sort_by, direction) {
        this.setProperties({ sort_by: sort_by, direction: direction });
      }
    }

  });
});