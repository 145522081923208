define("webapp/helpers/yes-no", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.yesNo = yesNo;
  function yesNo(value) {
    if (value instanceof Array) {
      value = value.firstObject;
    }
    if (value === undefined) {
      return "notSelected";
    } else if (value === false) {
      return "No";
    } else {
      return "Yes";
    }
  }

  exports.default = Ember.Helper.helper(yesNo);
});