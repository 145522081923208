define('webapp/pods/itm-tracking/components/battery-indicator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'td',
    classNames: ['status'],
    classNameBindings: ['less_than_ten:red', 'eleven_to_twenty_five:yellow', 'above_twenty_six:green'],

    less_than_ten: Ember.computed('battery_level', function () {
      return this.get('battery_level') <= 10;
    }),

    eleven_to_twenty_five: Ember.computed('battery_level', function () {
      return this.get('battery_level') > 10 && this.get('battery_level') <= 25;
    }),

    above_twenty_six: Ember.computed('battery_level', function () {
      return this.get('battery_level') > 25;
    })
  });
});