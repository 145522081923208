define('webapp/models/listing', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'webapp/mixins/statuses', 'ember-cp-validations', 'ember-data-copyable'], function (exports, _model, _attr, _relationships, _statuses, _emberCpValidations, _emberDataCopyable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;


  var Validations = (0, _emberCpValidations.buildValidations)({

    status: {
      descriptionKey: "status",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['setup', 'listing_info', 'pending', 'offered', 'awarded']
      })]
    },

    listing_type: {
      descriptionKey: "listingType",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['fixed_price', 'sealed_bid']
      })]
    },

    fixed_price: {
      descriptionKey: "fixedPrice",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed.equal('model.listing_type', 'sealed_bid')
      })]
    },

    bid_due_datetime: {
      descriptionKey: "bidDueDatetime",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', { presence: true }), (0, _emberCpValidations.validator)('presence', true)]
    },

    equipment_type: {
      descriptionKey: "equipmentTypeHeader",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 100 })]
    },

    trailer_length: {
      descriptionKey: "trailerLengthHeader",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        gt: 0
      })]
    },

    temperature: {
      description: "temperature",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed.not('model.needs_temperature')
      })]
    },

    organization: {
      descriptionKey: "organizationMenuLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    }

  });

  exports.default = _model.default.extend(_statuses.default, Validations, _emberDataCopyable.default, {

    equipment: inject.service(),

    eid: (0, _attr.default)(),
    listing_type: (0, _attr.default)({ defaultValue: 'sealed_bid' }),
    fixed_price: (0, _attr.default)('number'),
    pricing_type: (0, _attr.default)({ defaultValue: 'per_cwt' }),
    payment_terms: (0, _attr.default)({ defaultValue: '7' }),
    bid_due_datetime: (0, _attr.default)('datetime'),
    equipment_type: (0, _attr.default)(),
    trailer_length: (0, _attr.default)('number'),
    temperature: (0, _attr.default)(),
    temperature_type: (0, _attr.default)({ defaultValue: 'f' }),
    enable_auto_accept: (0, _attr.default)('boolean', { defaultValue: false }),
    allow_counter_bid: (0, _attr.default)('boolean'),
    need_insurance: (0, _attr.default)('boolean', { defaultValue: false }),
    cargo_value: (0, _attr.default)('number', { defaultValue: 0 }),
    auto_accept_price: (0, _attr.default)('number'),
    status: (0, _attr.default)({ defaultValue: 'setup' }),
    carrier_status: (0, _attr.default)({ defaultValue: 'no_bid' }),
    notes: (0, _attr.default)(),
    lowest_bid: (0, _attr.default)('number'),
    created_at: (0, _attr.default)('datetime_utc'),
    updated_at: (0, _attr.default)('datetime_utc'),
    is_template: (0, _attr.default)('boolean', { defaultValue: false }),

    organization: (0, _relationships.belongsTo)(),
    load: (0, _relationships.belongsTo)(),
    bids: (0, _relationships.hasMany)(),

    needs_temperature: computed('equipment_type', function () {
      return this.get('equipment').getType(this.get('equipment_type')).temperature;
    }),

    equipment_image: computed('equipment_type', function () {
      return this.get('equipment').getType(this.get('equipment_type')).image;
    }),

    bid_due_time: computed('bid_due_datetime', {
      get: function get() {
        var date = this.get('bid_due_datetime');
        if (date && date < new Date()) {
          // Set the next hour
          var roundUp = moment().add(1, 'hour').startOf('hour');
          return moment(roundUp).format('h:mm A');
        } else {
          return moment(date).format('h:mm A');
        }
      },
      set: function set(property, time) {
        var date = this.get('bid_due_datetime');
        var t = moment(time, 'h:mm A');
        var newDateTime = moment(date).set({ hour: t.hour(), minute: t.minute() }).toDate();
        this.set('bid_due_datetime', newDateTime);
        return time;
      }
    }),

    isAwarded: computed.equal('status', 'awarded')
  });
});