define('webapp/pods/organization/loads/components/load-driver/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({

    submitted: false,

    haul: computed.alias('load.haul'),
    is_available: computed.equal('status', 'available'),
    is_not_available: computed.equal('status', 'not_available'),

    actions: {
      openDriverModal: function openDriverModal(driver) {
        this.send('open_modal', 'organization/loads/components/edit-driver-modal', {
          driver: driver,
          saveTask: this.get('driverSaveTask')
        });
      },
      teamDriverChange: function teamDriverChange() {
        if (!this.get('haul.team_drivers')) {
          var assignments = this.get('haul.driver_assignments');
          assignments.forEach(function (assignment) {
            assignment.destroyRecord();
          });
        }
      },
      setStatus: function setStatus(status) {
        this.set('status', status);
        this.set('page', 1);
      }
    }

  });
});