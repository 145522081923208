define('webapp/pods/organization/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.get('session.loadCurrentOrganization').perform();
    },


    actions: {
      error: function error(_error, transition) {
        transition.abort();
        if (console.error) {
          console.error(_error);
        }
        this.send('open_modal', "unknown-error", { error: _error });
      }
    }

  });
});