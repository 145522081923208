define('webapp/helpers/friendly-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.friendlyNumber = friendlyNumber;
  function friendlyNumber(_ref) /*, hash*/{
    var number = _ref[0];

    if (number === 1) {
      return '1st';
    }
    if (number === 2) {
      return '2nd';
    }
    if (number === 3) {
      return '3rd';
    }
    return number + 'th';
  }

  exports.default = Ember.Helper.helper(friendlyNumber);
});