define('webapp/helpers/round-to-one', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.roundToOne = roundToOne;
  function roundToOne(params /*, hash*/) {
    var number = params[0];
    var precision = params[1];
    if (typeof number !== 'undefined') {
      return Math.round(number * 10) / 10;
    } else {
      return '';
    }
  }

  exports.default = Ember.Helper.helper(roundToOne);
});