define('webapp/pods/organization/notifications/view/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    showdown: Ember.inject.service(),
    notification_area: Ember.inject.service(),

    actions: {
      save_boolean: function save_boolean(name, value) {
        var _this = this;

        this.set(name + '_saving', true);
        this.notification.set(name, value);

        this.notification.save().then(function (res) {

          if (name === 'unread' && value === true && res.get('unread') === true) {

            // increase total notification number
            var total_notification_number = _this.get('notification_area').get('total');
            _this.get('notification_area').set('total', total_notification_number + 1);
          }
        }).finally(function () {
          _this.set(name + '_saving', false);
        });
      },
      unread: function unread(value) {
        this.send('save_boolean', 'unread', value);
      },
      pinned: function pinned(value) {
        this.send('save_boolean', 'pinned', value);
      },
      archived: function archived(value) {
        this.send('save_boolean', 'archived', value);
      }
    }
  });
});