define('webapp/resolver', ['exports', 'ember-resolver'], function (exports, _emberResolver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CustomResolver = _emberResolver.default.extend({
    moduleNameLookupPatterns: Ember.computed(function () {
      var defaults = this._super();
      defaults.concat([this.podBasedComponentsInResource]);
      return defaults;
    }),

    podBasedComponentsInResource: function podBasedComponentsInResource(parsedName) {
      var podPrefix = this.namespace.podModulePrefix || this.namespace.modulePrefix;
      if (parsedName.type === 'component' || parsedName.fullNameWithoutType.match(/^components/)) {
        var nameWithoutComponent = parsedName.fullNameWithoutType.replace(/components\//, '');
        var slash = nameWithoutComponent.lastIndexOf('/');
        if (slash > 0) {
          podPrefix = podPrefix + '/' + nameWithoutComponent.substr(0, slash) + '/components';
          parsedName.fullNameWithoutType = nameWithoutComponent.substr(slash + 1);
          return this.podBasedLookupWithPrefix(podPrefix, parsedName);
        }
      }
    },

    podBasedComponentsInSubdir: function podBasedComponentsInSubdir(parsedName) {
      var podPrefix = this.namespace.modulePrefix; // this.namespace.podModulePrefix || this.namespace.modulePrefix
      podPrefix = podPrefix + '/components';

      if (parsedName.type === 'component' || parsedName.fullNameWithoutType.match(/^components/)) {
        return this.podBasedLookupWithPrefix(podPrefix, parsedName);
      }
    }

  });

  exports.default = CustomResolver;
});