define('webapp/mixins/statuses', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Ember.Mixin.create({

    session: Ember.inject.service('session'),

    in_setup: computed.equal('status', 'setup'),
    is_pending: computed.equal('status', 'pending'),
    is_departing: computed.equal('status', 'departing'),
    is_en_route: computed.equal('status', 'en_route'),
    is_arriving: computed.equal('status', 'arriving'),
    is_checked_in: computed.equal('status', 'checked_in'),
    is_completed: computed.equal('status', 'completed'),
    is_cancelled: computed.equal('status', 'cancelled'),
    is_deleted: computed.equal('status', 'deleted'),
    is_viewed: computed.equal('status', 'viewed'),
    is_pickup: computed.equal('status', 'pickup'),
    is_dropoff: computed.equal('status', 'dropoff'),
    is_offered: computed.equal('status', 'offered'),
    is_awarded: computed.equal('status', 'awarded'),

    // Combined status checks
    in_setup_or_is_pending: computed.or('in_setup', 'is_pending'),
    is_active: computed.or('is_departing', 'is_en_route', 'is_arriving', 'is_checked_in'),
    is_checked_in_or_completed: computed.or('is_checked_in', 'is_completed'),
    not_in_setup: computed.not('in_setup'),

    isCompletedOrCancelled: computed.or('is_completed', 'is_cancelled'),
    notCompletedOrCancelled: computed.or('in_setup', 'is_pending', 'is_departing', 'is_en_route', 'is_arriving', 'is_checked_in', 'is_viewed'),

    friendly_status: computed('status', 'session.currentUser.language', function () {
      this.get('session.currentUser.language');
      if (this.get('is_completed')) {
        return this.get('intl').t('completed');
      } else if (this.get('is_checked_in')) {
        return this.get('intl').t('checkedIn');
      } else if (this.get('is_en_route')) {
        return this.get('intl').t('enRoute');
      } else if (this.get('is_pending')) {
        return this.get('intl').t('pending');
      } else if (this.get('in_setup') || this.get('status') === 'listing_info') {
        return this.get('intl').t('inSetup');
      } else if (this.get('is_cancelled')) {
        return this.get('intl').t('cancelled');
      } else if (this.get('is_deleted')) {
        return this.get('intl').t('deleted');
      } else if (this.get('is_viewed')) {
        return this.get('intl').t('viewed');
      } else if (this.get('is_offered')) {
        return this.get('intl').t('offered');
      } else if (this.get('is_awarded')) {
        return this.get('intl').t('awarded');
      } else if (this.get('is_arriving')) {
        return this.get('intl').t('arriving');
      } else if (this.get('is_departing')) {
        return this.get('intl').t('departing');
      } else if (this.get('is_pickup')) {
        return this.get('intl').t('pickUpLabel');
      } else if (this.get('is_dropoff')) {
        return this.get('intl').t('dropOffLabel');
      }

      return String.capitalize(this.get('status'));
    })
  });
});