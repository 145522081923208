define('webapp/models/load', ['exports', 'ember-data/model', 'ember-data/attr', 'webapp/mixins/statuses', 'webapp/mixins/model-request', 'ember-data/relationships', 'ember-cp-validations', 'ember-data-copyable'], function (exports, _model, _attr, _statuses, _modelRequest, _relationships, _emberCpValidations, _emberDataCopyable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      isBlank = Ember.isBlank,
      A = Ember.A;


  var Validations = (0, _emberCpValidations.buildValidations)({
    // attributes
    status: {
      descriptionKey: "status",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['setup', 'pending', 'viewed', 'en_route', 'checked_in', 'completed', 'cancelled', 'deleted']
      })]
    },

    notes: {
      descriptionKey: "notes",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', { max: 2500 })]
    },

    delays: {
      descriptionKey: "loadDelays",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many')]
    },

    // relationships
    haul: {
      descriptionKey: "haul",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },

    operations: {
      descriptionKey: "loadOperations",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many')]
    }
  });

  exports.default = _model.default.extend(Validations, _statuses.default, _emberDataCopyable.default, _modelRequest.default, {

    eid: (0, _attr.default)(),
    status: (0, _attr.default)({ defaultValue: 'setup' }),
    created_at: (0, _attr.default)('datetime-utc'),
    updated_at: (0, _attr.default)('datetime-utc'),
    notes: (0, _attr.default)(),
    eld_exempt: (0, _attr.default)(),
    distance: (0, _attr.default)('number'),
    unique_external_id: (0, _attr.default)('string'),
    authentication_token: (0, _attr.default)('string'),

    haul: (0, _relationships.belongsTo)({ async: false }),
    listing: (0, _relationships.belongsTo)(),
    power_unit: (0, _relationships.belongsTo)(),
    invoice: (0, _relationships.belongsTo)('load-invoice'),
    organization: (0, _relationships.belongsTo)('organization', { async: false }),
    shipper_organization: (0, _relationships.belongsTo)('organization', { async: false }),

    operations: (0, _relationships.hasMany)('load-operation', { async: false }),

    trackings: (0, _relationships.hasMany)("load-tracking"),
    paths: (0, _relationships.hasMany)("load-path"),

    // transient properties

    hasUpdated: null, // used for the sync service

    // computed properties

    // organization: computed.alias('haul.organization'),

    pickups: computed.filterBy('operations', 'is_pickup', true),

    dropoffs: computed.filterBy('operations', 'is_dropoff', true),

    first_pickup: computed('pickups.@each.actual_date', function () {
      var pickups = this.get('pickups').filterBy('actual_date');
      if (pickups) {
        pickups = pickups.sort(function (a, b) {
          return a.get('actual_date').getTime() - b.get('actual_date').getTime();
        });
      }
      return pickups.objectAt(0);
    }),

    last_dropoff: computed('dropoffs.@each.actual_date', function () {
      var dropoffs = this.get('dropoffs').filterBy('actual_date');
      if (dropoffs) {
        dropoffs = dropoffs.sort(function (a, b) {
          return b.get('actual_date').getTime() - a.get('actual_date').getTime();
        });
      }
      return dropoffs.objectAt(0);
    }),

    nearLocation: computed('operations.@each.status', function () {
      var statuses = ['en_route', 'checked_in'];
      if (statuses.includes(this.get('status'))) {
        var ops = this.get('operations').filter(function (o) {
          return statuses.includes(o.get('status'));
        });
        return ops.get('lastObject.location.company_name');
      }
    }),

    images: computed('operations.@each.images', function () {
      return this.get('operations').reduce(function (result, operation) {
        result.pushObjects(operation.get('images').toArray());
        return result;
      }, A());
    }),

    operation_audits: computed('operations.@each.audits', function () {
      return this.get('operations').reduce(function (result, operation) {
        result.pushObjects(operation.get('audits').toArray());
        return result;
      }, A());
    }),

    total_units: computed('operations.@each.total_units', function () {
      return this.get('operations').reduce(function (prev, item) {
        return prev + item.get('total_units');
      }, 0);
    }),

    total_weight: computed('operations.@each.total_weight', function () {
      return this.get('operations').reduce(function (prev, item) {
        return prev + item.get('total_weight');
      }, 0);
    }),

    tracking_number: computed('unique_external_id', 'authentication_token', function () {
      if (this.get('haul')) {
        return this.get('unique_external_id') + '?token=' + this.get('authentication_token');
      }
    }),

    status_changed: computed('initial_status', 'status', function () {
      if (isBlank(this.get('initial_status'))) {
        return false;
      }
      // Check based on controller tabs
      if (['setup', 'pending'].includes(this.get('status'))) {
        return !['setup', 'pending'].includes(this.get('initial_status'));
      }

      if (['en_route', 'checked_in'].includes(this.get('status'))) {
        return !['en_route', 'checked_in'].includes(this.get('initial_status'));
      }
      return this.get('initial_status') !== this.get('status');
    }),

    can_be_deleted: computed('status', 'first_pickup.status', function () {
      return !this.get('first_pickup.is_completed') && this.get('notCompletedOrCancelled');
    }),

    can_be_cancelled: computed('status', 'first_pickup.status', function () {
      return this.get('first_pickup.is_completed') && this.get('notCompletedOrCancelled');
    }),

    // functions

    areRelatedDirty: function areRelatedDirty() {
      var dirties = this.getRelatedModels().map(function (m) {
        return m.get('hasDirtyAttributes');
      });
      return Boolean(dirties.find(function (dirty) {
        return dirty === true;
      }));
    },
    rollbackRelated: function rollbackRelated() {
      return this.getRelatedModels().map(function (m) {
        return m.rollbackAttributes();
      });
    },
    path: function path() {
      return this.do_get('path');
    },
    points: function points() {
      return this.do_get('points');
    },
    route: function route(options) {
      return this.do_post('route', { options: options });
    },


    // TODO this should be done in the serializer

    updateRelatedStatus: function updateRelatedStatus(status) {
      // the backend will mark all load-related as (status)
      // but since we only persist the load, we will mark the
      // rest as (status) locally
      this.getRelatedModels().forEach(function (m) {
        if (status === 'deleted' && m.get('status') === 'pending') {
          // TODO also test if it's a LoadOperation
          m.set('status', status);
        }

        if (status === 'cancelled' && m.get('status') !== 'completed') {
          // TODO also test if it's a LoadOperation
          m.set('status', status);
        }

        if (status === 'pending' && m.get('status') === 'setup') {
          m.set('status', status);
        }

        if (status === 'deleted') {
          m.set('status', status);
        }
      });
      return this.save();
    },


    // returns a flat array of a series of sync-loaded Load-related models
    // (load, haul, operations, cargos, destinations)
    // Remove null values at the end
    getRelatedModels: function getRelatedModels() {
      return this.get('operations').reduce(function (a1, operation) {
        a1.pushObject(operation);
        var sub = operation.get('cargos').reduce(function (a2, cargo) {
          a2.pushObject(cargo);
          a2.pushObjects(cargo.get('destinations').toArray());
          return a2;
        }, A());
        a1.pushObjects(sub);
        return a1;
      }, A([this, this.get('haul')])).filter(function (e) {
        return e;
      });
    }
  });
});