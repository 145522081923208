define('webapp/helpers/lat-lng', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.latLng = latLng;
  function latLng(params /*, hash*/) {
    var lat = params[0];
    var lng = params[1];
    return new google.maps.LatLng({ lat: parseFloat(lat), lng: parseFloat(lng) });
  }

  exports.default = Ember.Helper.helper(latLng);
});