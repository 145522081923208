define('webapp/pods/organization/loads/components/load-summary/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    submitted: false,
    haul: computed.alias('model.haul')
  });
});