define('webapp/models/driver', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'webapp/mixins/model-request'], function (exports, _model, _attr, _relationships, _emberCpValidations, _modelRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;


  var Validations = (0, _emberCpValidations.buildValidations)({
    first_name: {
      descriptionKey: "firstNameLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 100 })]
    },

    last_name: {
      descriptionKey: "lastNameLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 100 })]
    },

    dot_number: {
      description: "DOT Number",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('dot-number-valid', {
        disabled: computed.alias('model.validate_dot_number')
      })]
    },

    driver_license_state: {
      descriptionKey: "driverLicenseState",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },

    driver_license_number: {
      descriptionKey: "driverLicenseNumber",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', { max: 50 })]
    },

    driver_license_expiration_date: {
      descriptionKey: "licenseExpirationDateLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },

    medical_certificate_national_registration_number: {
      descriptionKey: "medicalCertificateNational",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', { max: 50 })]
    },

    medical_certificate_expiration_date: {
      descriptionKey: "medicalCertificateNationalExpDate",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },

    email: {
      descriptionKey: "emailLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('format', {
        regex: /[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
        allowBlank: true
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed('model.is_email_invitation', 'isNew', function () {
          if (!this.get('isNew')) {
            return true;
          } else {
            return this.get('model.is_email_invitation');
          }
        })
      })]
    },

    status: {
      descriptionKey: "status",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['active', 'pending']
      })]
    },

    phone_number: {
      descriptionKey: "phoneNumberLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },

    country_code: {
      descriptionKey: "countryCodeLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },

    eld_exempt_reason: {
      descriptionKey: "eldExemptReason",
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed.not('model.eld_exempt'),
        dependentKeys: ['eld_exempt']
      })]
    }

  });

  exports.default = _model.default.extend(Validations, _modelRequest.default, {

    phone_number: (0, _attr.default)(),
    status: (0, _attr.default)({ defaultValue: 'pending' }),
    email: (0, _attr.default)(),
    first_name: (0, _attr.default)(),
    last_name: (0, _attr.default)(),
    avatar: (0, _attr.default)(),

    country: (0, _attr.default)(), // TODO these two should be the same (an iso code), supported by the geography service
    country_code: (0, _attr.default)(),

    confirmed_at: (0, _attr.default)('datetime-utc'),
    created_at: (0, _attr.default)('datetime-utc'),
    updated_at: (0, _attr.default)('datetime-utc'),
    personal_use: (0, _attr.default)('boolean'),
    yard_move: (0, _attr.default)('boolean'),
    eld_exempt: (0, _attr.default)('boolean'),
    communication_form: (0, _attr.default)({ defaultValue: 'phone' }),
    eld_exempt_reason: (0, _attr.default)('string'),
    latitude: (0, _attr.default)('number'),
    longitude: (0, _attr.default)('number'),
    driver_license_state: (0, _attr.default)(),
    driver_license_number: (0, _attr.default)(),
    driver_license_expiration_date: (0, _attr.default)('datetime'),
    medical_certificate_national_registration_number: (0, _attr.default)(),
    medical_certificate_expiration_date: (0, _attr.default)('datetime'),
    located_at: (0, _attr.default)('datetime-utc'),
    located_accuracy: (0, _attr.default)('number'),
    direction: (0, _attr.default)(),
    online: (0, _attr.default)('boolean'),
    loads_hauled: (0, _attr.default)('number'),
    last_activity_date: (0, _attr.default)('datetime'),
    suspended: (0, _attr.default)('boolean'),

    ifta_enabled: (0, _attr.default)('boolean', { transient: true }),

    current_organization: (0, _relationships.belongsTo)('organization', { async: false }), // TODO rename this to carrier?

    hauls: (0, _relationships.hasMany)(),
    driver_assignments: (0, _relationships.hasMany)(),
    current_haul: (0, _relationships.belongsTo)('haul', { inverse: null }),

    bids: (0, _relationships.hasMany)({ inverse: null }),
    log_lines: (0, _relationships.hasMany)(),
    devices: (0, _relationships.hasMany)(),
    // computed properties

    full_name: computed('first_name', 'last_name', function () {
      return this.get('first_name') + ' ' + this.get('last_name');
    }),

    short_name: computed('first_name', 'last_name', function () {
      return this.get('first_name').substr(0, 1) + '. ' + this.get('last_name');
    }),

    is_active: computed.equal('status', 'active'),
    is_pending: computed.equal('status', 'pending'),
    is_inactive: computed.equal('status', 'inactive'),

    has_coordinates: computed('latitude', 'longitude', function () {
      return !!this.get('latitude') && !!this.get('longitude');
    }),

    is_online: computed('status', function () {
      if (!this.get('is_active')) {
        return false;
      }
      // We need to have a metric to track if online, last 30 minutes, something else?
      return false;
    }),

    is_offline: computed('status', 'is_online', function () {
      if (this.get('is_online')) {
        return false;
      }
      return this.get('is_active');
    }),

    is_email_invitation: computed('communication_form', function () {
      return this.get('communication_form') === 'phone';
    }),

    is_unassigned: computed.not('is_assigned'),
    is_assigned: computed.notEmpty('current_haul.id'),
    is_active_and_unassigned: computed.and('is_active', 'is_unassigned'),

    completedHauls: computed('hauls.[]', function () {
      return this.get('hauls').filterBy('status', 'completed');
    }),

    // TODO these should be adapter methods

    clear_avatar: function clear_avatar() {
      return this.do_delete('avatar');
    },
    resend_invite: function resend_invite() {
      return this.do_post('resend_invite');
    }
  });
});