define('webapp/helpers/waiting-for', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Helper.extend({

    intl: inject.service(),

    compute: function compute(_ref) {
      var bid = _ref[0],
          currentId = _ref[1];

      var waitingFor = bid.get('waitingForOrganization');
      if (waitingFor) {
        if (currentId === waitingFor.get('id')) {
          return '<span class="waiting-for-you">' + this.get('intl').t('you') + '</span>';
        } else {
          return '<span>' + this.get('intl').t('them') + '</span>';
        }
      } else {
        return '-';
      }
    }
  });
});