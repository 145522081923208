define('webapp/initializers/model-get-id', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    _emberData.default.Model.reopen({
      getId: function getId(key) {
        var rel = this._internalModel._relationships.initializedRelationships;
        return rel && rel[key] && rel[key].canonicalState && rel[key].canonicalState.id;
      },
      getIds: function getIds(key) {
        var rel = this._internalModel._relationships.initializedRelationships;
        var canonicalState = rel && rel[key] && rel[key].canonicalState && rel[key].canonicalState;
        var ids = [];
        if (canonicalState) {
          ids = canonicalState.mapBy('id');
        }
        return ids;
      }
    });
  }

  exports.default = {
    name: 'model-get-id',
    initialize: initialize
  };
});