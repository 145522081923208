define('webapp/pods/application/adapter', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'webapp/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    namespace: 'api/v1',

    authorize: function authorize(xhr) {
      var _get = this.get('session.data.authenticated'),
          email = _get.email,
          token = _get.token;

      if (Ember.isPresent(email) && Ember.isPresent(token)) {
        xhr.setRequestHeader('X-User-Email', email);
        xhr.setRequestHeader('X-User-Token', token);

        // Updating the pusher ajax call to also include
        // the user authentication tokens

        _environment.default.APP.PUSHER.connection.auth = {
          headers: {
            'X-User-Email': email,
            'X-User-Token': token
          }
        };
      } else {
        var params = new URLSearchParams(window.location.search);
        var param_token = params.get('token');

        if (Ember.isPresent(param_token)) {
          xhr.setRequestHeader('X-User-Token', param_token);
        }
      }
    }
  });
});