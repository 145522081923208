define('webapp/components/driver-position-window/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    map: null,
    marker: null,
    infoWindow: null,
    lat: null,
    long: null,
    driver: null,
    uuid: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('uuid', 'info-window-' + Ember.uuid());
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (!this.get('map') || !this.get('marker')) {
        return;
      }

      if (this.get('infoWindow')) {
        this.get('infoWindow').setContent(this.$().html());
      } else {
        var infoWindow = new google.maps.InfoWindow({
          content: this.$().html()
        });
        this.set('infoWindow', infoWindow);
        infoWindow.open(this.get('map'), this.get('marker'));
      }
    }
  });
});