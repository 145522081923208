define('webapp/pods/organization/view/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend({

    columns: ['number', 'odometer_unit', 'license_plate', 'vehicle_id_number', 'nickname'],
    sortColumn: 'number',
    sortDirection: 'ascending',

    sortDefinition: computed('sortColumn', 'sortDirection', function () {
      var dir = void 0;
      if (this.get('sortDirection') == 'ascending') {
        dir = 'asc';
      } else {
        dir = 'desc';
      }
      return [this.get('sortColumn') + ':' + dir];
    }),

    sortedData: computed.sort('model.power_units', 'sortDefinition'),

    actions: {
      sortByColumn: function sortByColumn(name) {
        var column = this.get('sortColumn');
        var dir = this.get('sortDirection');

        this.set('sortColumn', name);
        this.set('sortDirection', column == name ? dir == "ascending" ? "descending" : "ascending" : "ascending");
      }
    }

  });
});