define('webapp/models/commission', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'webapp/mixins/model-request', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _modelRequest, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({});

  exports.default = _model.default.extend(Validations, _modelRequest.default, {

    intl: inject.service(),

    amount: (0, _attr.default)('number'),
    status: (0, _attr.default)('string'),

    user: (0, _relationships.belongsTo)(),
    listing: (0, _relationships.belongsTo)()

  });
});