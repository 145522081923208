define('webapp/helpers/error-messages', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.errorMessages = errorMessages;


  var friendly = function friendly(text) {
    return text.replace(/_/, ' ').replace(/\w\S*/g, function (text) {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
    });
  };

  function errorMessages(_ref) {
    var errors = _ref[0];

    if (Ember.isBlank(errors)) {
      return;
    }
    var html = "<ul class='ui list'>";
    if (typeof errors === "string") {
      html += "<li>" + errors + "</li>";
    } else if (errors.constructor === _emberData.default.Errors || !Ember.isArray(errors) && errors.forEach != null) {
      errors.forEach(function (item) {
        html += "<li><b>" + friendly(item.attribute) + ":</b> " + item.message + "</li>";
      });
    } else if (Ember.isArray(errors)) {
      var i = void 0,
          len = void 0,
          item = void 0;
      for (i = 0, len = errors.length; i < len; i++) {
        item = errors[i];
        html += "<li>" + item + "</li>";
      }
    } else {
      var key = void 0,
          value = void 0;
      for (key in errors) {
        value = errors[key];
        if (Ember.isArray(value)) {
          var j = void 0,
              len1 = void 0,
              _item = void 0;
          for (j = 0, len1 = value.length; j < len1; j++) {
            _item = value[j];
            html += "<li><b>" + friendly(key) + ":</b> " + _item + "</li>";
          }
        } else {
          html += "<li><b>" + friendly(key) + ":</b> " + value + "</li>";
        }
      }
    }
    html += "</ul>";
    return new Ember.String.htmlSafe(html);
  }

  exports.default = Ember.Helper.helper(errorMessages);
});