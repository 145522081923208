define('webapp/models/load-cargo-destination', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({

    // attributes

    total_units: {
      descriptionKey: "totalUnits",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', { gte: 0 })]
    },

    total_weight: {
      descriptionKey: "totalWeight",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', { gte: 0 })]
    },

    // relationships

    cargo: {
      descriptionKey: "cargoItems",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },

    dropoff_operation: {
      descriptionKey: "dropOff",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    }

  });

  exports.default = _model.default.extend(Validations, {

    total_units: (0, _attr.default)('number', { defaultValue: 0 }),
    total_weight: (0, _attr.default)('number', { defaultValue: 0 }),
    created_at: (0, _attr.default)('datetime-utc'),
    updated_at: (0, _attr.default)('datetime-utc'),

    cargo: (0, _relationships.belongsTo)('load-cargo', { async: false }),
    dropoff_operation: (0, _relationships.belongsTo)('load-operation', { async: false }),

    // computed properties

    pickup: computed.alias('cargo.pickup_operation'),

    description: computed.alias('cargo.description'),

    friendly_name: computed.alias('cargo.friendly_name'),

    total_value: computed('cargo.{needs_unit_count,needs_weight}', 'total_units', 'total_weight', {
      get: function get() {
        if (this.get('cargo.needs_unit_count')) {
          return this.get('total_units');
        }
        return this.get('total_weight');
      },
      set: function set(key, value) {
        if (this.get('cargo.needs_unit_count')) {
          this.set('total_units', value);
        } else {
          this.set('total_weight', value);
        }
        return value;
      }
    }),

    all_selected: computed('cargo.total_value', 'total_value', {
      get: function get() {
        return this.get('cargo.total_value') === this.get('total_value');
      },
      set: function set(key, value) {
        if (value) {
          this.set('total_value', this.get('cargo.total_value'));
        } else {
          this.set('total_value', 0);
        }
        return value;
      }
    })

  });
});