define('webapp/validators/zip-validator', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;


  var REGEX = {
    US: /^\d{5}(?:[-\s]\d{4})?$/,
    CA: /^\s*[a-ceghj-npr-tvxy]\d[a-ceghj-npr-tv-z](\s)?\d[a-ceghj-npr-tv-z]\d\s*$/i,
    MX: /^\d{5}$/
  };

  exports.default = _base.default.extend({

    intl: inject.service(),

    validate: function validate(value, ctx, model) {
      var countryCode = model.get('country');
      if (!countryCode) {
        return true;
      }
      if (REGEX[countryCode].test(value)) {
        return true;
      } else {
        return this.get('intl').t('errors.invalidZip');
      }
    }
  });
});