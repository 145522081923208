define('webapp/components/view-load', ['exports', 'common/components/view-load'], function (exports, _viewLoad) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _viewLoad.default;
    }
  });
});