define('webapp/models/organization-billing-address', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    organization: {
      descriptionKey: "organizationMenuLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'),
      // Don't include belongs-to for validation
      (0, _emberCpValidations.validator)('presence', true)]
    },

    address: {
      descriptionKey: "addressLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', true)]
    },

    company_name: {
      descriptionKey: "companyNameLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 100 })]
    },

    contact_name: {
      descriptionKey: "contactNameLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 100 })]
    },

    phone_number: {
      descriptionKey: "phoneNumberLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        type: 'phone'
      })]
    },

    fax_number: {
      descriptionKey: "faxNumberLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('format', {
        type: 'phone',
        allowBlank: true
      })]
    },

    email: {
      descriptionKey: "emailLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/
      })]
    }
  });

  exports.default = _model.default.extend(Validations, {

    company_name: (0, _attr.default)('string'),
    contact_name: (0, _attr.default)('string'),
    phone_number: (0, _attr.default)('string'),
    fax_number: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    created_at: (0, _attr.default)('datetime_utc'),
    updated_at: (0, _attr.default)('datetime_utc'),

    address: (0, _relationships.belongsTo)('organization-address'),
    organization: (0, _relationships.belongsTo)(),
    invoices: (0, _relationships.hasMany)('load_invoice')

  });
});