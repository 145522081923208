define('webapp/pods/users/password/edit/controller', ['exports', 'uncharted-ajax'], function (exports, _unchartedAjax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['reset_password_token'],
    password: null,
    password_confirmation: null,
    errors: null,

    text_changed: Ember.observer('password', 'password_confirmation', function () {
      if (this.get('errors')) {
        this.set('errors', null);
      }
    }),

    actions: {
      change: function change() {
        var _this = this;

        return (0, _unchartedAjax.default)({
          url: "/api/v1/users/password",
          type: 'PUT',
          dataType: 'json',
          data: {
            'user[password]': this.get('password'),
            'user[password_confirmation]': this.get('password_confirmation'),
            'user[reset_password_token]': this.get('reset_password_token')
          }
        }).then(function () {
          return _this.transitionToRoute('users.login', { queryParams: { password_reset: true } });
        }).catch(function (result) {
          return _this.set('errors', result.responseJSON.errors);
        });
      },
      reset: function reset() {
        this.set('password', null);
        this.set('password_confirmation', null);
        this.set('errors', null);
      }
    }
  });
});