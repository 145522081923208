define('webapp/models/load-cargo', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'ember-data-copyable'], function (exports, _model, _attr, _relationships, _emberCpValidations, _emberDataCopyable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      isBlank = Ember.isBlank;


  var Validations = (0, _emberCpValidations.buildValidations)({

    // attributes

    description: {
      descriptionKey: "description",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 250 })]
    },

    unit_type: {
      descriptionKey: "unitType",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },

    total_units: {
      descriptionKey: "totalUnits",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        gt: 0,
        disabled: computed.not('model.needs_unit_count')
      })]
    },

    total_weight: {
      descriptionKey: "totalWeight",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        gt: 0,
        disabled: computed.alias('model.needs_unit_count')
      }),
      // if we need a unit count, then disable
      (0, _emberCpValidations.validator)('number', {
        is: 0,
        disabled: computed.alias('model.needs_weight')
      })]
    },

    is_palletized: {
      descriptionKey: "Is Palletized",
      validators: [(0, _emberCpValidations.validator)('ds-error'),
      // If it needs a unit count, then disable this validation
      (0, _emberCpValidations.validator)('inclusion', {
        in: [false],
        disabled: computed.alias('model.needs_unit_count')
      }),
      // If it needs weight, then disable this validation
      (0, _emberCpValidations.validator)('inclusion', {
        in: [true, false],
        disabled: computed.alias('model.needs_weight')
      })]
    },

    total_pallets: {
      descriptionKey: "numberOfPallets",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('number', {
        gt: 0,
        disabled: computed('model.needs_weight', 'model.is_palletized', function () {
          // if we require weight, disable
          if (this.get('model.needs_weight')) {
            return true;
          }
          // if its not palletized, it needs to be zero
          return !this.get('model.is_palletized');
        })
      }), (0, _emberCpValidations.validator)('number', {
        is: 0,
        disabled: computed('model.needs_weight', 'model.is_palletized', function () {
          // if we need weight, ensure its 0
          if (this.get('model.needs_weight')) {
            return false;
          }
          // if its palletized, ensure its greater than 0
          return this.get('model.is_palletized');
        })
      })]
    },

    // relationships

    pickup_operation: {
      descriptionKey: "pickUp",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },

    // validate destination quantities are correct
    // (the whole total is distributed along dropoff locations)

    destinations: (0, _emberCpValidations.validator)('cargo-quantities', {
      disabled: computed.equal('model.destinations.length', 0),
      dependentKeys: ['model.destinations.@each.total_value', 'total_selected']
    })

  });

  var LoadCargo = _model.default.extend(Validations, _emberDataCopyable.default, {

    unit_type: (0, _attr.default)(),
    weight_type: (0, _attr.default)({ defaultValue: 'lbs' }),

    description: (0, _attr.default)(),
    total_units: (0, _attr.default)("number", { defaultValue: 0 }),
    total_weight: (0, _attr.default)("number", { defaultValue: 0 }),
    total_pallets: (0, _attr.default)("number", { defaultValue: 0 }),
    is_palletized: (0, _attr.default)("boolean", { defaultValue: false }),
    created_at: (0, _attr.default)("datetime-utc"),
    updated_at: (0, _attr.default)("datetime-utc"),

    pickup_operation: (0, _relationships.belongsTo)('load-operation', { async: false }),
    destinations: (0, _relationships.hasMany)('load-cargo-destination', { async: false }),
    destinationsSort: ['id:asc'],
    sortedDestinations: computed.sort('destinations', 'destinationsSort'),

    // computed properties

    needs_unit_count: computed('unit_type', function () {
      return this.get('unit_type') !== 'bulk';
    }),

    needs_weight: computed.not('needs_unit_count'),

    total_value: computed('needs_unit_count', 'needs_weight', 'total_units', 'total_weight', {
      get: function get() {
        if (this.get('needs_unit_count')) {
          return this.get('total_units');
        }
        return this.get('total_weight');
      },
      set: function set(key, value) {
        if (this.get('needs_unit_count')) {
          this.set('total_units', value);
        } else {
          this.set('total_weight', value);
        }
        return value;
      }
    }),

    destinations_total_value: computed.mapBy('destinations', 'total_value'),
    total_selected: computed.sum('destinations_total_value'),

    all_selected: computed.equal('total_value', 'total_selected'),

    friendly_name: computed('needs_unit_count', 'needs_weight', function () {
      if (this.get('needs_unit_count')) {
        return this.get('intl').t("units");
      } else if (this.get('needs_weight')) {
        return this.get('intl').t("weight") + ' (' + this.get('weight_type') + ')';
      } else {
        throw '' + this.get('intl').t("unableCargoType");
      }
    }),

    dropoffs_with_values: computed('destinations.@each.{dropoff_operation,no_total_value}', function () {
      var destinations = this.get('destinations').filterBy('no_total_value', false);
      var dropoffs = destinations.mapBy('dropoff_operation');
      return dropoffs;
    })

  });

  LoadCargo.reopenClass({
    unitTypes: ["bag", "binCardboard", "binPlastic", "box", "bulk", "carton", "crate", "container", "rpc", "tote"]
  });

  exports.default = LoadCargo;
});