define("webapp/helpers/in-array", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var helper = Ember.Helper.helper;
	exports.default = helper(function (_ref) {
		var value = _ref[0],
		    comparisonArray = _ref[1];

		return comparisonArray.includes(value);
	});
});