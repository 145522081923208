define('webapp/pods/organization/help/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller, context, params) {
      this._super.apply(this, arguments);

      var organizationController = this.controllerFor('organization');
      controller.set('signed_up', params.queryParams.signed_up);
    }
  });
});