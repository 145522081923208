define('webapp/pods/organization/dispatch/index/route', ['exports', 'common/mixins/live-sync'], function (exports, _liveSync) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A,
      inject = Ember.inject;


  var mappings = {
    load_operation: 'location,location.address'
  };

  exports.default = Ember.Route.extend((0, _liveSync.default)(['driver', 'haul', 'load', 'load_operation', 'load_path', 'load_operation_audit'], mappings), {

    sync: inject.service(),

    model: function model() {

      if (this.get('session.currentOrganization.isShipper')) {
        return Ember.RSVP.hash({
          hauls: this.store.query('haul', {
            filter: {
              currently_active: true
            },
            include: ['active-driver', 'driver-assignments', 'driver-assignments.driver', 'driver-assignments.driver.current-organization', 'loads', 'loads.operations', 'loads.operations.location', 'loads.operations.location.address'].join(',')
          })
        });
      }

      return Ember.RSVP.hash({

        hauls: this.store.query('haul', {
          filter: {
            organization_id: this.get('session.currentOrganization.id'),
            currently_active: true
          },
          sort: '-updated_at',
          include: ['active-driver', 'driver-assignments', 'driver-assignments.driver', 'driver-assignments.driver.current-organization', 'loads', 'loads.operations', 'loads.operations.location', 'loads.operations.location.address', 'loads.shipper-organization'].join(',')
        }),

        loads_for_today: this.store.query('load', {
          filter: {
            today: true
          },
          include: ['operations', 'operations.location', 'operations.location.address', 'haul', 'haul.driver-assignments', 'haul.driver-assignments.driver', 'haul.driver-assignments.driver.current-organization'].join(',')
        }),

        unassigned_drivers: this.store.query('driver', {
          filter: {
            current_organization_id: this.get('session.currentOrganization.id'),
            assigned: false,
            status: 'active'
          },
          page: {
            size: 100
          },
          include: 'current-organization'
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('hauls', A(model.hauls.toArray()));

      if (model.loads_for_today) {
        // Remove hauls that are already included in loads_for_today
        // collection, since we will only display a predictive route line
        // from driver's last position to the pickup location REV-977
        model.loads_for_today.forEach(function (load) {
          controller.get('hauls').removeObject(load.get('haul'));
        });
        controller.set('loads_for_today', model.loads_for_today);
      }

      if (model.unassigned_drivers) {
        controller.set('unassigned_drivers', A(model.unassigned_drivers.toArray()));
      }

      // add new models to drivers/hauls that were not present
      // in the initial xhr requests
      this.get('sync').on('updated', this, function (model, type) {
        if (type == 'haul') {
          controller.get('hauls').addObject(model);
        }
        if (type == 'driver' && controller.get('unassigned_drivers.length')) {
          controller.get('unassigned_drivers').addObject(model);
        }
      });
    }
  });
});