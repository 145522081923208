define('webapp/pods/organization/locations/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        location: this.store.findRecord('location', params.location_id, {
          include: 'address,operating-hours'
        })
      });
    },
    afterModel: function afterModel(model) {
      // route: 'organization.locations.edit',
      return new Ember.RSVP.Promise(function (resolve /*, reject*/) {
        var address = model.location.get('address');
        var address_text = [address.get('address_1'), address.get('city'), address.get('state'), address.get('country'), address.get('zip')].join(' ');

        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: address_text }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            model.geo = results;
            resolve();
          }
          resolve();
        });
      });
    },
    setupController: function setupController(controller, model) {
      if (model.geo) {
        model.center = model.geo[0].geometry.location;
        model.zoom = 15;
        model.formatted_address = model.geo[0].formatted_address;
        delete model.geo;
      } else {
        model.center = { lat: 39.850033, lng: -97.6500523 };
        model.zoom = 4;
      }

      controller.set('location', model.location);

      controller.set('center', model.center);
      controller.set('zoom', model.zoom);
      controller.set('formatted_address', model.formatted_address);
    }
  });
});