define('webapp/helpers/calculate-distance', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.calculateDistance = calculateDistance;


  var meters_in_mile = 0.000621371;

  function calculateDistance(params /*, hash*/) {
    var from = params[0];
    var to = params[1];

    var result = google.maps.geometry.spherical.computeDistanceBetween(from, to) * meters_in_mile;
    if (isNaN(result)) {
      return null;
    }
    return result;
  }

  exports.default = Ember.Helper.helper(calculateDistance);
});