define('webapp/models/user-notification', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'webapp/mixins/model-request'], function (exports, _model, _attr, _relationships, _modelRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend(_modelRequest.default, {

    title: (0, _attr.default)('string'),
    from: (0, _attr.default)('string'),
    to: (0, _attr.default)('string'),
    body: (0, _attr.default)('string'),
    kind: (0, _attr.default)('string'),
    expires: (0, _attr.default)('datetime'),
    pinned: (0, _attr.default)('boolean'),
    unread: (0, _attr.default)('boolean', { defaultValue: true }),
    archived: (0, _attr.default)('boolean'),
    created_at: (0, _attr.default)('datetime_utc'),
    updated_at: (0, _attr.default)('datetime_utc'),

    user: (0, _relationships.belongsTo)(),

    links: function links() {
      return this.do_post('links');
    }
  });
});