define('webapp/services/sync', ['exports', 'webapp/config/environment', 'ember-concurrency', 'ember-inflector'], function (exports, _environment, _emberConcurrency, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* global Pusher */
  var LiveSync = Ember.Object.extend({
    send: function send(operation, id, include_mappings, onComplete) {
      if (this.get('isDestroyed')) {
        return;
      }
      if (this.get('model.isDestroyed')) {
        return;
      }

      var record = this.get('store').peekRecord(this.get('model'), id);

      if (operation === 'create' || operation === 'update') {
        if (record) {
          this.get('update').perform(record, include_mappings, onComplete);
        } else {
          this.get('load').perform(id, include_mappings, onComplete);
        }
      }

      if (operation === 'destroy' && record) {
        this.get('destroy').perform(record);
      }
    },


    load: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(id, include_mappings, onComplete) {
      var includes, model, models;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              // Load the new record from the server
              if (_environment.default.environment === 'development') {
                Ember.Logger.log('Loading ' + this.get('model') + ':' + id);
              }
              includes = include_mappings[this.get("model")];
              model = void 0;

              if (!includes) {
                _context.next = 10;
                break;
              }

              _context.next = 6;
              return this.get('store').query(this.get('model'), { filter: { id: id }, include: includes });

            case 6:
              models = _context.sent;

              model = models.objectAt(0);
              _context.next = 13;
              break;

            case 10:
              _context.next = 12;
              return this.get('store').findRecord(this.get('model'), id);

            case 12:
              model = _context.sent;

            case 13:

              onComplete(model, this.get('model'));

            case 14:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).keepLatest(),

    update: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(record, include_mappings, onComplete) {
      var includes, model, models;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              if (!(record.get('isLoaded') && !record.get('isSaving') && !record.get('isDeleted'))) {
                _context2.next = 19;
                break;
              }

              if (_environment.default.environment === 'development') {
                Ember.Logger.log('Reloading ' + this.get('model') + ':' + record.get('id'));
              }
              includes = include_mappings[this.get("model")];
              model = void 0;

              if (!includes) {
                _context2.next = 11;
                break;
              }

              _context2.next = 7;
              return this.get('store').query(this.get('model'), { filter: { id: record.get('id') }, include: includes });

            case 7:
              models = _context2.sent;

              model = models.objectAt(0);
              _context2.next = 14;
              break;

            case 11:
              _context2.next = 13;
              return record.reload();

            case 13:
              model = _context2.sent;

            case 14:

              onComplete(model, this.get('model'));

              // Add a little buffer time so we don't overwhelm that backend server
              _context2.next = 17;
              return (0, _emberConcurrency.timeout)(1000);

            case 17:
              _context2.next = 20;
              break;

            case 19:
              Ember.Logger.log('Skipping ' + this.get('model') + ':' + record.get('id'));

            case 20:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).keepLatest(),

    destroy: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3(record) {
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              if (_environment.default.environment === 'development') {
                Ember.Logger.log('Removing ' + this.get('model') + ':' + record.get('id'));
              }

              // Rollback any pending changes
              if (record.get('hasDirtyAttributes')) {
                record.rollback();
              }

              // TODO: Removing a record from the store is causing a major bug where ember
              // continues to query the backend over and over and over and over and over
              // and over and over again. Until that is solved (possibly by an ember-data upgrade)
              // I'm disabling this part.

              // Delete the record
              // yield record.destroyRecord({ adapterOptions: { clientSideDelete: true }});

              // Unload the record
              // yield record.unloadRecord();

            case 2:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })).enqueue()
  });

  exports.default = Ember.Service.extend(Ember.Evented, {
    init: function init() {
      this._super.apply(this, arguments);
      this.set('pusher', new Pusher(_environment.default.APP.PUSHER.key, _environment.default.APP.PUSHER.connection));
    },
    subscribe: function subscribe(store, model) {
      var _this = this;

      var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var include_mappings = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      var channel_name = prefix ? prefix + '__' + (0, _emberInflector.pluralize)(Ember.String.underscore(model)) : (0, _emberInflector.pluralize)(model);
      var target = LiveSync.create({ store: store, model: model, channel_name: channel_name });

      if (_environment.default.environment === 'development') {
        Ember.Logger.info('Subscribing to ' + channel_name);
      }
      var channel = this.get('pusher').subscribe(channel_name);
      channel.bind('create', function (id) {
        target.send('create', id, include_mappings, function (model, model_type) {
          return _this.trigger('created', model, model_type);
        });
      });
      channel.bind('update', function (id) {
        target.send('update', id, include_mappings, function (model, model_type) {
          return _this.trigger('updated', model, model_type);
        });
      });
      channel.bind('destroy', function (id) {
        target.send('destroy', id);
      });

      return target;
    },
    unsubscribe: function unsubscribe(target) {
      if (_environment.default.environment === 'development') {
        Ember.Logger.info('Unsubscribing from ' + target.get('channel_name'));
      }
      var channel = this.get('pusher').unsubscribe(target.get('channel_name'));
    }
  });
});