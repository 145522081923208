define('webapp/pods/organization/loads/tracking/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('load', params.load_id, {
        include: ['haul', 'haul.organization', 'haul.driver-assignments', 'haul.driver-assignments.driver', 'paths', 'operations', 'operations.images', 'operations.location', 'operations.location.address', 'operation-audits', 'operation-audits.operation', 'operation-audits.operation.location', 'operation-audits.operation.location.address'].join(',')
      });
    }
  });
});