define('webapp/validators/dropoff-quantities', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = _base.default.extend({

    intl: inject.service(),

    validate: function validate(_, options, dropoff, attribute) {
      if (dropoff.get('dropoff_cargo_destinations').every(function (dest) {
        return dest.get('total_value') * 1 === 0;
      })) {
        return this.get('intl').t('errors.cargoBadDistribution');
      }
      return true;
    }
  });
});