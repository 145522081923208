define('webapp/helpers/gallons-to-liters', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Helper.extend({

    intl: inject.service(),

    compute: function compute(_ref) {
      var quantity = _ref[0],
          system = _ref[1],
          precision = _ref[2];

      var value = 0;
      if (quantity) {
        if (system == 'metric') {
          value = quantity * 3.78541;
        } else {
          value = quantity;
        }
      }

      if (precision) {
        value = Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
        value = this.get('intl').formatNumber(value);
      }

      var unit = system == 'metric' ? ' L' : ' gal';

      return value + unit;
    }
  });
});