define('webapp/models/organization-address', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    organization: {
      descriptionKey: "organizationMenuLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'),
      // Don't include belongs-to for validation
      (0, _emberCpValidations.validator)('presence', true)]
    },

    address_1: {
      descriptionKey: "address1",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 150 })]
    },

    address_2: {
      descriptionKey: "address2",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', { max: 150 })]
    },

    city: {
      descriptionKey: "cityLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 100 })]
    },

    state: {
      descriptionKey: "stateLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'),
      // validator('presence', true),
      (0, _emberCpValidations.validator)('presence', {
        presence: true,
        dependentKeys: ['country']
      }), (0, _emberCpValidations.validator)('length', {
        dependentKeys: ['country']
      })]
    },

    country: {
      descriptionKey: "country",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },

    zip: {
      descriptionKey: "zipCodeLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('zip-validator', {
        dependentKeys: ['country']
      })]
    }

  });

  exports.default = _model.default.extend(Validations, {

    address_1: (0, _attr.default)('string'),
    address_2: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    country: (0, _attr.default)('string', { defaultValue: 'US' }),
    zip: (0, _attr.default)('string', { defaultValue: '' }), // Set default value to an empty string so it doesn't fail on #formatted_zip in case value is undefined
    latitude: (0, _attr.default)('number'),
    longitude: (0, _attr.default)('number'),
    timezone: (0, _attr.default)(),
    created_at: (0, _attr.default)('datetime_utc'),
    updated_at: (0, _attr.default)('datetime_utc'),

    // Inverse needs to be null, or it clears the organization on a new address creation
    organization: (0, _relationships.belongsTo)({ inverse: null }),
    billing_addresses: (0, _relationships.hasMany)('organization-billing-address'),

    formatted_zip: computed('zip', function () {
      var zip = this.get('zip');
      zip = zip.replace(/\D/g, "");
      if (zip.length > 5) {
        return zip.substr(0, 5) + '-' + zip.substr(5);
      }
      return zip;
    }),

    line2: computed('city', 'state', 'zip', 'country', function () {
      return this.get('city') + ', ' + this.get('state') + ' ' + this.get('formatted_zip');
    }),

    default_country_code: computed('country', function () {
      if (this.get('country') === 'MX') {
        return "52";
      } else {
        return "1";
      }
    })

  });
});