define('webapp/pods/organization/locations/view/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    sorted_by: ['position'],
    sorted_operating_hours: Ember.computed.sort('model.operating_hours', 'sorted_by'),

    actions: {
      redirectToLocations: function redirectToLocations() {
        this.transitionToRoute('organization.locations');
      }
    }
  });
});