define('webapp/helpers/miles-to-km', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Helper.extend({

    intl: inject.service(),

    compute: function compute(_ref) {
      var quantity = _ref[0],
          system = _ref[1];

      var value = void 0,
          unit = void 0;
      quantity = quantity || 0;

      if (system == 'metric') {
        value = quantity * 1.60934;
        unit = 'km';
      } else {
        value = quantity;
        unit = 'Mi';
      }

      value = value.toFixed(0);

      return this.get('intl').formatNumber(value) + ' ' + unit;
    }
  });
});