define('webapp/mixins/ss-modal', ['exports', 'ember-spread', 'webapp/mixins/ss-transition'], function (exports, _emberSpread, _ssTransition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame || function (callback) {
    setTimeout(callback, 0);
  };

  exports.default = Ember.Mixin.create(_emberSpread.default, _ssTransition.default, {
    classNames: ['ui', 'modal'],

    // Defaults
    padding: 50,
    offset: 0,
    closable: true,

    _isShown: false,

    // Transition Defaults
    transitionMode: 'scale',
    transitionDuration: 500,

    // Setup and destroy
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      // Ensure scrolling is gone for initial render
      this.removeScrolling();
      // Add body classes
      window.$('body').addClass('dimmable undetached dimmed scrolling');
      window.$('body').append('<div class="ui dimmer modals page transition visible active" style="display: flex !important"></div>');
      // Set values
      this.doRefresh();
      this.transitionIn();
      window.$(window).on('resize.ss-modal-' + this.get('elementId'), Ember.run.bind(this, this.doRefreshWithAnimation));
      if (this.get('closable')) {
        window.$(document).on('click.ss-modal-' + this.get('elementId'), Ember.run.bind(this, this.checkClick));
      }
      this.trackChanges();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      window.$(window).off('resize.ss-modal-' + this.get('elementId'));
      window.$(document).off('click.ss-modal-' + this.get('elementId'));
      if (this.get('observer') != null) {
        this.get('observer').disconnect();
      }
      window.$('body').removeClass('dimmable dimmed');
      window.$('.ui.dimmer.modals.page.transition.visible.active').remove();
    },


    // Events
    checkClick: function checkClick(event) {
      if (!this.get('_isShown')) {
        return;
      }
      var target = window.$(event.target);
      var isInModal = target.closest(this.$()).length > 0;
      var isInDOM = window.$.contains(window.document.documentElement, event.target);

      if (!isInModal && isInDOM) {
        this.closeModal();
      }
    },
    trackChanges: function trackChanges() {
      var _this = this;

      if ('MutationObserver' in window) {
        var observer = new MutationObserver(function () {
          return _this.doRefresh();
        });
        observer.observe(this.get('element'), {
          childList: true,
          subtree: true
        });
        this.set('observer', observer);
      }
    },


    // Public method to close
    closing: function closing() {},
    // callback hook

    closeModal: function closeModal() {
      this.closing();
      this.transitionOut();
    },
    opened: function opened() {},
    // callback hook

    // Overwrite transition callback
    shown: function shown() {
      this.set('_isShown', true);
      this.opened();
    },


    // Overwrite transition callback
    hidden: function hidden() {
      var modalClosed = this.get('modal_closed');
      if (typeof modalClosed === "function") {
        modalClosed();
      }
    },


    // Functions
    doRefresh: function doRefresh(sizes) {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }
      if (Ember.isBlank(sizes)) {
        sizes = this.getSizes();
      }
      this.setScreenHeight(sizes);
      this.setType(sizes);
      this.setPosition(sizes);
    },
    doRefreshWithAnimation: function doRefreshWithAnimation() {
      requestAnimationFrame(Ember.run.bind(this, this.doRefresh, null));
    },


    // Method functions
    getSizes: function getSizes() {
      var modalHeight = this.$().outerHeight();
      return {
        pageHeight: window.$(document).outerHeight(),
        height: modalHeight + this.get('offset'),
        contextHeight: window.$(window).height()
      };
    },
    canFit: function canFit(sizes) {
      return sizes.height + this.get('padding') * 2 < sizes.contextHeight;
    },
    removeScrolling: function removeScrolling() {
      window.$('body').removeClass('scrolling');
      this.$().parent().css({
        overflow: ''
      });
      this.$().removeClass('scrolling');
    },
    setScrolling: function setScrolling() {
      window.$('body').addClass('scrolling');
      this.$().parent().css({
        overflow: 'auto'
      });
      this.$().addClass('scrolling');
    },
    setPosition: function setPosition(sizes) {
      if (this.canFit(sizes)) {
        this.$().css({
          top: '',
          marginTop: -(sizes.height / 2)
        });
      } else {
        this.$().css({
          marginTop: '',
          top: window.$(document).scrollTop()
        });
      }
    },
    setScreenHeight: function setScreenHeight(sizes) {
      if (this.canFit(sizes)) {
        window.$('body').css('height', '');
      } else {
        window.$('body').css('height', sizes.height + this.get('padding') * 2);
      }
    },
    setType: function setType(sizes) {
      if (this.canFit(sizes)) {
        this.removeScrolling();
      } else {
        this.setScrolling();
      }
    },


    actions: {
      close: function close() {
        this.closeModal();
        window.$('body').removeClass('dimmed');
        window.$('.ui.dimmer.modals.page.transition.visible.active').remove();
      }
    }
  });
});