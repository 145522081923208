define('webapp/mixins/model-request', ['exports', 'uncharted-ajax'], function (exports, _unchartedAjax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    request: function request(action, data, method) {
      var modelName = this.get('_internalModel.modelName');
      var adapter = this.store.adapterFor(modelName);
      var url = adapter.buildURL(modelName, this.get('id')) + '/' + action;
      return (0, _unchartedAjax.default)({
        method: method,
        url: url,
        dataType: 'json',
        data: data
      });
    },
    do_get: function do_get(action, data) {
      return this.request(action, data, 'GET');
    },
    do_post: function do_post(action, data) {
      return this.request(action, data, 'POST');
    },
    do_delete: function do_delete(action, data) {
      return this.request(action, data, 'DELETE');
    }
  });
});