define('webapp/formats', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    date: {
      'time-style': {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
    },
    number: {
      usd_code: { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, locale: 'en' },
      separated: { useGrouping: true, style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 },
      EUR: { style: 'currency', currency: 'EUR' },
      USD: { style: 'currency', currency: 'USD' }
    },
    time: {
      hhmmss: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
    }
  };
});