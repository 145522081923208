define('webapp/pods/drivers/confirmation/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    controllerName: 'drivers.password',
    templateName: '',

    driver_confirmation: Ember.inject.service(),

    model: function model(params) {
      return this.get('driver_confirmation.confirm').perform(params.confirmation_token);
    },
    afterModel: function afterModel(model) {
      if (model['success']) {
        return this.transitionTo('drivers.password.new', { queryParams: { reset_password_token: model['reset_password_token'] } });
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('is_confirmation', true);
      controller.set('confirmed', model);
    }
  });
});