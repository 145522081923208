define('webapp/models/itm-device', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    hardware_unit_id: (0, _attr.default)('string'),
    firmware_version: (0, _attr.default)('string'),
    battery_level: (0, _attr.default)('string'),

    organization: (0, _relationships.belongsTo)(),
    itm_lines: (0, _relationships.hasMany)()

  });
});