define('webapp/pods/organization/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Controller.extend({
    queryParams: ['signed_up'],
    signed_up: false
  });
});