define('webapp/transforms/datetime-utc', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(value) {
      return value && moment.utc(value).toDate();
    },
    serialize: function serialize(value) {
      return value && moment.utc(value).format();
    }
  });
});