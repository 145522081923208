define('webapp/initializers/polyfills', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    Object.defineProperty(Array.prototype, 'flat', {
      value: function value() {
        var depth = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

        return this.reduce(function (flat, toFlatten) {
          return flat.concat(Array.isArray(toFlatten) && depth - 1 ? toFlatten.flat(depth - 1) : toFlatten);
        }, []);
      }
    });
  }

  exports.default = {
    initialize: initialize
  };
});