define('webapp/validators/dot-number-valid', ['exports', 'ember-cp-validations/validators/base', 'uncharted-ajax'], function (exports, _base, _unchartedAjax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    intl: Ember.inject.service(),

    validate: function validate(value) {
      var _this = this;

      return (0, _unchartedAjax.default)({
        method: 'GET',
        url: '/api/v1/organizations/dot_number_lookup',
        dataType: 'json',
        data: {
          dot_number: value
        }
      }).then(function (result) {
        if (result) {
          return true;
        } else {
          return _this.get('intl').t('errors.invalid_dot');
        }
      });
    }
  });
});