define('webapp/models/power-unit', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    number: {
      descriptionKey: 'number',
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    odometer_unit: {
      descriptionKey: 'odometer_unit',
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', { in: ['kilometers', 'miles'] })]
    }
  });

  exports.default = _model.default.extend(Validations, {
    number: (0, _attr.default)('string'),
    odometer_unit: (0, _attr.default)('string', { defaultValue: 'miles' }),
    license_plate: (0, _attr.default)('string'),
    vehicle_id_number: (0, _attr.default)('string'),
    nickname: (0, _attr.default)('string'),
    max_odometer_reading: (0, _attr.default)('string'),
    max_refueled_at: (0, _attr.default)('date'),

    organization: (0, _relationships.belongsTo)(),
    loads: (0, _relationships.hasMany)('load'),
    inKms: computed.equal('odometer_unit', 'kilometers')
  });
});