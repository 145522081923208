define('webapp/models/load-operation', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'webapp/mixins/statuses'], function (exports, _model, _attr, _relationships, _emberCpValidations, _statuses) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      A = Ember.A;


  var Validations = (0, _emberCpValidations.buildValidations)({

    // attributes

    reference_number: {
      descriptionKey: computed('model.is_pickup', function () {
        if (this.get('model.is_pickup')) {
          return "pickupNumberLabel";
        }
        return "dropoffNumberfLabel";
      }),
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed.alias('model.is_dropoff')
      }), (0, _emberCpValidations.validator)('length', { max: 100 })]
    },

    purchase_order_number: {
      descriptionKey: "purchageOrderNumber",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', { max: 100 }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        // if its a pickup, disable so we don't try to valid
        disabled: computed.alias('model.is_pickup')
      })]
    },

    status: {
      descriptionKey: "status",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', { in: ['setup', 'pending', 'viewed', 'en_route', 'checked_in', 'completed', 'cancelled', 'deleted', 'arriving', 'departing'] })]
    },

    source_type: {
      descriptionKey: "sourceType",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('inclusion', { in: ['pickup', 'dropoff'] })]
    },

    expected_date: {
      descriptionKey: computed('model.is_pickup', function () {
        if (this.get('model.is_pickup')) {
          return "pickupDateLabel";
        }
        return "dropoffDateLabel";
      }),

      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true),

      // PICKUP RELATED VALIDATIONS
      (0, _emberCpValidations.validator)('date', {
        onOrBefore: computed.alias('model.load.last_dropoff.actual_date'),
        precision: 'day',
        disabled: computed('model.is_dropoff', 'model.load.last_dropoff.actual_date', function () {
          if (this.get('model.is_dropoff')) {
            return true;
          }
          return Ember.isBlank(this.get('model.load.last_dropoff.actual_date'));
        }),
        message: function message(type, options, value) {
          return this.get('model.intl').t("errors.lastDropOffBeforePickup", value);
        }
      }),

      // PICKUP VALIDATIONS BASED ON DROPOFFS
      (0, _emberCpValidations.validator)('date', {
        onOrBefore: computed.alias('model.pickup_first_related_dropoff.actual_date'),
        precision: 'day',
        disabled: computed('model.is_dropoff', 'model.pickup_first_related_dropoff.actual_date', function () {
          if (this.get('model.is_dropoff')) {
            return true;
          }
          return Ember.isBlank(this.get('model.pickup_first_related_dropoff.actual_date'));
        }),
        message: function message(type, options, value) {
          return this.get('model.intl').t("errors.pickupFirstRelatedDropOff", value);
        }
      }),

      // DROPOFF RELATED VALIDATIONS
      (0, _emberCpValidations.validator)('date', {
        onOrAfter: computed.alias('model.load.first_pickup.actual_date'),
        precision: 'day',
        disabled: computed('model.is_dropoff', 'model.pickup_first_related_dropoff.actual_date', function () {
          if (this.get('model.is_pickup')) {
            return true;
          }
          return Ember.isBlank(this.get('model.load.first_pickup.actual_date'));
        }),
        message: function message(type, options, value) {
          return this.get('model.intl').t("errors.firstPickupActualDate", value);
        }
      }),

      // DROPOFF VALIDATIONS BASED ON PICKUPS
      (0, _emberCpValidations.validator)('date', {
        onOrAfter: computed.alias('model.dropoff_last_related_pickup.actual_date'),
        precision: 'day',
        disabled: computed('model.is_pickup', 'model.dropoff_last_related_pickup.actual_date', function () {
          if (this.get('model.is_pickup')) {
            return true;
          }
          return Ember.isBlank(this.get('model.dropoff_last_related_pickup.actual_date'));
        }),
        message: function message(type, options, value) {
          return this.get('model.intl').t("errors.dropOffLastRelatedPickup", value);
        }
      })]
    },

    completed_date: {
      descriptionKey: "completedDate",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed.not('model.is_completed')
      }),
      // PICKUP RELATED VALIDATIONS
      (0, _emberCpValidations.validator)('date', {
        onOrBefore: computed.alias('model.load.last_dropoff.actual_date'),
        precision: 'day',
        disabled: computed('model.is_dropoff', 'model.load.last_dropoff.actual_date', function () {
          if (this.get('model.is_dropoff')) {
            return true;
          } else if (!this.get('model.is_completed')) {
            return true;
          }
          return Ember.isBlank(this.get('model.load.last_dropoff.actual_date'));
        }),
        message: function message(type, options, value) {
          return this.get('model.intl').t("errors.lastDropOffBeforePickup", value);
        }
      }),

      // PICKUP VALIDATIONS BASED ON DROPOFFS
      (0, _emberCpValidations.validator)('date', {
        onOrBefore: computed.alias('model.pickup_first_related_dropoff.actual_date'),
        precision: 'day',
        disabled: computed('model.is_dropoff', 'model.pickup_first_related_dropoff.actual_date', function () {
          if (this.get('model.is_dropoff')) {
            return true;
          } else if (!this.get('model.is_completed')) {
            return true;
          }
          return Ember.isBlank(this.get('model.pickup_first_related_dropoff.actual_date'));
        }),
        message: function message(type, options, value) {
          return this.get('model.intl').t("errors.pickupFirstRelatedDropOff", value);
        }
      }),

      // DROPOFF RELATED VALIDATIONS
      (0, _emberCpValidations.validator)('date', {
        onOrAfter: computed.alias('model.load.first_pickup.actual_date'),
        precision: 'day',
        disabled: computed('model.is_dropoff', 'model.pickup_first_related_dropoff.actual_date', function () {
          if (this.get('model.is_pickup')) {
            return true;
          } else if (!this.get('model.is_completed')) {
            return true;
          }
          return Ember.isBlank(this.get('model.load.first_pickup.actual_date'));
        }),
        message: function message(type, options, value) {
          return this.get('model.intl').t("errors.firstPickupActualDate", value);
        }
      }),

      // DROPOFF VALIDATIONS BASED ON PICKUPS
      (0, _emberCpValidations.validator)('date', {
        onOrAfter: computed.alias('model.dropoff_last_related_pickup.actual_date'),
        precision: 'day',
        disabled: computed('model.is_pickup', 'model.dropoff_last_related_pickup.actual_date', function () {
          if (this.get('model.is_pickup')) {
            return true;
          } else if (!this.get('model.is_completed')) {
            return true;
          }
          return Ember.isBlank(this.get('model.dropoff_last_related_pickup.actual_date'));
        }),
        message: function message(type, options, value) {
          return this.get('model.intl').t("errors.dropOffLastRelatedPickup", value);
        }
      })]
    },

    appointment_date: {
      descriptionKey: "appointmentTime",
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed.not('model.location.appointment_required')
      })]
    },

    // relationships

    load: {
      descriptionKey: "load",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },

    location: {
      descriptionKey: "location",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },

    dropoff_cargo_destinations: (0, _emberCpValidations.validator)('dropoff-quantities', {
      disabled: computed.or('model.no_dropoff_cargo_destinations', 'model.shouldNotValidateCargo'),
      dependentKeys: ['model.dropoff_cargo_destinations.@each.total_value']
    })

  });

  exports.default = _model.default.extend(Validations, _statuses.default, {

    expected_date: (0, _attr.default)('datetime'),
    completed_date: (0, _attr.default)('datetime'),
    reference_number: (0, _attr.default)(),
    purchase_order_number: (0, _attr.default)(),
    status: (0, _attr.default)({ defaultValue: 'setup' }),
    source_type: (0, _attr.default)(),
    created_at: (0, _attr.default)('datetime_utc'),
    updated_at: (0, _attr.default)('datetime_utc'),
    status_updated_at: (0, _attr.default)('datetime_utc'),

    // TODO really needed?
    address_1: (0, _attr.default)(),
    address_2: (0, _attr.default)(),
    address_city: (0, _attr.default)(),
    address_state: (0, _attr.default)(),
    address_zip: (0, _attr.default)(),

    latitude: (0, _attr.default)('number'),
    longitude: (0, _attr.default)('number'),
    appointment_date: (0, _attr.default)('datetime'),

    load: (0, _relationships.belongsTo)({ async: false }),
    location: (0, _relationships.belongsTo)({ async: false }),

    cargos: (0, _relationships.hasMany)('load-cargo', { async: false }),

    // cargo destinations for a specific dropoff
    dropoff_cargo_destinations: (0, _relationships.hasMany)('load-cargo-destination', { async: false, inverse: 'dropoff_operation' }),

    audits: (0, _relationships.hasMany)('load-operation-audit'),
    images: (0, _relationships.hasMany)('load-image'),

    // computed properties
    shouldValidateCargo: computed(function () {
      return true;
    }),
    shouldNotValidateCargo: computed.equal('shouldValidateCargo', false),

    no_dropoff_cargo_destinations: computed.equal('dropoff_cargo_destinations.length', 0),

    is_pickup: computed('source_type', function () {
      return this.get('source_type') === 'pickup' || this.get('source_type') === 'both';
    }),

    is_dropoff: computed('source_type', function () {
      return this.get('source_type') === 'dropoff' || this.get('source_type') === 'both';
    }),

    actual_date: computed('completed_date', 'expected_date', function () {
      return this.get('completed_date') || this.get('expected_date');
    }),

    total_units: computed('cargos.@each.total_units', function () {
      return this.get('cargos').reduce(function (prev, item) {
        return prev + item.get('total_units');
      }, 0);
    }),

    total_weight: computed('cargos.@each.total_weight', function () {
      return this.get('cargos').reduce(function (prev, item) {
        return prev + item.get('total_weight');
      }, 0);
    }),

    // Ember hack since they apparently don't allow multiple sub properties under and each now
    entrance_fee: computed.alias('location.entrance_fee'),
    unloading_fee: computed.alias('location.unloading_fee'),
    misc_fee: computed.alias('location.misc_fee'),
    late_fee: computed.alias('location.late_fee'),

    // If Pickup - Related dropoffs
    pickup_cargo_destinations: computed('cargos.@each.destinations', function () {
      return this.get('cargos').reduce(function (destinations, cargo) {
        destinations.pushObjects(cargo.get('destinations'));
        return destinations;
      }, A());
    }),

    // BELOW - Used for validators, shouldn't really be used for templates

    pickup_related_dropoffs_grouped: computed.mapBy('cargos', 'dropoffs_with_values'),

    pickup_related_dropoffs: computed('pickup_related_dropoffs_grouped.[]', function () {
      var dropoffs = [];

      (this.get('pickup_related_dropoffs_grouped') || []).forEach(function (dropoff_array) {
        dropoffs = dropoffs.concat(dropoff_array);
      });
      return dropoffs;
    }),

    pickup_first_related_dropoff: computed('pickup_related_dropoffs.@each.actual_date', function () {
      var dropoffs = this.get('pickup_related_dropoffs').filterBy('actual_date');
      if (dropoffs) {
        dropoffs = dropoffs.sort(function (a, b) {
          return a.get('actual_date').getTime() - b.get('actual_date').getTime();
        });
      }
      return dropoffs.objectAt(0);
    }),

    dropoff_related_pickups: computed('dropoff_cargo_destinations.@each.{pickup,no_total_value}', function () {
      var destinations = this.get('dropoff_cargo_destinations').filterBy('no_total_value', false);
      var pickups = destinations.mapBy('pickup');
      return pickups;
    }),

    dropoff_last_related_pickup: computed('dropoff_related_pickups.@each.actual_date', function () {
      var pickups = this.get('dropoff_related_pickups').filterBy('actual_date');
      if (pickups) {
        pickups = pickups.sort(function (a, b) {
          return b.get('actual_date').getTime() - a.get('actual_date').getTime();
        });
      }
      return pickups.objectAt(0);
    }),

    dropoff_related_pickups_done: computed('dropoff_related_pickups.@each.isCompletedOrCancelled', function () {
      return this.get('dropoff_related_pickups').isEvery('isCompletedOrCancelled', true);
    }),

    // This needs to be done because you can select time before the expected_date and
    // this doesn't set any value to the appointment_date (SHIP-272) which is a required field.
    // By recomputing the appointment_time once you select an expected_date, it can check
    // if the time is present and construct a valid DateTime value.
    expected_date_changed: Ember.observer('expected_date', function () {
      if (this.get('location.appointment_required') && this.get('expected_date')) {
        this.set('appointment_time', this.get('appointment_time'));
      }
    }),

    any_drivers: computed.notEmpty('load.haul.driver_assignments'),

    appointment_time: computed('appointment_date', {
      get: function get() {
        var date = this.get('appointment_date');
        return date ? moment(date).format('h:mm A') : null;
      },
      set: function set(property, time) {
        if (this.get('location.appointment_required')) {
          var date = this.get('expected_date');
          if (date && time) {
            var t = moment(time, 'h:mm A');
            var newDateTime = moment(date).set({ hour: t.hour(), minute: t.minute() }).toDate();
            this.set('appointment_date', newDateTime);
          }
          return time;
        } else {
          return null;
        }
      }
    }),

    // functions

    copyLocation: function copyLocation() {
      this.set('address_1', this.get('location.address.address_1'));
      this.set('address_2', this.get('location.address.address_2'));
      this.set('address_city', this.get('location.address.city'));
      this.set('address_state', this.get('location.address.state'));
      this.set('address_zip', this.get('location.address.zip'));
      this.set('latitude', this.get('location.address.latitude'));
      this.set('longitude', this.get('location.address.longitude'));
    }
  });
});