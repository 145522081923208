define('webapp/components/unverified-user/component', ['exports', 'semantic-ui-ember/components/ui-modal'], function (exports, _uiModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiModal.default.extend({

    classNames: ['unverified-user-modal'],

    actions: {
      cancel: function cancel() {
        this.execute('hide');
      }
    }

  });
});