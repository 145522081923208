define('webapp/pods/organization/locations/edit/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      cancel: function cancel() {
        this.transitionToRoute('organization.locations');
      },
      save: function save() {
        this.transitionToRoute('organization.locations');
      }
    }
  });
});