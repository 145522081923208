define('webapp/pods/organization/loads/components/load-pickup/component', ['exports', 'webapp/models/load-cargo', 'webapp/mixins/times'], function (exports, _loadCargo, _times) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend(_times.default, {

    // params

    queryParams: ["pickup"],
    pickup: null,

    submitted: false,
    activePickup: null,
    activeCargo: null,

    didReceiveAttrs: function didReceiveAttrs() {
      // set the right pickup & cargo active
      var pickup = this.get('pickups.firstObject');
      this.set('activePickup', pickup);
      this.set('activeCargo', pickup.get('cargos.firstObject'));
    },


    activePickupChanged: Ember.observer('activePickup', function () {
      this.set('activeCargo', this.get('activePickup').get('cargos.lastObject'));
    }),

    // computed properties

    pickups: computed('model.pickups.[]', function () {
      return this.get('model.pickups').rejectBy('isDeleted', true);
    }),

    unitTypes: computed(function () {
      return _loadCargo.default.unitTypes;
    }),

    actions: {
      updateStatus: function updateStatus(pickup, status) {
        pickup.set('status', status);

        // If status was downgraded from completed, remove the completed date
        if (status !== 'is_completed') {
          pickup.set('completed_date', null);
        }

        // If a pickup is in setup and the load is not, change pickup to at least pending
        if (status == 'in_setup' && !this.currentModel.get('in_setup')) {
          pickup.set('status', 'pending');
        }
      },
      updateCargoTotals: function updateCargoTotals(cargo, type) {

        cargo.set('unit_type', type);

        if (cargo.get('needs_unit_count')) {
          // Clear the weight if its set to unit count
          cargo.set('total_weight', 0);
          // Clear the pallets if its not set
          if (!cargo.get('is_palletized')) {
            cargo.set('total_pallets', 0);
          }
        } else if (cargo.get('needs_weight')) {
          cargo.setProperties({
            total_units: 0,
            is_palletized: false,
            total_pallets: 0
          });
        }
      },
      updatePickupLocation: function updatePickupLocation(pickup, location) {
        pickup.set('location', location);
        // TODO why location has to be stored in the load operation??
        pickup.copyLocation();
      },
      deletePickup: function deletePickup(pickup) {
        this.get('deletePickup')(pickup);

        // restore order
        var pickups = this.get('pickups');
        this.set('activePickup', pickups.get('firstObject'));
        this.set('activeCargo', this.get('activePickup.cargos.firstObject'));
      }
    }

  });
});