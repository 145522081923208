define("webapp/pods/organization/offers/components/offer-details/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    classNames: ["ui", "grid"],

    isFixedPrice: computed.equal('listing.listing_type', 'fixed_price'),
    distance: computed.alias('listing.load.distance'),

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('isFixedPrice')) {
        this.send('updateAmount', this.get('listing.fixed_price'));
      } else if (this.get('offer.amount')) {
        this.send('updateAmount', this.get('offer.amount'));
      }
    },


    actions: {
      updateAmount: function updateAmount(value) {
        this.set('offer.amount', Math.round(value));
        if (this.get('distance')) {
          this.set('dollarsPerMile', (value / this.get('distance')).toFixed(2));
        }
      },
      updateDollarsPerMile: function updateDollarsPerMile(value) {
        if (this.get('distance')) {
          this.set('dollarsPerMile', value);
          this.set('offer.amount', Math.round(Number(value * this.get('distance'))));
        }
      }
    }

  });
});