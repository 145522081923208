define('webapp/services/equipment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var A = Ember.A,
      computed = Ember.computed;
  exports.default = Ember.Service.extend({
    getType: function getType(name) {
      return this.types[name] || this.types['dryVan'];
    },


    typeNames: computed(function () {
      return Object.keys(this.types);
    }),

    types: {
      dryVan: {
        image: "dry_van.png",
        temperature: false
      },
      refrigeratedVan: {
        image: "refrigerated_trailer.png",
        temperature: true
      },
      ventilatedVan: {
        image: "dry_van.png",
        temperature: false
      },
      airRideVan: {
        image: "dry_van.png",
        temperature: false
      },
      vanwithCurtains: {
        image: "dry_van.png",
        temperature: false
      },
      flatbed: {
        image: "flatbed.png"
      },
      flatbedwithSides: {
        image: "flatbed.png"
      },
      flatbedwithTarps: {
        image: "flatbed.png"
      },
      flatbedStretch: {
        image: "flatbed.png"
      },
      flatbedSideKit: {
        image: "flatbed.png"
      },
      autoCarrier: {
        image: "auto_carrier.png"
      },
      beverageTrailer: {
        image: "conestoga_trailer.png"
      },
      intermodal20Container: {
        image: "container.png"
      },
      intermodal40Container: {
        image: "container.png"
      },
      livestockTrailerCattle: {
        image: "livestock_truck.png"
      },
      livestockTrailerHogs: {
        image: "livestock_truck.png"
      },
      livestockTrailerChickens: {
        image: "livestock_truck.png"
      },
      loggerTrailer: {
        image: "logger_trailer.png"
      },
      boatTrailerSingle: {
        image: "flatbed.png"
      },
      boatTrailerDouble: {
        image: "flatbed.png"
      },
      removableGooseneckRGN: {
        image: "single_drop_trailer.png"
      },
      conestogaTrailer: {
        image: "conestoga_trailer.png"
      },
      doubleDrop: {
        image: "double_drop.png"
      },
      doubleDropStretch: {
        image: "double_drop.png"
      },
      doubleDropExtendable: {
        image: "double_drop.png"
      },
      doubleDropSideKit: {
        image: "double_drop.png"
      },
      bTrainCombo: {
        image: "b_train_combo.png"
      },
      singleDrop: {
        image: "single_drop_trailer.png"
      },
      singleDropStretch: {
        image: "single_drop_trailer.png"
      },
      singleDropExtendable: {
        image: "single_drop_trailer.png"
      },
      singleDropSideKit: {
        image: "single_drop_trailer.png"
      },
      dumpTrailer: {
        image: "dump_trailer.png"
      },
      endDumpTrailer: {
        image: "dump_trailer.png"
      },
      halfRoundEndDumpTrailer: {
        image: "half_round_end_dump_trailer.png"
      },
      bottomDumpTrailer: {
        image: "bottom_dump_trailer.png"
      },
      fuelTankSingle: {
        image: "tanker.png"
      },
      fuelTankDouble: {
        image: "fuel_tank_double.png"
      },
      'hopperGrain,Single': {
        image: "single_hopper.png"
      },
      'hopperGrain,Double': {
        image: "single_hopper.png"
      },
      hopperSingle: {
        image: "single_hopper.png"
      },
      hopperDouble: {
        image: "single_hopper.png"
      },
      liveFloor: {
        image: "dry_van.png",
        temperature: false
      },
      saddlemount: {
        image: "saddlemount.png"
      },
      pneumatic: {
        image: "pneumatic_trailer.png"
      },
      lowboy: {
        image: "double_drop.png"
      },
      maxiCube: {
        image: "dry_van.png",
        temperature: false
      },
      powerUnitOnly: {
        image: "power_unit.png"
      },
      fracTankSquare: {
        image: "frac_tank.png"
      },
      fracTankRoundBottom: {
        image: "frac_tank.png"
      },
      tankerFoodGrade: {
        image: "tanker.png",
        temperature: true
      },
      tankerWater: {
        image: "tanker.png"
      },
      tankerVacuum: {
        image: "tanker.png"
      },
      tankerPetroleum: {
        image: "tanker.png"
      },
      tankerChemical: {
        image: "tanker.png",
        temperature: true
      },
      specializedTrailer: {
        image: "dry_van.png",
        temperature: false
      }
    },

    trailerLengths: A([57, 53, 48, 45, 43, 42, 40, 36, 32, 28, 24, 20])

  });
});