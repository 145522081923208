define('webapp/pods/organization/locations/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['page', 'page_size', { filter_type: 'type' }, 'search'],
    page: 1,
    page_size: 10,
    filter_type: 'all',
    search: null,

    is_all: Ember.computed.equal('filter_type', 'all'),
    is_dropoff: Ember.computed.equal('filter_type', 'dropoff'),
    is_pickup: Ember.computed.equal('filter_type', 'pickup'),

    actions: {
      filter: function filter(filter_type) {
        this.set('filter_type', filter_type);
        this.set('page', 1);
      }
    }
  });
});