define('webapp/pods/organization/loads/edit/driver/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ['page', 'page_size', 'search', { driver_sort: 'sort' }, 'status'],

    page: 1,
    page_size: 10,
    search: null,
    driver_sort: "last_name,first_name",
    state: "all",
    status: "available"

  });
});