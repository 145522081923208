define('webapp/serializers/payment-method', ['exports', 'webapp/pods/application/serializer'], function (exports, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend({
    /*
     * Results for `payment-methods` should spot the `payment-method` type
     * instead of the more specific one. So we override `normalize` to set
     * it appropriately. Let's keep the original type in a temp attribute
     * called `kind`.
     */
    normalize: function normalize(modelClass, resourceHash) {
      resourceHash.attributes.kind = resourceHash.type.split('-')[0];
      // resourceHash.type = 'payment-methods';
      return this._super(modelClass, resourceHash);
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);
      delete json.data.attributes.kind;
      return json;
    }
  });
});