define('webapp/pods/organization/edit/controller', ['exports', 'webapp/utils/catch-real', 'common/mixins/edit-organization-controller'], function (exports, _catchReal, _editOrganizationController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_editOrganizationController.default, {
    uploadError: null,
    actions: {
      update: function update() {
        var _this = this;

        if (this.get('saveTask.isRunning')) {
          return;
        }

        this.set('model.validate_dot_number', false);
        this.set('submitted', true);

        return this.get('saveTask').perform().then(function () {
          return _this.transitionToRoute('organization.view');
        }).catch((0, _catchReal.default)(function (error) {
          alert(_this.get('intl').t('unknownErrorSaving'));
        }));
      }
    }
  });
});