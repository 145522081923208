define('webapp/models/load-operation-offer-detail', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'ember-data-copyable'], function (exports, _model, _attr, _relationships, _emberCpValidations, _emberDataCopyable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _isEqual = Ember.isEqual;


  var Validations = (0, _emberCpValidations.buildValidations)({
    offer: {
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    load_operation: {
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    expected_date: {
      descriptionKey: 'expected_date',
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    }

  });

  exports.default = _model.default.extend(Validations, _emberDataCopyable.default, {

    late_fee: (0, _attr.default)('number'),
    entrance_fee: (0, _attr.default)('number'),
    pallet_exchange_fee: (0, _attr.default)('number'),
    misc_fee: (0, _attr.default)('number'),
    unloading_fee: (0, _attr.default)('number'),
    expected_date: (0, _attr.default)('date'),
    late_fee_countered: (0, _attr.default)('boolean'),
    entrance_fee_countered: (0, _attr.default)('boolean'),
    pallet_exchange_fee_countered: (0, _attr.default)('boolean'),
    misc_fee_countered: (0, _attr.default)('boolean'),
    unloading_fee_countered: (0, _attr.default)('boolean'),
    expected_date_countered: (0, _attr.default)('boolean'),
    created_at: (0, _attr.default)('datetime_utc'),
    updated_at: (0, _attr.default)('datetime_utc'),

    offer: (0, _relationships.belongsTo)(),
    load_operation: (0, _relationships.belongsTo)(),

    isEqual: function isEqual(lood) {
      var _this = this;

      return ['late_fee', 'entrance_fee', 'pallet_exchange_fee', 'misc_fee', 'unloading_fee', 'expected_date'].reduce(function (result, prop) {
        return result && _isEqual(lood.get(prop), _this.get(prop));
      }, true);
    }
  });
});