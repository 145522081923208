define('webapp/pods/organization/offers/new/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['listing_id', 'bid_id'],
    listing_id: null,
    bid_id: null,

    organization: Ember.computed.readOnly('session.currentOrganization'),

    actions: {
      saved: function saved(listing_id) {
        this.send('transitionToPage', listing_id);
      },
      transitionToPage: function transitionToPage(listing_id) {
        if (this.get('session.currentOrganization.org_type') === 'carrier') {
          this.transitionToRoute('carrier.listings', {
            queryParams: { carrier_status: 'in_process' }
          });
        } else {
          this.transitionToRoute('shipper.listings.bids', listing_id);
        }
      },
      cancelled: function cancelled(listing_id) {
        var offer = this.get('offer');
        if (offer.get('isNew')) {
          offer.unloadRecord();
        }
        this.send('transitionToPage', listing_id);
      }
    }
  });
});