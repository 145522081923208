define('webapp/models/user', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'webapp/mixins/model-request', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _modelRequest, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    organization: {
      descriptionKey: "organizationMenuLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'),
      // Don't include belongs-to for validation
      (0, _emberCpValidations.validator)('presence', true)]
    },

    first_name: {
      descriptionKey: "firstNameLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 100 })]
    },

    last_name: {
      descriptionKey: "lastNameLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 100 })]
    },

    email: {
      descriptionKey: "emailLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/i
      })]
    },

    status: {
      descriptionKey: "status",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['active', 'inactive', 'pending']
      })]
    },

    role: {
      descriptionKey: "role",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['owner', 'admin']
      })]
    },

    password: {
      descriptionKey: "passwordLabel",
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed.not('model.isNew')
      }), (0, _emberCpValidations.validator)('length', {
        min: 8,
        disabled: computed.not('model.isNew')
      }), (0, _emberCpValidations.validator)('ds-error')]
    },

    confirmation_password: {
      descriptionKey: "passwordConfirmationLabel",
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed.not('model.isNew')
      }), (0, _emberCpValidations.validator)('confirmation', {
        on: 'password',
        message: function message() {
          return this.get('model.intl').t('passwordMustMatchError');
        },

        disabled: computed.not('model.isNew')
      })]
    }

  });

  exports.default = _model.default.extend(Validations, _modelRequest.default, {

    intl: inject.service(),

    first_name: (0, _attr.default)('string'),
    last_name: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    status: (0, _attr.default)('string', { defaultValue: 'active' }),
    avatar: (0, _attr.default)('string'),
    role: (0, _attr.default)('string', { defaultValue: 'owner' }),
    password: (0, _attr.default)('string'),
    unlock_token: (0, _attr.default)('string'),
    locked: (0, _attr.default)('boolean'),
    language: (0, _attr.default)('string'),
    created_at: (0, _attr.default)('datetime_utc'),
    updated_at: (0, _attr.default)('datetime_utc'),
    confirmed_at: (0, _attr.default)('datetime_utc'),
    first_sign_in: (0, _attr.default)('datetime'),
    sign_in_count: (0, _attr.default)('number'),

    organization: (0, _relationships.belongsTo)({ async: false }),
    notifications: (0, _relationships.hasMany)('user_notifications'),
    commissions: (0, _relationships.hasMany)(),
    sales_organizations: (0, _relationships.hasMany)('organizations', { inverse: 'sales_person', async: false }),

    full_name: computed('first_name', 'last_name', function () {
      return this.get('first_name') + ' ' + this.get('last_name');
    }),

    send_feedback: function send_feedback(subject, body) {
      return this.do_post('feedback', { subject: subject, body: body });
    },
    clear_avatar: function clear_avatar() {
      return this.do_delete('avatar');
    },


    is_superadmin: computed.equal('role', 'superadmin'),
    admin_role: computed.equal('role', 'admin'),
    superadmin_role: computed.equal('role', 'superadmin'),
    is_admin: computed.or('admin_role', 'superadmin_role'),
    is_sales: computed.equal('role', 'sales'),

    sales_carrier_orgs: computed.filterBy('sales_organizations', 'org_type', 'carrier'),
    sales_shipper_orgs: computed.filterBy('sales_organizations', 'org_type', 'shipper'),

    impersonate: function impersonate() {
      return this.do_post('impersonate');
    },
    stop_impersonation: function stop_impersonation() {
      return this.do_delete('impersonate');
    },
    unlock: function unlock() {
      return this.do_post('unlock', { unlock_token: this.get('unlock_token') });
    },


    // comissions cps

    amounts: computed.mapBy('commissions', 'amount'),
    total_commissions: computed.sum('amounts'),

    paid_commissions_list: computed.filterBy('commissions', 'status', 'paid'),
    paid_amounts: computed.mapBy('paid_commissions_list', 'amount'),
    paid_commissions: computed.sum('paid_amounts'),

    pending_commissions: computed.filterBy('commissions', 'status', 'pending'),
    pending_amounts: computed.mapBy('pending_commissions', 'amount'),
    outstanding_commission: computed.sum('pending_amounts')
  });
});