define('webapp/pods/user/components/cancel-subscription-modal/component', ['exports', 'semantic-ui-ember/components/ui-modal'], function (exports, _uiModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiModal.default.extend({

    closable: false,
    observeChanges: true,
    classNames: ['cancel-subscription-modal'],

    onDeny: function onDeny() {
      return this.get('cancelSubscriptionTask').perform();
    },


    actions: {
      cancel: function cancel() {
        this.execute('hide');
      }
    }

  });
});