define('webapp/pods/application/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({

    serializeAttribute: function serializeAttribute(record, json, key, attribute) {
      if (attribute.options.transient) {
        return;
      }
      return this._super(record, json, key, attribute);
    }

  });
});