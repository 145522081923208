define('webapp/transforms/datetime', ['exports', 'moment', 'ember-data'], function (exports, _moment, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(value) {
      return value && (0, _moment.default)(value).toDate();
    },
    serialize: function serialize(value) {
      return value && (0, _moment.default)(value).format();
    }
  });
});