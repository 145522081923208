define('webapp/mixins/route-css', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    on_activate: Ember.on('activate', function () {
      var cssClass, root;
      cssClass = this.toCssClass();
      root = this.root();
      if (cssClass !== 'application') {
        root.addClass(cssClass);
      }
      if (this.get('routeClasses')) {
        root.addClass(this.get('routeClasses'));
      }
      root.removeClass('loading-full');
      if (this.removeRouteClasses) {
        root.removeClass(this.removeRouteClasses);
      }
    }),
    on_deactivate: Ember.on('deactivate', function () {
      var root;
      root = this.root();
      root.removeClass(this.toCssClass());
      if (this.get('routeClasses')) {
        return root.removeClass(this.get('routeClasses'));
      }
    }),
    root: function root() {
      var element;
      element = window.$('html');
      if (!element.hasClass('root')) {
        element.addClass('root');
      }
      return element;
    },
    toCssClass: function toCssClass() {
      return this.routeName.split('.').slice(-1)[0].dasherize().replace(/\-/g, ' ').replace('/', ' ');
    },

    actions: {
      loading: function loading() {
        var root = this.root();
        root.addClass('loading-full');
        return true;
      },
      didTransition: function didTransition() {
        var root = this.root();
        root.removeClass('loading-full');
        return true;
      },
      error: function error() {
        var root = this.root();
        root.removeClass('loading-full');
        return true;
      }
    },

    afterModel: function afterModel() /* model */{
      this._super.apply(this, arguments);
      var root = this.root();
      root.removeClass('loading-full');
    }
  });
});