define('webapp/pods/organization/notifications/view/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    notification_area: Ember.inject.service(),

    model: function model(params) {
      return this.store.findRecord('user-notification', params.notification_id);
    },
    afterModel: function afterModel(model) {
      if (model.get('unread')) {

        model.set('unread', false);

        // decrease unread count in notification.
        var total_notification_number = this.get('notification_area').get('total');
        this.get('notification_area').set('total', total_notification_number - 1);

        return model.save();
      }
    },
    setupController: function setupController(controller, context) {
      this._super(controller, null);

      return context.links().then(function (res) {
        context.set('next_id', res.next_id);
        context.set('prev_id', res.prev_id);

        controller.set('notification', context);
      }).catch(function (result) {
        // error handling might be necessary
        console.log(result);
      });
    }
  });
});