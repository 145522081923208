define('webapp/initializers/model', ['exports', 'ember-data/model', 'ember-data-storefront/mixins/loadable-model'], function (exports, _model, _loadableModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {

    _model.default.reopen(_loadableModel.default);

    _model.default.reopen({
      get: function get(prop, id) {
        if (!id) {
          return this._super.apply(this, arguments);
        }

        var first = this.get(prop) && this.get(prop).objectAt(0);
        if (!first) {
          return null;
        }

        var type = first.constructor.modelName;
        return this.store.peekRecord(type, id);
      },
      load: function load(rel) {
        return this._super.apply(this, arguments).then(function (model) {
          return model.get(rel);
        });
      },
      createOne: function createOne(prop) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

        var rel = this.hasMany(prop).hasManyRelationship;
        if (rel && rel.inverseKey) {
          // set the inverse automatically if not provided
          options[rel.inverseKey] = options[rel.inverseKey] || this;
          return this.store.createRecord(rel.belongsToType, options);
        } else {
          throw "Unsupported createOne: Please use store.createRecord";
        }
      }
    });
  }

  exports.default = {
    name: 'model',
    initialize: initialize
  };
});