define('webapp/pods/users/choose-org-type/controller', ['exports', 'uncharted-ajax', 'ember-concurrency', 'webapp/utils/catch-real'], function (exports, _unchartedAjax, _emberConcurrency, _catchReal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),

    org_type: null,
    errors: [],

    actions: {
      next: function next() {
        var org_type = this.get('org_type');
        if (org_type) {
          return this.transitionToRoute('users.signup', { queryParams: { org_type: org_type } });
        } else {
          this.set('errors', [this.get('intl').t('mustSelectOrganizationType')]);
        }
      }
    }
  });
});