define('webapp/components/draggable-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ui-draggable'],
    classNameBindings: ['dragClass'],
    attributeBindings: ['draggable'],
    draggable: true,
    dragging: false,
    dragClass: Ember.computed('draggable', 'dragging', function () {
      if (this.get('dragging')) {
        return "dragging";
      }
      if (this.get('draggable')) {
        return "draggable";
      }
      return null;
    }),

    dragStart: function dragStart(event) {
      event.dataTransfer.dropEffect = "move";
      event.stopPropagation();
      this.set('dragging', true);
      this.set_data();
    },
    dragEnd: function dragEnd(event) {
      this.set('dragging', false);
    },
    set_data: function set_data() {}
  });
});