define('webapp/pods/itm-tracking/route', ['exports', 'common/mixins/live-sync'], function (exports, _liveSync) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    routeClasses: 'external',

    queryParams: {
      time_frame: {
        refreshModel: true
      },
      start_date: {
        refreshModel: true
      },
      end_date: {
        refreshModel: true
      }
    },

    model: function model(params) {
      this.set('hardware_unit_id', params.itm_hardware_device_id);

      var filter = {
        itm_hardware_device_id: params.itm_hardware_device_id
      };

      if (params.time_frame === 'custom') {
        // no time frame filter
      } else if (params.time_frame) {
        filter['time_frame'] = params.time_frame;
      } else {
        filter['time_frame'] = 24;
      }

      if (params.start_date !== 'null' && params.end_date !== 'null') {
        filter['date'] = {};
        filter['date']['start_date'] = params.start_date;
        filter['date']['end_date'] = params.end_date;
      }

      return Ember.RSVP.hash({
        // This is a hack so the adapter can know to load it differently
        itmDevice: this.store.find('itm-device', 'E1A' + '!~!' + params.itm_hardware_device_id),
        itmLines: this.store.query('itm-line', {
          filter: filter
        }),
        itmDelays: this.store.query('itm-delay', {
          filter: filter
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('itmDevice', model.itmDevice);
      controller.set('itmLines', model.itmLines);
      controller.set('itmDelays', model.itmDelays);
      controller.set('hardware_unit_id', this.get('hardware_unit_id'));
    },


    actions: {
      loading: function loading(transition) {
        var controller = this.controllerFor(this.routeName);
        controller.set('isLoading', true);
        transition.promise.finally(function () {
          return controller.set('isLoading', false);
        });
      }
    }

  });
});