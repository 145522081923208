define('webapp/pods/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'semantic-ui-ember-modal/mixins/modal-actions'], function (exports, _applicationRouteMixin, _modalActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      getWithDefault = Ember.getWithDefault;
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _modalActions.default, {

    title: "National Carrier Exchange",

    language: inject.service(),

    model: function model() {
      var _this = this;

      // if user is available, set their language
      return this.get('session.loadCurrentUser').perform().then(function (user) {
        var lang = getWithDefault(_this, 'user.language', navigator.language.split('-')[0]);
        return _this.get('language').changeLanguage(lang);
      });
    }
  });
});