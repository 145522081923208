define('webapp/models/organization', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    address: {
      descriptionKey: "addressLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', true)]
    },

    name: {
      descriptionKey: "nameLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 200 })]
    },
    mc_number: {
      descriptionKey: "mcNumberLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', {
        max: 200,
        allowBlank: true
      })]
    },

    // dot_number: {
    //   descriptionKey: "dotNumberLabel",
    //   validators: [
    //     validator('ds-error'),
    //     validator('dot-number-valid', {
    //       dependentKeys: ['validate_dot_number'],
    //       disabled: computed.not('model.validate_dot_number'),
    //     }),
    //   ]
    // },

    verified: {
      descriptionKey: "verified",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', { in: [true, false] })]
    },

    phone_number: {
      descriptionKey: "phoneNumberLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },

    phone_extension: {
      descriptionKey: "extension",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('length', { max: 10 })]
    }

    // insurance_company_name: {
    //   descriptionKey: 'insuranceCompanyNameLabel',
    //   validators: [
    //     validator('ds-error'),
    //     validator('presence', true),
    //     validator('length', { max: 200 }),
    //   ],
    // },

    // insurance_policy_number: {
    //   descriptionKey: 'insurancePolicyNumberLabel',
    //   validators: [
    //     validator('ds-error'),
    //     validator('presence', true),
    //   ],
    // },

    // insurance_amount_of_coverage: {
    //   descriptionKey: 'insuranceAmountOfCoverageLabel',
    //   validators: [
    //     validator('ds-error'),
    //     validator('presence', true),
    //   ],
    // },

    // insurance_expires_on: {
    //   descriptionKey: 'insuranceExpirationDateLabel',
    //   validators: [
    //     validator('ds-error'),
    //     validator('presence', true),
    //     validator('date', {
    //       after: 'now',
    //       precision: 'day',
    //     }),
    //   ],
    // },
  });

  exports.default = _model.default.extend(Validations, {

    eid: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    mc_number: (0, _attr.default)('string'),
    dot_number: (0, _attr.default)('string'),
    verified: (0, _attr.default)('boolean'),
    phone_number: (0, _attr.default)('string'),
    phone_extension: (0, _attr.default)('string'),
    fax_number: (0, _attr.default)('string'),
    delivers_in_usa: (0, _attr.default)('boolean'),
    org_type: (0, _attr.default)('string'),
    created_at: (0, _attr.default)('datetime_utc'),
    updated_at: (0, _attr.default)('datetime_utc'),
    insurance_company_name: (0, _attr.default)('string'),
    insurance_policy_number: (0, _attr.default)('string'),
    insurance_amount_of_coverage: (0, _attr.default)('number'),
    insurance_expires_on: (0, _attr.default)('datetime'),
    proof_of_insurance: (0, _attr.default)('string'),
    proof_of_insurance_filename: computed('proof_of_insurance', function () {
      var poi = this.get('proof_of_insurance');
      return poi && poi.split(/(\\|\/)/g).pop();
    }),
    eld_features: (0, _attr.default)('boolean'),
    personal_use: (0, _attr.default)('boolean'),
    yard_move: (0, _attr.default)('boolean'),
    eld_timezone: (0, _attr.default)('string'),
    eld_start_time: (0, _attr.default)('string'),
    eld_utc_offset: (0, _attr.default)('string'),
    multiday_duty_status: (0, _attr.default)('number'),
    routing: (0, _attr.default)('string', { defaultValue: 'osrm' }),
    shipper_enabled: (0, _attr.default)('boolean'),
    active: (0, _attr.default)('boolean'),
    ifta_reporting: (0, _attr.default)('boolean'),
    ifta_subscription: (0, _attr.default)(),
    recurly_account_id: (0, _attr.default)('number'),

    validate_dot_number: (0, _attr.default)('boolean', { defaultValue: true }),

    address: (0, _relationships.belongsTo)('organization-address', { inverse: null }),
    hauls: (0, _relationships.hasMany)({ async: false }),
    listings: (0, _relationships.hasMany)(),
    sales_person: (0, _relationships.belongsTo)('user', { inverse: 'sales_organizations', async: false }),

    locations: (0, _relationships.hasMany)({ async: false }),
    location_operating_hours: (0, _relationships.hasMany)(),
    addresses: (0, _relationships.hasMany)('organization-address'),
    billing_addresses: (0, _relationships.hasMany)('organization-billing-address'),
    users: (0, _relationships.hasMany)(),
    payment_methods: (0, _relationships.hasMany)(),
    drivers: (0, _relationships.hasMany)(),
    standing_searches: (0, _relationships.hasMany)(),
    power_units: (0, _relationships.hasMany)(),

    isCarrier: computed.equal('org_type', 'carrier'),
    isShipper: computed.equal('org_type', 'shipper'),
    country: computed.alias('address.country'),

    active_drivers: computed.filter('drivers', function (driver) {
      return driver.get('status') === 'active';
    }),

    abbreviated_time_zone: computed('eld_timezone', function () {
      var tz = this.get('eld_timezone');
      if (tz === "Central Time (US & Canada)") {
        return 'CST';
      } else if (tz === "Mountain Time (US & Canada)") {
        return 'MST';
      } else if (tz === "Pacific Time (US & Canada)") {
        return 'PST';
      } else if (tz === "Eastern Time (US & Canada)") {
        return 'EST';
      } else {
        return '';
      }
    }),

    time_zone_code: computed('eld_timezone', function () {
      var tz = this.get('eld_timezone');
      if (tz === "Central Time (US & Canada)") {
        return 'America/Chicago';
      } else if (tz === "Mountain Time (US & Canada)") {
        return 'America/Denver';
      } else if (tz === "Pacific Time (US & Canada)") {
        return 'America/Los_Angeles';
      } else if (tz === "Eastern Time (US & Canada)") {
        return 'America/New_York';
      } else {
        return 'America/Los_Angeles';
      }
    })
  });
});