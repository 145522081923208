define('webapp/helpers/is-step-after', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isStepAfter = isStepAfter;
  function isStepAfter(params) {
    var routes = params[0];
    var current_route = params[1] || '';
    var after_route = params[2] || '';

    var current_last_part = current_route.split('.').slice(-1)[0];
    var after_last_part = after_route.split('.').slice(-1)[0];

    if (routes.indexOf(after_last_part) > routes.indexOf(current_last_part)) {
      return true;
    } else {
      return false;
    }
  }

  exports.default = Ember.Helper.helper(isStepAfter);
});