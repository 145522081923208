define('webapp/initializers/live-filter', ['exports', 'ember-data-live-filter-by/initializers/live-filter'], function (exports, _liveFilter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _liveFilter.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _liveFilter.initialize;
    }
  });
});