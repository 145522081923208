define('webapp/mixins/log_line_mappings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    source_name: Ember.computed('source', function () {
      if (this.get('source') === 0) {
        return 'ELD';
      } else if (this.get('source') === 1) {
        return 'Mobile Device';
      } else if (this.get('source') === 2) {
        return 'Driver';
      } else if (this.get('source') === 3) {
        return 'Dispatcher';
      }
    }),

    event_type_name: Ember.computed('event_type', function () {
      var event_type = this.get('event_type');
      if (event_type === 1) {
        return 'Duty Status Change';
      } else if (event_type === 2) {
        return 'Location Record';
      } else if (event_type === 3) {
        return 'Driving Category Change';
      } else if (event_type === 4) {
        return 'Certification';
      } else if (event_type === 5) {
        return 'Authentication Event';
      } else if (event_type === 6) {
        return 'Engine Event';
      } else if (event_type === 7) {
        return 'Diagnostic Reading';
      } else if (event_type === 50) {
        return 'Engine Diagnostic';
      }
    }),

    event_code_name: Ember.computed('event_type', 'event_code', function () {
      var event_type = this.get('event_type');
      var event_code = this.get('event_code');
      if (event_type === 1) {
        if (event_code === 1) {
          return 'Off-duty';
        } else if (event_code === 2) {
          return 'Sleeper Berth';
        } else if (event_code === 3) {
          return 'Driving';
        } else if (event_code === 4) {
          return 'On-duty not driving';
        }
      } else if (event_type === 2) {
        if (event_code === 1) {
          return 'Conventional Precision';
        } else if (event_code === 2) {
          return 'Reduced Precision';
        }
      } else if (event_type === 3) {
        if (event_code === 1) {
          return 'Authorized Personal Use';
        } else if (event_code === 2) {
          return 'Yard Moves';
        } else if (event_code === 3) {
          return 'Indication Cleared';
        }
      } else if (event_type === 4) {
        if (event_code === 1) {
          return 'First';
        } else if (event_code !== 1) {
          return 'Certification ' + event_code;
        }
      } else if (event_type === 5) {
        if (event_code === 1) {
          return 'Login';
        } else if (event_code === 2) {
          return 'Logout';
        }
      } else if (event_type === 6) {
        if (event_code === 1) {
          return 'Power-up (conventional)';
        } else if (event_code === 2) {
          return 'Power-up (reduced precision)';
        } else if (event_code === 3) {
          return 'Shut-down (conventional)';
        } else if (event_code === 4) {
          return 'Shut-down (reduced precision)';
        }
      } else if (event_type === 7) {
        if (event_code === 1) {
          return 'Malfunction';
        } else if (event_code === 2) {
          return 'Malfunction cleared';
        } else if (event_code === 3) {
          return 'Diagnostic Event';
        } else if (event_code === 4) {
          return 'Diagnostic Event cleared';
        }
      } else if (event_type === 50) {
        return 'Custom Event';
      }
    })

  });
});