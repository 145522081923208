define('webapp/helpers/sub-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.subString = subString;
  function subString(_ref, hash) {
    var value = _ref[0];

    if (value && hash['max_length']) {
      var substring = value.substring(0, hash['max_length']);
      if (value.length > hash['max_length'] && hash['overflow']) {
        substring = substring.trim() + hash['overflow'];
      }
      return substring;
    }
    return value;
  }

  exports.default = Ember.Helper.helper(subString);
});