define('webapp/pods/organization/dispatch/index/details/controller', ['exports', 'common/mixins/icons'], function (exports, _icons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_icons.default, {
    parent: Ember.inject.controller('organization.dispatch.index'),

    unassigned_drivers: Ember.computed.filterBy('drivers', 'is_active_and_unassigned'),

    actions: {
      get_haul_icon_color: function get_haul_icon_color(haul, driver) {
        var color = this.get('parent').actions.assign_dual_color.apply(this.get('parent'), [haul.get('id')]);
        if (driver.get('online')) {
          return '#' + color.full;
        } else {
          return '#' + color.pastel;
        }
      }
    }

  });
});