define('webapp/models/haul', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'webapp/mixins/statuses'], function (exports, _model, _attr, _relationships, _emberCpValidations, _statuses) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;


  var Validations = (0, _emberCpValidations.buildValidations)({

    // attributes

    status: {
      descriptionKey: "status",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['setup', 'pending', 'viewed', 'en_route', 'checked_in', 'completed', 'cancelled', 'deleted']
      })]
    },

    equipment_type: {
      descriptionKey: "equipmentTypeHeader",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed.not('model.validate_equipment_type')
      })]
    },

    trailer_length: {
      descriptionKey: "trailerLengthHeader",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed.not('model.validate_equipment_type')
      }), (0, _emberCpValidations.validator)('inclusion', {
        in: computed.alias('equipment.trailerLengths'),
        disabled: computed.not('model.validate_equipment_type')
      })]
    },

    temperature_type: {
      descriptionKey: "temperatureType",
      dependentKeys: ['needs_temperature'],
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed.alias('model.needs_temperature')
      }), (0, _emberCpValidations.validator)('inclusion', {
        in: ['f', 'c'],
        allowBlank: computed.not('model.needs_temperature'),
        disabled: computed.not('model.needs_temperature')
      })]
    },

    required_temperature: {
      descriptionKey: "temperatureType",
      dependentKeys: ['needs_temperature'],
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: computed.not('model.needs_temperature')
      })]
    },

    // relationships

    organization: {
      descriptionKey: "organizationMenuLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'),
      // Don't include belongs-to for validation
      (0, _emberCpValidations.validator)('presence', true)]
    },

    loads: {
      descriptionKey: "loadsMenuLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('has-many')]
    }

  });

  exports.default = _model.default.extend(Validations, _statuses.default, {

    equipment: inject.service(),

    status: (0, _attr.default)({ defaultValue: 'setup' }),
    equipment_type: (0, _attr.default)(),
    trailer_length: (0, _attr.default)('number'),
    required_temperature: (0, _attr.default)('number'),
    temperature_type: (0, _attr.default)({ defaultValue: 'f' }),
    team_drivers: (0, _attr.default)('boolean'),
    created_at: (0, _attr.default)('datetime-utc'),
    updated_at: (0, _attr.default)('datetime-utc'),

    // this organization is always a carrier
    organization: (0, _relationships.belongsTo)({ async: false }),
    loads: (0, _relationships.hasMany)({ async: false }),
    driver_assignments: (0, _relationships.hasMany)(),
    active_driver: (0, _relationships.belongsTo)('driver', { async: false }),

    // computed properties

    drivers: computed('driver_assignments.[]', function () {
      return this.get('driver_assignments').mapBy('driver');
    }),

    operations: computed('loads.operations.[]', function () {
      var flattened_operations = [];
      for (var _iterator = this.get('loads').toArray(), _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
        var _ref;

        if (_isArray) {
          if (_i >= _iterator.length) break;
          _ref = _iterator[_i++];
        } else {
          _i = _iterator.next();
          if (_i.done) break;
          _ref = _i.value;
        }

        var load = _ref;

        var operations = load.get('operations');
        for (var _iterator2 = operations.toArray(), _isArray2 = Array.isArray(_iterator2), _i2 = 0, _iterator2 = _isArray2 ? _iterator2 : _iterator2[Symbol.iterator]();;) {
          var _ref2;

          if (_isArray2) {
            if (_i2 >= _iterator2.length) break;
            _ref2 = _iterator2[_i2++];
          } else {
            _i2 = _iterator2.next();
            if (_i2.done) break;
            _ref2 = _i2.value;
          }

          var operation = _ref2;

          flattened_operations.push(operation);
        }
      }
      return flattened_operations;
    }),

    first_pickup: computed('loads.@each.first_pickup', function () {
      var pickups = this.get('loads').mapBy('first_pickup').compact().filterBy('actual_date');
      if (pickups) {
        pickups = pickups.sort(function (a, b) {
          return a.get('actual_date').getTime() - b.get('actual_date').getTime();
        });
      }
      return pickups.objectAt(0);
    }),

    last_dropoff: computed('loads.@each.last_dropoff', function () {
      var dropoffs = this.get('loads').mapBy('last_dropoff').compact().filterBy('actual_date');
      if (dropoffs) {
        dropoffs = dropoffs.sort(function (a, b) {
          return b.get('actual_date').getTime() - a.get('actual_date').getTime();
        });
      }
      return dropoffs.objectAt(0);
    }),

    total_loads_units: computed.mapBy('loads', 'total_units'),
    total_units: computed.sum('total_loads_units'),

    total_loads_weight: computed.mapBy('loads', 'total_weight'),
    total_weight: computed.sum('total_loads_weight'),

    needs_temperature: computed('equipment_type', function () {
      return this.get('equipment').getType(this.get('equipment_type')).temperature;
    }),

    equipment_image: computed('equipment_type', function () {
      return this.get('equipment').getType(this.get('equipment_type')).image;
    })

  });
});