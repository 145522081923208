define('webapp/pods/drivers/forgot/controller', ['exports', 'uncharted-ajax'], function (exports, _unchartedAjax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    email: null,
    errors: null,

    actions: {
      submit: function submit() {
        var _this = this;

        this.set('errors', null);
        var email = this.get('email');
        return (0, _unchartedAjax.default)({
          method: 'POST',
          url: '/api/v1/drivers/password',
          dataType: 'json',
          data: {
            driver: {
              email: email
            }
          }
        }).then(function () {
          return _this.transitionToRoute('drivers.forgot-confirmation');
        }).catch(function (result) {
          return _this.set('errors', result.responseJSON.errors);
        });
      },
      reset: function reset() {
        this.set('email', null);
        this.set('errors', null);
      }
    }
  });
});