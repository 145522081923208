define('webapp/transforms/array', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(value) {
      if (Ember.isBlank(value)) {
        return null;
      }
      return Ember.A(value);
    },
    serialize: function serialize(value) {
      return value ? JSON.stringify(value) : null;
    }
  });
});