define('webapp/helpers/map-point', ['exports', 'google-maps/helpers/map-point'], function (exports, _mapPoint) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mapPoint.default;
    }
  });
  Object.defineProperty(exports, 'mapPoint', {
    enumerable: true,
    get: function () {
      return _mapPoint.mapPoint;
    }
  });
});