define('webapp/pods/organization/locations/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      page: {
        refreshModel: true
      },
      filter_type: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var filter = {
        organization_id: this.get('session.currentOrganization.id'),
        status: 'active'
      };

      if (params.filter_type !== 'all') {
        filter['source_type'] = ['both', params.filter_type];
      }

      if (params.search) {
        filter['search'] = params.search;
      }

      return this.store.query('location', {
        filter: filter,
        include: 'operating-hours,address',
        sort: 'company_name',
        page: {
          number: params.page || 1,
          size: params.page_size || 10
        }
      });
    },


    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }

  });
});