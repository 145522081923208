define('webapp/initializers/bust-cache', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    return window.$(document).on('ajaxSend', function (event, xhr, settings) {
      if (settings.type === 'GET') {
        return settings.url += (settings.url.indexOf('?') > -1 ? "&" : "?") + ("_=" + new Date().getTime());
      }
    });
  }

  exports.default = {
    name: 'bust-cache',
    initialize: initialize
  };
});