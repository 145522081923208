define('webapp/pods/organization/loads/index/view/route', ['exports', 'webapp/mixins/route-modals'], function (exports, _routeModals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeModals.default, {
    model: function model(params) {
      return this.store.findRecord('load', params.load_id, {
        include: ['invoice', 'haul', 'haul.driver-assignments', 'haul.driver-assignments.driver', 'operations', 'operations.images', 'operations.location', 'operations.location.address', 'organization'].join(',')
      });
    }
  });
});