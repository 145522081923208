define('webapp/app', ['exports', 'webapp/resolver', 'ember-load-initializers', 'webapp/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Ember.MODEL_FACTORY_INJECTIONS = true;

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default,
    engines: {
      tracking: {
        dependencies: {
          services: ['store', 'session', 'cookies', 'geography', 'sync'],
          externalRoutes: {
            'organization': 'organization',
            'organization.help': 'organization.help',
            'user.profile': 'user.profile',
            'organization.locations.view': 'organization.locations.view'
          }
        }
      },
      manage: {
        dependencies: {
          services: ['store', 'session', 'cookies', 'geography', 'sync', 'language'],
          externalRoutes: {
            'organization': 'organization',
            'organization.help': 'organization.help',
            'user.profile': 'user.profile',
            'users.login': 'users.login',
            'organization.locations.view': 'organization.locations.view'
          }
        }
      },
      sales: {
        dependencies: {
          services: ['store', 'session', 'cookies', 'geography', 'sync', 'language'],
          externalRoutes: {
            'organization': 'organization',
            'organization.help': 'organization.help',
            'user.profile': 'user.profile',
            'users.login': 'users.login',
            'organization.locations.view': 'organization.locations.view'
          }
        }
      },
      shipper: {
        dependencies: {
          services: ['store', 'session', 'cookies', 'router', 'sync', 'geography', 'language', 'equipment', 'organization_blocker', 'notification_area', 'eld_alert_notification_area', 'load_operation_tasks'],
          externalRoutes: {
            'user.profile': 'user.profile',
            'users.login': 'users.login',
            'organization': 'organization',
            'organization.dispatch': 'organization.dispatch',
            'organization.loads': 'organization.loads',
            'organization.loads.edit.pickup': 'organization.loads.edit.pickup',
            'organization.loads.edit.dropoff': 'organization.loads.edit.dropoff',
            'organization.locations': 'organization.locations',
            'organization.help': 'organization.help',
            'organization.view': 'organization.view',
            'organization.locations.view': 'organization.locations.view',
            'organization.offers.new': 'organization.offers.new',
            'user.card': 'user.card',
            'carrier.listings.index.confirm-availability': 'carrier.listings.index.confirm-availability',
            'manage': 'manage',
            'sales.dashboard': 'sales.dashboard',
            'organization.feedback': 'organization.feedback'
          }
        }
      },
      carrier: {
        dependencies: {
          services: ['store', 'session', 'cookies', 'router', 'sync', 'language', 'equipment', 'organization_blocker', 'notification_area', 'eld_alert_notification_area', 'load_operation_tasks', 'calendar_data', 'geography'],
          externalRoutes: {
            'user.profile': 'user.profile',
            'user.subscription': 'user.subscription',
            'users.login': 'users.login',
            'organization': 'organization',
            'organization.edit': 'organization.edit',
            'organization.dispatch': 'organization.dispatch',
            'organization.loads': 'organization.loads',
            'organization.loads.edit.pickup': 'organization.loads.edit.pickup',
            'organization.loads.edit.dropoff': 'organization.loads.edit.dropoff',
            'organization.loads.index.view': 'organization.loads.index.view',
            'organization.locations': 'organization.locations',
            'organization.help': 'organization.help',
            'organization.view': 'organization.view',
            'organization.locations.view': 'organization.locations.view',
            'organization.offers.new': 'organization.offers.new',
            'user.card': 'user.card',
            'user.record-fuel-purchase.step-one': 'user.record-fuel-purchase.step-one',
            'manage': 'manage',
            'sales.dashboard': 'sales.dashboard',
            'organization.feedback': 'organization.feedback',
            'user.record-fuel-purchase.step-one.edit': 'user.record-fuel-purchase.step-one.edit'
          }
        }
      }
    }
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});