define('webapp/models/device', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    platform: (0, _attr.default)('string'),
    platform_name: Ember.computed('platform', function () {
      if (this.get('platform') === 'android') {
        return 'Android';
      } else {
        return 'iOS';
      }
    }),
    driver: (0, _relationships.belongsTo)('driver')
  });
});