define('webapp/pods/users/forgot/controller', ['exports', 'uncharted-ajax'], function (exports, _unchartedAjax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    email: null,
    errors: null,
    sent: false,

    actions: {
      submit: function submit() {
        var _this = this;

        this.set('errors', null);
        var email = this.get('email');
        return (0, _unchartedAjax.default)({
          method: 'POST',
          url: '/api/v1/users/password',
          dataType: 'json',
          data: {
            user: {
              email: email
            }
          }
        }).then(function () {
          _this.set('sent', true);
        }).catch(function (result) {
          _this.set('errors', result.responseJSON.errors);
        });
      },
      reset: function reset() {
        this.set('email', null);
        this.set('errors', null);
        this.set('sent', false);
      }
    }

  });
});