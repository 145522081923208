define('webapp/pods/organization/loads/edit/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    redirect: function redirect() {
      return this.transitionTo('organization.loads.edit.pickup');
    }
  });
});