define('webapp/models/load-tracking', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    latitude: (0, _attr.default)('number'),
    longitude: (0, _attr.default)('number'),
    speed: (0, _attr.default)('number'),
    accuracy: (0, _attr.default)('number'),
    created_at: (0, _attr.default)('datetime_utc'),
    located_at: (0, _attr.default)('datetime_utc'),

    organization: (0, _relationships.belongsTo)(),
    haul: (0, _relationships.belongsTo)(),
    load: (0, _relationships.belongsTo)(),
    operation: (0, _relationships.belongsTo)()

  });
});