define('webapp/pods/organization/feedback/controller', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    subject: {
      description: "Subject",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 250
      })]
    },
    body: {
      description: "Message",
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 10000
      })]
    }
  });

  exports.default = Ember.Controller.extend(Validations, {
    subject: null,
    body: null,
    submitted: false,
    sending: false,
    sent: false,

    isValid: Ember.computed('validations.isValid', 'submitted', function () {
      if (this.get('submitted')) {
        return this.get('validations.isValid');
      }
      return true;
    }),

    actions: {
      send: function send() {
        var _this = this;

        if (this.get('sending')) {
          return;
        }
        this.set('submitted', true);
        return this.validate().then(function (_ref) {
          var model = _ref.model,
              validations = _ref.validations;

          if (!validations.get('isValid')) {
            _this.set('error', true);
            return;
          }
          _this.get('session.currentUser').send_feedback(_this.get('subject'), _this.get('body')).then(function () {
            return _this.set('sent', true);
          }).catch(function () {
            return alert(_this.get('intl').t("unknownErrorFeedback"));
          }).finally(function () {
            return _this.set('sending', false);
          });
        });
      },
      reset: function reset() {
        this.set('subject', null);
        this.set('body', null);
        this.set('submitted', false);
        this.set('sending', false);
        this.set('sent', false);
      }
    }
  });
});