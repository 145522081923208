define('webapp/mixins/payment-method', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;


  var now = function now() {
    return new Date();
  };

  exports.default = Ember.Mixin.create({
    kind: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date')
  });
});