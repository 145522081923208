define("webapp/services/geography", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      computed = Ember.computed;
  exports.default = Ember.Service.extend({

    session: inject.service(),

    states: {
      US: [{ abbr: "AL", name: "Alabama" }, { abbr: "AK", name: "Alaska" }, { abbr: "AZ", name: "Arizona" }, { abbr: "AR", name: "Arkansas" }, { abbr: "CA", name: "California" }, { abbr: "CO", name: "Colorado" }, { abbr: "CT", name: "Connecticut" }, { abbr: "DE", name: "Delaware" }, { abbr: "DC", name: "District of Columbia" }, { abbr: "FL", name: "Florida" }, { abbr: "GA", name: "Georgia" }, { abbr: "HI", name: "Hawaii" }, { abbr: "ID", name: "Idaho" }, { abbr: "IL", name: "Illinois" }, { abbr: "IN", name: "Indiana" }, { abbr: "IA", name: "Iowa" }, { abbr: "KS", name: "Kansas" }, { abbr: "KY", name: "Kentucky" }, { abbr: "LA", name: "Louisiana" }, { abbr: "ME", name: "Maine" }, { abbr: "MD", name: "Maryland" }, { abbr: "MA", name: "Massachusetts" }, { abbr: "MI", name: "Michigan" }, { abbr: "MN", name: "Minnesota" }, { abbr: "MS", name: "Mississippi" }, { abbr: "MO", name: "Missouri" }, { abbr: "MT", name: "Montana" }, { abbr: "NE", name: "Nebraska" }, { abbr: "NV", name: "Nevada" }, { abbr: "NH", name: "New Hampshire" }, { abbr: "NJ", name: "New Jersey" }, { abbr: "NM", name: "New Mexico" }, { abbr: "NY", name: "New York" }, { abbr: "NC", name: "North Carolina" }, { abbr: "ND", name: "North Dakota" }, { abbr: "OH", name: "Ohio" }, { abbr: "OK", name: "Oklahoma" }, { abbr: "OR", name: "Oregon" }, { abbr: "PA", name: "Pennsylvania" }, { abbr: "PR", name: "Puerto Rico" }, { abbr: "RI", name: "Rhode Island" }, { abbr: "SC", name: "South Carolina" }, { abbr: "SD", name: "South Dakota" }, { abbr: "TN", name: "Tennessee" }, { abbr: "TX", name: "Texas" }, { abbr: "UT", name: "Utah" }, { abbr: "VT", name: "Vermont" }, { abbr: "VA", name: "Virginia" }, { abbr: "WA", name: "Washington" }, { abbr: "WV", name: "West Virginia" }, { abbr: "WI", name: "Wisconsin" }, { abbr: "WY", name: "Wyoming" }],

      CA: [{ abbr: "AB", name: "Alberta" }, { abbr: "BC", name: "British Columbia" }, { abbr: "MB", name: "Manitoba" }, { abbr: "NB", name: "New Brunswick" }, { abbr: "NL", name: "Newfoundland and Labrador" }, { abbr: "NS", name: "Nova Scotia" }, { abbr: "NT", name: "Northwest Territories" }, { abbr: "NU", name: "Nunavut" }, { abbr: "ON", name: "Ontario" }, { abbr: "PE", name: "Prince Edward Island" }, { abbr: "QC", name: "Quebec" }, { abbr: "SK", name: "Saskatchewan" }, { abbr: "YT", name: "Yukon" }],

      MX: [{ abbr: "AG", name: "Aguascalientes" }, { abbr: "BN", name: "Baja California" }, { abbr: "BS", name: "Baja California Sur" }, { abbr: "CM", name: "Campeche" }, { abbr: "CP", name: "Chiapas" }, { abbr: "CH", name: "Chihuahua" }, { abbr: "CA", name: "Coahuila" }, { abbr: "CL", name: "Colima" }, { abbr: "CX", name: "D.F." }, { abbr: "DU", name: "Durango" }, { abbr: "GT", name: "Guanajuato" }, { abbr: "GR", name: "Guerrero" }, { abbr: "HI", name: "Hidalgo" }, { abbr: "JA", name: "Jalisco" }, { abbr: "MX", name: "México" }, { abbr: "MC", name: "Michoacán" }, { abbr: "MR", name: "Morelos" }, { abbr: "NA", name: "Nayarit" }, { abbr: "NL", name: "Nuevo León" }, { abbr: "OA", name: "Oaxaca" }, { abbr: "PU", name: "Puebla" }, { abbr: "QE", name: "Querétaro" }, { abbr: "QR", name: "Quintana Roo" }, { abbr: "SL", name: "San Luis Potosí" }, { abbr: "SI", name: "Sinaloa" }, { abbr: "SO", name: "Sonora" }, { abbr: "TB", name: "Tabasco" }, { abbr: "TM", name: "Tamaulipas" }, { abbr: "TL", name: "Tlaxcala" }, { abbr: "VE", name: "Veracruz" }, { abbr: "YU", name: "Yucatán" }, { abbr: "ZA", name: "Zacatecas" }]
    },

    // date formatting

    dateFormat: computed('session.currentOrganization.address.country', function () {
      var country = this.get('session.currentOrganization.address.country');
      if (country == 'US') {
        return 'MM/DD/YYYY';
      }
      if (country == 'CA') {
        return 'YYYY/MM/DD';
      }
      if (country == 'MX') {
        return 'DD/MM/YYYY';
      }
    }),

    formatDate: function formatDate(date) {
      return moment(date).format(this.get('dateFormat'));
    }
  });
});