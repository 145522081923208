define('webapp/authenticators/application', ['exports', 'ember-simple-auth/authenticators/devise'], function (exports, _devise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember = Ember,
      isEmpty = _Ember.isEmpty,
      RSVP = _Ember.RSVP,
      get = _Ember.get;
  exports.default = _devise.default.extend({
    serverTokenEndpoint: '/api/v1/users/sign_in',

    restore: function restore(data) {
      var _getProperties = this.getProperties('tokenAttributeName', 'identificationAttributeName'),
          tokenAttributeName = _getProperties.tokenAttributeName,
          identificationAttributeName = _getProperties.identificationAttributeName;

      var tokenAttribute = get(data, tokenAttributeName);
      var identificationAttribute = get(data, identificationAttributeName);

      return new RSVP.Promise(function (resolve, reject) {
        if (!isEmpty(tokenAttribute) && !isEmpty(identificationAttribute)) {
          resolve(data);
        } else {
          reject();
        }
      });
    }
  });
});