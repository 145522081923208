define('webapp/helpers/is-nan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isNAN = isNAN;
  function isNAN(params) {
    var value = params[0];
    return isNaN(value);
  }

  exports.default = Ember.Helper.helper(isNAN);
});