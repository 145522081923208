define('webapp/pods/organization/notifications/index/controller', ['exports', 'uncharted-ajax'], function (exports, _unchartedAjax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['page', 'page_size', 'pinned', 'archived', 'search'],
    page: 1,
    page_size: 20,
    pinned: false,
    archived: false,
    selected_all: false,
    search: null,
    notification_area: Ember.inject.service(),

    actions: {
      selectAll: function selectAll() {

        // select / unselect all other checkboxes
        // this function is fired before value is updated in non-IE browsers
        // this functions is fired after value is updated in IE

        var bulkOption = false;

        var isIE = false || !!document.documentMode;
        var isEdge = window.navigator.userAgent.indexOf("Edge") > -1;
        var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

        if (this.get('selected_all') === (false && !isIE && !isSafari && !isEdge)) {
          bulkOption = true; // specify real value
        }

        if (isSafari || isEdge) {
          bulkOption = this.get('selected_all');
        }

        this.get('model').forEach(function (notification) {
          notification.set('is_selected', bulkOption);
        });
      },

      readBulk: function readBulk(unread) {
        var _this = this;

        // array of ids for bulk action
        var ids = [];

        this.get('model').forEach(function (notification) {

          if (notification.is_selected === true) {
            ids.push(notification.id);
          }
        });

        return (0, _unchartedAjax.default)({
          method: 'POST',
          url: '/api/v1/notifications',
          dataType: 'json',
          data: {
            ids: ids,
            unread: unread
          }
        }).then(function (res) {

          if (res.success === true) {
            var total_notification_number = _this.get('notification_area').get('total');

            if (unread === true) {
              // update total notification count

              _this.get('notification_area').set('total', total_notification_number + res.count);
            } else {
              _this.get('notification_area').set('total', total_notification_number - res.count);
            }

            _this.send('update_notifications', ids, unread);
          }
        }).catch(function (result) {
          // error handling might be necessary
        });
      },
      update_notifications: function update_notifications(ids, unread) {

        //update unread variable of each notification
        this.get('model').forEach(function (notification) {

          //check whether it belongs to selected ids
          if (ids.indexOf(notification.id) > -1) {
            notification.set('unread', unread);
          }
        });
      }
    }
  });
});