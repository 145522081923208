define('webapp/models/eld-device', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({

    serial_number: {
      descriptionKey: "serialNumber",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    }

  });

  exports.default = _model.default.extend(Validations, {

    serial_number: (0, _attr.default)('string'),
    fmcsa_registration_id: (0, _attr.default)('string'),
    power_unit_id: (0, _attr.default)('string'),
    online: (0, _attr.default)('boolean'),
    enabled: (0, _attr.default)('boolean', { defaultValue: true }),
    vin_number: (0, _attr.default)('string'),
    active: (0, _attr.default)('boolean', { defaultValue: true }),
    last_connected_at: (0, _attr.default)('datetime_utc'),
    created_at: (0, _attr.default)('datetime_utc'),

    organization: (0, _relationships.belongsTo)()

  });
});