define('webapp/models/location-operating-hour', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    organization: {
      descriptionKey: "organizationMenuLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'),
      // Don't include belongs-to for validation
      (0, _emberCpValidations.validator)('presence', true)]
    },

    location: {
      descriptionKey: "location",
      validators: [(0, _emberCpValidations.validator)('ds-error'),
      // Don't include belongs-to for validation
      (0, _emberCpValidations.validator)('presence', true)]
    },

    day: {
      descriptionKey: "day",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('inclusion', {
        in: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      })]
    },

    open: {
      descriptionKey: "open",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },

    close: {
      descriptionKey: "close",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },

    all_day: {
      descriptionKey: "allDay",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('inclusion', {
        in: [false, true]
      })]
    },

    position: {
      descriptionKey: "position",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    }
  });

  exports.default = _model.default.extend(Validations, {

    day: (0, _attr.default)('string'),
    open: (0, _attr.default)('time_text'),
    close: (0, _attr.default)('time_text'),
    all_day: (0, _attr.default)('boolean'),
    position: (0, _attr.default)('number'),

    organization: (0, _relationships.belongsTo)(),
    location: (0, _relationships.belongsTo)(),

    is_monday: computed.equal('day', 'Mon'),
    is_tuesday: computed.equal('day', 'Tue'),
    is_wednesday: computed.equal('day', 'Wed'),
    is_thursday: computed.equal('day', 'Thu'),
    is_friday: computed.equal('day', 'Fri'),
    is_saturday: computed.equal('day', 'Sat'),
    is_sunday: computed.equal('day', 'Sun')

  });
});