define('webapp/pods/users/login/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Controller$ext;

  var inject = Ember.inject,
      computed = Ember.computed;
  exports.default = Ember.Controller.extend((_Ember$Controller$ext = {

    session: inject.service(),

    queryParams: ['password_reset', 'signed_up', 'thank_you', 'confirmed'],
    email: null,
    password: null,
    remember: false,
    errors: null,
    loading: false,
    logged_in: false,
    toggled: false

  }, _Ember$Controller$ext['remember'] = computed({
    get: function get(key) {
      return false;
    },
    set: function set(key, value) {
      var expirationTime = value ? 14 * 24 * 60 * 60 : null;
      this.set('session.store.cookieExpirationTime', expirationTime);
      return value;
    }
  }), _Ember$Controller$ext.loading_or_logged_in = computed.or('loading', 'logged_in'), _Ember$Controller$ext.currentYear = computed(function () {
    return new Date().getFullYear();
  }), _Ember$Controller$ext.usingEnglish = computed(function () {
    return this.get('intl').get('locale')[0] === 'en';
  }), _Ember$Controller$ext.actions = {
    authenticate: function authenticate() {
      var _this = this;

      if (this.get('loading_or_logged_in')) {
        return;
      }
      this.set('errors', null);
      this.set('loading', true);

      var _getProperties = this.getProperties('email', 'password'),
          email = _getProperties.email,
          password = _getProperties.password;

      this.get('session').authenticate('authenticator:application', email, password).then(function () {

        _this.store.unloadAll();
        _this.set('logged_in', true);
        _this.get('session.loadCurrentUser').perform().then(function (user) {
          if (_this.get('confirmed') || user.get('sign_in_count') === 1) {
            _this.transitionToRoute('organization.help', { queryParams: { signed_up: true } });
          }
          if (user.get('organization.isShipper') && !user.get('organization.verified')) {
            _this.send('open_modal', 'unverified-user', {});
          }
        });
      }).catch(function (reason) {
        var errors = reason.error || reason;
        if (/must confirm your email/.test(errors)) {
          errors = _this.get('intl').t('mustConfirmEmail');
        }
        _this.set('errors', errors);
      }).finally(function () {
        _this.set('loading', false);
      });
    },
    reset: function reset() {
      this.set('email', null);
      this.set('password', null);
      this.set('errors', null);
      this.set('loading', false);
      this.set('logged_in', false);
    },
    toggle_password: function toggle_password() {
      this.toggleProperty('toggled');
    },
    swap_language: function swap_language(language) {
      this.get('intl').setLocale(language);
      this.toggleProperty('usingEnglish');
    },
    no_signups: function no_signups() {
      alert(this.get('intl').t('openSignUp'));
    }
  }, _Ember$Controller$ext));
});