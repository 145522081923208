define('webapp/models/standing_search', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'ember-md5', 'webapp/utils/prune-object'], function (exports, _model, _attr, _relationships, _emberCpValidations, _emberMd, _pruneObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    organization: {
      descriptionKey: 'organizationLabel',
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('belongs-to'), (0, _emberCpValidations.validator)('presence', true)]
    }
  });

  exports.default = _model.default.extend(Validations, {

    name: (0, _attr.default)('string'),
    equipment_types: (0, _attr.default)('array', { defaultValue: function defaultValue() {
        return [];
      } }),
    pickup_location: (0, _attr.default)('string'),
    radius_from_pickup: (0, _attr.default)('number'),
    dropoff_location: (0, _attr.default)('string'),
    radius_from_dropoff: (0, _attr.default)('number'),
    minimum_value: (0, _attr.default)('number'),
    pickup_date: (0, _attr.default)('datetime_utc'),
    pickup_within_days: (0, _attr.default)('number'),
    dropoff_date: (0, _attr.default)('datetime_utc'),
    dropoff_within_days: (0, _attr.default)('number'),
    extra_insurance_required: (0, _attr.default)('boolean'),
    created_at: (0, _attr.default)('datetime_utc'),

    user: (0, _relationships.belongsTo)(),
    organization: (0, _relationships.belongsTo)(),

    equipment_types_array: computed('equipment_types', function () {
      if (this.get('equipment_types') && !Ember.isEmpty(this.get('equipment_types'))) {
        return this.get('equipment_types');
      } else {
        return [];
      }
    }),

    toJSON: function toJSON() {
      var result = {
        'listing': {
          'equipment_type': this.get('equipment_types_array').sortBy('name'),
          'minimum': this.get('minimum_value'),
          'extra_insurance_required': this.get('extra_insurance_required')
        },
        'pickup': {
          'location': this.get('pickup_location'),
          'radius': {
            'distance': this.get('radius_from_pickup')
          },
          'date': {
            'date': this.get('pickup_date') && moment(this.get('pickup_date')).format('YYYY-MM-DD'),
            'within': this.get('pickup_within_days')
          }
        },
        'dropoff': {
          'location': this.get('dropoff_location'),
          'radius': {
            'distance': this.get('radius_from_dropoff')
          },
          'date': {
            'date': this.get('dropoff_date') && moment(this.get('dropoff_date')).format('YYYY-MM-DD'),
            'within': this.get('dropoff_within_days')
          }
        }
      };

      return new _pruneObject.default(result);
    },

    hash: computed(function () {
      return (0, _emberMd.default)(JSON.stringify(this.toJSON()));
    }).volatile()

  });
});