define('webapp/serializers/user', ['exports', 'webapp/pods/application/serializer'], function (exports, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      var keys = ['avatar'];
      // Only allow password on create
      if (Ember.isPresent(snapshot.id)) {
        keys.push('password');
      }

      // Only allow roles for admins
      if (!this.get('session.currentUser.is_admin')) {
        keys.push('role');
      }

      if (keys.indexOf(key) < 0) {
        if (key !== 'locked' && key !== 'unlock_token' && key !== 'sign_in_count') {
          return this._super.apply(this, arguments);
        }
      }
    }
  });
});