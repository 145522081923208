define('webapp/pods/organization/loads/components/load-dropoff/component', ['exports', 'webapp/mixins/times'], function (exports, _times) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend(_times.default, {

    // params

    queryParams: ["dropoff"],
    dropoff: null,

    submitted: false,

    didReceiveAttrs: function didReceiveAttrs() {
      // set the right active dropoff
      // Sort by id ASC and then get the first object
      this.set('activeDropoff', this.get('dropoffs').sort(function (a, b) {
        return a.get('id') - b.get('id');
      })[0]);
    },


    // computed properties

    dropoffs: computed('model.dropoffs.[]', function () {
      return this.get('model.dropoffs').rejectBy('isDeleted', true);
    }),

    actions: {
      updateStatus: function updateStatus(dropoff, status) {
        dropoff.set('status', status);

        // If status was downgraded from completed, remove the completed date
        if (status !== 'is_completed') {
          dropoff.set('completed_date', null);
        }

        // If a dropoff is in setup and the load is not, change dropoff to at least pending
        if (status == 'in_setup' && !this.currentModel.get('in_setup')) {
          dropoff.set('status', 'pending');
        }
      },
      updateDropoffLocation: function updateDropoffLocation(dropoff, location) {
        dropoff.set('location', location);
        // TODO why location has to be stored in the load operation??
        dropoff.copyLocation();
      }
    }

  });
});