define('webapp/pods/organization/locations/components/delete-location-modal/component', ['exports', 'semantic-ui-ember/components/ui-modal'], function (exports, _uiModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiModal.default.extend({
    loading: false,
    error: false,
    classNames: ['delete-location-modal'],
    // TODO: Put some conditions in here
    can_delete: true,

    actions: {
      approve: function approve(view) {
        var _this = this;

        this.set('loading', true);

        var obj = this.get('location');

        obj.set('status', 'deleted');

        obj.save().then(function () {
          view.execute('hide');
          _this.get('completed')();
        }).catch(function (error) {
          _this.set('error', true);
        }).finally(function () {
          _this.set('loading', false);
        });
      }
    }
  });
});