define('webapp/serializers/driver', ['exports', 'webapp/pods/application/serializer'], function (exports, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      if (key !== 'online' && key !== 'loads_hauled') {
        return this._super.apply(this, arguments);
      }
    }
  });
});