define('webapp/pods/organization/loads/edit/route', ['exports', 'ember-concurrency', 'webapp/mixins/route-modals'], function (exports, _emberConcurrency, _routeModals) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      isEmpty = Ember.isEmpty;
  exports.default = Ember.Route.extend(_routeModals.default, {

    attemptedTransition: true,

    beforeModel: function beforeModel() {
      this.set('attemptedTransition', false);
      return this._super.apply(this, arguments);
    },
    model: function model(params) {
      return this.store.findRecord('load', params.load_id, {
        include: ['haul', 'haul.driver-assignments', 'haul.driver-assignments.driver', 'operations', 'operations.images', 'operations.location', 'operations.location.address', 'operations.location.operating-hours', 'operations.cargos', 'operations.cargos.destinations.dropoff-operation', 'operations.cargo-destinations'].join(',')
      });
    },


    actions: {
      willTransition: function willTransition(transition) {

        // ridiculous how Ember forces you to put a flag not to call willTransition again
        if (this.get('attemptedTransition')) {
          return true;
        }

        var load = this.currentModel;
        var to = transition.targetName;

        // if we are not going to keep editing, prompt for deletion/rollback
        if (!to.includes("organization.loads.edit")) {
          if (load.areRelatedDirty()) {
            transition.abort();
            if (load.get('in_setup')) {
              this.send('open_modal', 'organization/loads/components/delete-modal', {
                load: load,
                transition: transition,
                deleteTask: this.deleteTask
              });
            } else {
              this.send('open_modal', 'organization/loads/components/rollback-modal', {
                load: load,
                transition: transition,
                rollbackTask: this.rollbackTask
              });
            }
          }

          return true;
        }
      }
    }

  });
});