define('webapp/router', ['exports', 'webapp/config/environment', 'webapp/mixins/route-css'], function (exports, _environment, _routeCss) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      get = Ember.get,
      run = Ember.run;


  Ember.Route.reopen(_routeCss.default);

  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    metrics: inject.service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      run.scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');
        var title = _this.getWithDefault('currentRouteName', 'unknown');
        get(_this, 'metrics').trackPage({ page: page, title: title });
      });
    }
  });

  Router.map(function () {

    // common

    this.route('drivers', function () {
      this.route('confirmation');
      this.route('download');
      this.route('forgot-confirmation');
      this.route('forgot');

      this.route('password', function () {
        this.route('new');
        this.route('edit');
      });
    });

    this.route('itm-tracking', { path: '/itm-tracking/:itm_hardware_device_id' });

    this.route('user', function () {
      this.route('profile');
      this.route('edit', { path: '/:user_id/edit' });

      this.route('record-fuel-purchase', { path: '/record-fuel-purchase' }, function () {
        this.route('step-one', function () {
          this.route('edit', { path: '/:fuel_stop_id/edit' });
        });
        this.route('step-two', { path: '/:fuel_stop_id/step-two' });
        this.route('step-three', { path: '/:fuel_stop_id/step-three' });
      });

      this.route('subscription');
      this.route('card');
    });

    // encapsulating this under users so we can override the users template
    this.route('users', function () {
      this.route('confirmation');
      this.route('choose-org-type');
      this.route('forgot');
      this.route('resend-confirmation');
      this.route('login');
      this.route('signup');

      this.route('password', function () {
        this.route('edit');
      });
    });

    this.route('organization', function () {

      // organization top level routes
      this.route('edit');
      this.route('view');
      this.route('unverified');

      // common

      this.route('feedback');
      this.route('help');

      this.route('dispatch', function () {
        this.route('index', { path: '/' }, function () {
          this.route('details');
          this.route('load', { path: 'load/:load_id' });
          this.route('operation', { path: 'load/:load_id/operation/:load_operation_id' });
        });
      });

      this.route('loads', function () {
        this.route('index', { path: '/' }, function () {
          this.route('view', { path: '/:load_id' });
          this.route('operation', { path: '/:load_id/operation/:load_operation_id' });
        });
        this.route('tracking', { path: '/tracking/:load_id' });
        this.route('new', function () {
          this.route('pickup');
        });
        this.route('edit', { path: '/:load_id/edit' }, function () {
          this.route('pickup');
          this.route('dropoff');
          this.route('cargo');
          this.route('equipment');
          this.route('driver');
          this.route('summary');
        });
      });

      this.route('locations', function () {
        this.route('new');
        this.route('view', { path: '/:location_id' });
        this.route('edit', { path: '/:location_id/edit' });
      });

      this.route('log-lines', function () {
        this.route('edit', { path: '/:log_line_id/edit' });
      });

      this.route('offers', function () {
        this.route('new');
      });

      this.route('notifications', function () {
        this.route('view', { path: '/:notification_id' });
      });
    });

    this.mount('tracking');
    this.mount('manage');
    this.mount('sales');
    this.mount('shipper', { path: '/organization/shipper' });
    this.mount('carrier', { path: '/organization/carrier' });
  });

  exports.default = Router;
});