define('webapp/helpers/offer-term-color', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.offerTermColor = offerTermColor;
  function offerTermColor(_ref) {
    var isCountered = _ref[0],
        _ref$ = _ref[1],
        colored = _ref$ === undefined ? true : _ref$;


    if (!colored) {
      return 'black';
    }

    if (isCountered) {
      return 'red';
    } else {
      return 'green';
    }
  }

  exports.default = Ember.Helper.helper(offerTermColor);
});