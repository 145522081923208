define('webapp/models/itm-line', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    sequence_id: (0, _attr.default)('number'),
    latitude: (0, _attr.default)('string'),
    longitude: (0, _attr.default)('string'),
    temperature: (0, _attr.default)('string'),
    battery_level: (0, _attr.default)('string'),
    timestamp: (0, _attr.default)('number'),

    itm_device: (0, _relationships.belongsTo)()

  });
});