define('webapp/models/fuel-stop', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;


  var Validations = (0, _emberCpValidations.buildValidations)({
    refueled_at: {
      descriptionKey: "Refueled at",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    date: {
      descriptionKey: "dateLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    time: {
      descriptionKey: 'time',
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    country: {
      descriptionKey: "country",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    state: {
      descriptionKey: "stateLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    odometer_reading: {
      descriptionKey: "odometerReading",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        gt: computed.alias('model.maxOdometerReading'),
        lt: computed.alias('model.minOdometerReading')
      })]
    },
    odometer_in_kms: {
      descriptionKey: "odometerReadingKms",
      disabled: computed.alias('model.odometerInMiles'),
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        gt: computed.alias('model.maxOdometerReadingInKms'),
        lt: computed.alias('model.minOdometerReadingInKms')
      })]
    },
    driver: {
      descriptionKey: "driverLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    power_unit: {
      descriptionKey: "vehicle",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    quantity_of_fuel: {
      descriptionKey: "quantityOfFuel",
      disabled: computed.not('model.onStepTwo'),
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    quantity_of_fuel_liters: {
      descriptionKey: "quantityOfFuelLiters",
      disabled: computed.not('model.inCanadaAndStepTwo'),
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    price_per_liter: {
      descriptionKey: "pricePerLiter",
      disabled: computed.not('model.inCanadaAndStepTwo'),
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    price_per_gallon: {
      descriptionKey: "pricePerGallon",
      disabled: computed.not('model.onStepTwo'),
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },
    fuel_type: {
      descriptionKey: "fuelType",
      disabled: computed.not('model.onStepTwo'),
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', { in: ['diesel', 'motor_fuel_gasoline', 'ethanol', 'propane', 'compressed_natural_gas', 'liquified_natural_gas'] })]
    }
  });

  exports.default = _model.default.extend(Validations, {

    geography: inject.service(),

    format: computed.alias('geography.dateFormat'),

    date: (0, _attr.default)('date'),
    time: (0, _attr.default)('time'),
    odometer_reading: (0, _attr.default)('number'),
    odometer_in_kms: (0, _attr.default)('number'),
    fuel_type: (0, _attr.default)('string'),
    quantity_of_fuel: (0, _attr.default)('number'),
    quantity_of_fuel_liters: (0, _attr.default)('number'),
    price_per_gallon: (0, _attr.default)('number'),
    price_per_liter: (0, _attr.default)('number'),
    receipt_photo: (0, _attr.default)('string'),
    receipt_photo_filename: computed('receipt_photo', function () {
      var rp = this.get('receipt_photo');
      return rp && rp.split(/(\\|\/)/g).pop();
    }),
    state: (0, _attr.default)('string'),
    country: (0, _attr.default)('string'),
    refueled_at: (0, _attr.default)('datetime'),
    step: (0, _attr.default)('string'),

    driver: (0, _relationships.belongsTo)(),
    power_unit: (0, _relationships.belongsTo)(),

    onStepTwo: computed('step', function () {
      return this.get('step') === 'two';
    }),

    odometerInMiles: computed.equal('power_unit.odometer_unit', 'miles'),
    inCanadaAndStepTwo: computed.and('inCanada', 'onStepTwo'),
    inCanada: computed.equal('country', 'CA'),

    minOdometerReading: computed('isNew', 'power_unit.max_odometer_reading', 'refueled_at', 'power_unit.max_refueled_at', function () {
      var dateFormat = this.get('format');
      if (this.get('isNew')) {
        if (moment(this.get('refueled_at'), dateFormat) < moment(this.get('power_unit.max_refueled_at'), dateFormat) && this.get('odometer_reading') * 1 > this.get('power_unit.max_odometer_reading')) {
          return this.get('power_unit.max_odometer_reading');
        } else {
          return (this.get('power_unit.max_odometer_reading') || 1) * 10000000;
        }
      } else {
        // Dont let setting a greater odometer value for a past date
        if (moment(this.get('refueled_at'), dateFormat) < moment(this.get('power_unit.max_refueled_at'), dateFormat)) {
          return this.get('power_unit.max_odometer_reading');
        }
      }
    }),

    maxOdometerReading: computed('isNew', 'power_unit.max_odometer_reading', 'refueled_at', 'power_unit.max_refueled_at', function () {
      var dateFormat = this.get('format');
      if (moment(this.get('refueled_at'), dateFormat) > moment(this.get('power_unit.max_refueled_at'), dateFormat)) {
        return this.get('power_unit.max_odometer_reading');
      } else {
        return 0;
      }
    }),

    maxOdometerReadingInKms: computed('isNew', 'power_unit.max_odometer_reading', 'refueled_at', 'power_unit.max_refueled_at', function () {
      var max_odometer_in_kms = (this.get('power_unit.max_odometer_reading') * 1.60934).toFixed(2);
      var dateFormat = this.get('format');
      if (moment(this.get('refueled_at'), this.get('format')) > moment(this.get('power_unit.max_refueled_at'), dateFormat)) {
        return max_odometer_in_kms;
      } else {
        return 0;
      }
    }),

    minOdometerReadingInKms: computed('isNew', 'power_unit.max_odometer_reading', 'refueled_at', 'power_unit.max_refueled_at', function () {
      var dateFormat = this.get('format');
      var max_odometer_in_kms = parseFloat((this.get('power_unit.max_odometer_reading') * 1.60934).toFixed(2));
      if (this.get('isNew')) {
        if (moment(this.get('refueled_at'), dateFormat) < moment(this.get('power_unit.max_refueled_at'), dateFormat) && this.get('odometer_reading') * 1 > max_odometer_in_kms) {
          return max_odometer_in_kms;
        } else {
          return (max_odometer_in_kms || 1) * 10000000; // Set a high value so validations can pass
        }
      } else {
        // Dont let setting a greater odometer value for a past date
        if (moment(this.get('refueled_at'), dateFormat) < moment(this.get('power_unit.max_refueled_at'), dateFormat)) {
          return max_odometer_in_kms;
        }
      }
    }),

    odometerInKmsChanged: Ember.observer('odometer_in_kms', function () {
      var odometer_in_miles = (this.get('odometer_in_kms') * 0.621371).toFixed(2);
      this.set('odometer_reading', Math.round(odometer_in_miles));
    }),

    litersChanged: Ember.observer('quantity_of_fuel_liters', function () {
      var total_gallons = (this.get('quantity_of_fuel_liters') * 0.264172).toFixed(5);
      this.set('quantity_of_fuel', total_gallons);
    }),

    pricePerLiterChanged: Ember.observer('price_per_liter', function () {
      var price_per_gallon = (parseFloat(this.get('price_per_liter')) / 0.264172).toFixed(5);
      this.set('price_per_gallon', price_per_gallon);
    }),

    ready: function ready() {
      var refueled_at = this.get('refueled_at');
      if (refueled_at && moment(refueled_at).isValid()) {
        this.set('date', moment(this.get('refueled_at')).format('MM/DD/YYYY'));
        this.set('time', moment(this.get('refueled_at')).format('h:mm A'));
      }
      if (this.get('inCanada') && parseFloat(this.get('price_per_gallon')) > 0) {
        this.set('price_per_liter', (this.get('price_per_gallon') * 0.264172).toFixed(2));
        this.set('quantity_of_fuel_liters', (this.get('quantity_of_fuel') / 0.264172).toFixed(2));
      }
      if (parseFloat(this.get('odometer_reading')) > 0) {
        var odometer_in_kms = (this.get('odometer_reading') * 1.60934).toFixed(2);
        this.set('odometer_in_kms', Math.round(odometer_in_kms));
      }
    },

    datetimeChanged: Ember.observer('date', 'time', function () {
      var time = this.get('time');
      var date = this.get('date');
      if (date && time) {
        time = moment(this.get('time'), 'h:mm A');
        this.set('refueled_at', moment(date).set({ hour: time.hour(), minute: time.minute() }).toDate());
      }
    }),

    dateChanged: Ember.observer('date', function () {
      if (moment(this.get('date')).isValid()) {
        this.set('date', moment(this.get('date')).format('MM/DD/YYYY'));
      }
    }),

    fuel_type_fallback: computed('fuel_type', function () {
      return this.get('fuel_type') || 'notAvailable';
    }),

    total: computed('quantity_of_fuel', 'quantity_of_fuel_liters', 'price_per_gallon', function () {
      var quantity_of_fuel = 0;
      var price_per_unit = 0;
      if (this.get('inCanada')) {
        quantity_of_fuel = parseFloat(this.get('quantity_of_fuel_liters'));
        price_per_unit = parseFloat(this.get('price_per_liter'));
      } else {
        quantity_of_fuel = parseFloat(this.get('quantity_of_fuel'));
        price_per_unit = parseFloat(this.get('price_per_gallon'));
      }
      if (quantity_of_fuel > 0 && price_per_unit) {
        return parseFloat(quantity_of_fuel * price_per_unit).toFixed(2);
      } else {
        return 0;
      }
    })
  });
});