define('webapp/models/log-line-change', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    sequence_id: (0, _attr.default)('string'),
    date: (0, _attr.default)('datetime'),
    new_values: (0, _attr.default)('object'),
    old_values: (0, _attr.default)('object'),
    created_at: (0, _attr.default)('datetime_utc'),
    updated_at: (0, _attr.default)('datetime_utc')

  });
});