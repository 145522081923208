define('webapp/pods/organization/loads/components/current-progress/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject,
      on = Ember.on;


  var ROUTES = ['pickup', 'dropoff', 'cargo', 'equipment', 'driver', 'summary'];

  exports.default = Ember.Component.extend({
    classNames: ['current', 'progress'],

    routing: inject.service('-routing'),

    routes: computed(function () {
      return ROUTES;
    }),

    currentRoute: computed(function () {
      return this.get('routing').get('currentRouteName');
    })

  });
});