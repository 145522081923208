define('webapp/pods/organization/loads/components/driver-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",
    store: Ember.inject.service(),

    isSelected: Ember.computed('driver', 'haul.driver_assignments.@each.driver.id', function () {
      var self = this;
      var assignments = this.get('haul.driver_assignments');
      var result = false;

      assignments.forEach(function (assignment) {
        if (assignment.get('driver.id') === self.get('driver.id')) {
          result = true;
          return result;
        }
      });

      return result;
    }),

    actions: {
      add_driver: function add_driver() {
        if (this.get('isSelected')) {
          var self = this;
          // remove the driver-assignment
          this.get('haul.driver_assignments').forEach(function (assignment) {
            if (assignment.get('driver.id') === self.get('driver.id')) {
              assignment.destroyRecord();
            }
          });
        } else {
          // destroy previous driver if not a team driving haul
          if (!this.get('haul.team_drivers')) {
            var _assignment = this.get('haul.driver_assignments.firstObject');
            if (_assignment) {
              _assignment.destroyRecord();
            }
          }
          // add a new driver-assignment
          var assignment = this.get('store').createRecord('driver-assignment', {
            driver: this.get('driver'),
            haul: this.get('haul')
          });
          assignment.save();
        }
      }
    }
  });
});