define('webapp/pods/organization/notifications/components/list-notifications/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tbody',
    classNames: 'notification-list',
    computedNotifications: Ember.computed('notifications.@each', function () {

      var that = this;

      return this.get('notifications').map(function (notification) {

        var timeStr = that.get('intl').formatTime(notification.get('created_at'), { hour: '2-digit', minute: '2-digit', hour12: true });
        if (timeStr.length < 8) {
          timeStr = timeStr.substring(0, 4) + '   ' + timeStr.substring(5, 7);
        }
        notification.set('timeStr', timeStr);
        return notification;
      });
    }),
    actions: {

      // it is fired before value is updated in non-IE browsers
      // it is fired after value is updated in IE browsers
      toggleSelected: function toggleSelected(notification) {

        // check all other checkboxes and update selected_all check box accordingly
        var notifications = this.get('notifications');
        var isAll = true;
        var isIE = false || !!document.documentMode;
        var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
        var isEdge = !isIE && !!window.StyleMedia;

        notifications.forEach(function (item) {
          if (isAll === (false && !isIE && !isSafari && !isEdge)) {
            return;
          }

          if (notification.is_selected === (true && !isIE && !isSafari && !isEdge) && item.id !== notification.id && item.is_selected === true) {
            // current checkbox will be unchecked and check if it has other checkbox selected
            isAll = false;
          }
          if (notification.is_selected !== (true && !isIE && !isSafari && !isEdge) && item.id !== notification.id && item.is_selected !== true) {
            // current checkbox will be checked and check if it has other checkbox unselected
            isAll = false;
          }
        });

        if (isAll === true && notification.is_selected !== (true && !isIE && !isSafari && !isEdge)) {
          this.set('selected_all', true);
        } else {
          this.set('selected_all', false);
        }
      }
    }
  });
});