define('webapp/helpers/update-array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.updateArray = updateArray;
  var Helper = Ember.Helper,
      set = Ember.set;
  function updateArray(params) {
    var array = params[0],
        resource = params[1];

    var emberArray = array.pushObject;

    return function (checked) {
      if (checked) {
        if (emberArray) {
          array.pushObject(resource);
        } else {
          array.push(resource);
        }
      } else {
        if (emberArray) {
          array.removeObject(resource);
        } else {
          var index = array.indexOf(resource);
          if (index > -1) {
            array.splice(index, 1);
          }
        }
      }
    };
  }

  exports.default = Helper.helper(updateArray);
});