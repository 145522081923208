define('webapp/models/bid', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var Validations = (0, _emberCpValidations.buildValidations)({
    organization: {
      validators: [(0, _emberCpValidations.validator)('ds-error'),
      // Don't include belongs-to for validation
      (0, _emberCpValidations.validator)('presence', true)]
    },
    listing: {
      validators: [(0, _emberCpValidations.validator)('ds-error'),
      // Don't include belongs-to for validation
      (0, _emberCpValidations.validator)('presence', true)]
    },
    status: {
      descriptionKey: "status",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
        in: ['in_process', 'cancelled', 'awarded', 'blocked']
      })]
    }
  });

  exports.default = _model.default.extend(Validations, {

    amount: (0, _attr.default)('string'),
    payment_terms: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    created_at: (0, _attr.default)('datetime_utc'),
    updated_at: (0, _attr.default)('datetime_utc'),

    organization: (0, _relationships.belongsTo)(),
    listing: (0, _relationships.belongsTo)(),
    drivers: (0, _relationships.hasMany)('drivers', { inverse: null, async: true }),
    offers: (0, _relationships.hasMany)('offers', { async: false }),

    is_blocked: computed.equal('status', 'blocked'),
    is_offered: computed.equal('status', 'offered'),
    is_awarded: computed.equal('status', 'awarded'),
    is_cancelled: computed.equal('status', 'cancelled'),
    is_in_process: computed.equal('status', 'in_process'),

    ncx_fee: computed('amount', function () {
      if (this.get('amount')) {
        // round to two decimal points
        return Number(Math.round(this.get('amount') * 0.05 + 'e2') + 'e-2');
      } else {
        return 0;
      }
    }),

    // carrier is the one who bids
    carrier: computed.alias('organization'),

    // shipper is the one that creates the listing
    shipper: computed.alias('listing.organization'),

    waitingForOrganization: computed('status', 'offers.[]', function () {
      var status = this.get('status');
      var carrier = this.get('carrier');
      var shipper = this.get('shipper');

      if (status === 'in_process') {
        var offers = this.get('offers').sortBy('created_at');
        var last = offers.lastObject;

        if (last.get('is_counteroffer')) {
          return carrier;
        } else {
          return shipper;
        }
      }

      if (status === 'offered') {
        return this.get('carrier');
      }

      // any other status (cancelled, withdrawn, blocked) is none
      return null;
    }).volatile(),
    // Make this property volatile so it recomputes after an offer is made

    waiting_on_you: Ember.computed('waitingForOrganization', function () {
      return this.get('waitingForOrganization') === this.get('organization');
    }).volatile()
    // Make this property volatile so it recomputes after an offer is made

  });
});