define('webapp/pods/organization/locations/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    actions: {
      delete: function _delete(location) {
        var _this = this;

        location.destroyRecord().then(function () {
          return _this.transitionTo('organization.locations.index');
        }).catch(function () {
          location.rollbackAttributes();
          alert(_this.get('intl').t('unableDeleteLocation'));
        });
      }
    }
  });
});