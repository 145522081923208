define('webapp/components/ifta-enabled-drivers/component', ['exports', 'semantic-ui-ember/components/ui-modal'], function (exports, _uiModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = _uiModal.default.extend({

    store: inject.service(),

    closable: true,
    observeChanges: true,
    classNames: ['ifta-enabled-drivers'],

    drivers: computed('driverIds', function () {
      var _this = this;

      return this.get('driverIds').map(function (id) {
        return _this.get('store').peekRecord('driver', id);
      });
    }),

    actions: {
      cancel: function cancel() {
        this.execute('hide');
      }
    }

  });
});