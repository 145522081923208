define("webapp/transforms/time-text", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var format = "h:mm A";
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(value) {
      // Always format time in UTC, otherwise it Daylight savings does some weird stuff on the return object
      return moment.utc(value).format(format);
    },
    serialize: function serialize(value) {
      // Always format time in UTC, otherwise it Daylight savings does some weird stuff on the return object
      return value ? moment.utc(value, format).toJSON() : null;
    }
  });
});