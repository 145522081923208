define('webapp/services/language', ['exports', 'webapp/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      run = Ember.run;


  var apiKey = _environment.default.googleMaps.apiKey;
  var apiVersion = _environment.default.googleMaps.apiVersion;
  var apiLibraries = _environment.default.googleMaps.libraries.join(',');

  exports.default = Ember.Service.extend({

    intl: inject.service(),

    changeLanguage: function changeLanguage(lang) {
      var _this = this;

      this.get('intl').setLocale(lang);

      if (window.google && window.google.maps) {
        delete google.maps;
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {

        var gmapURL = 'https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&v=' + apiVersion + '&libraries=' + apiLibraries;

        Ember.$.getScript(gmapURL + '&language=' + lang, function () {
          run.scheduleOnce('afterRender', _this, function () {
            if (!(window.google && window.google.maps)) {
              return reject();
            }
            resolve();
          });
        }).fail(function () {
          reject();
        });
      });
    }
  });
});