define('webapp/pods/organization/loads/index/route', ['exports', 'common/mixins/live-sync', 'webapp/mixins/route-modals', 'uncharted-ajax'], function (exports, _liveSync, _routeModals, _unchartedAjax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      on = Ember.on;


  var mappings = {
    load: ['operations', 'operations.images', 'operations.location', 'organization', 'operations.location.address', 'haul', 'haul.driver-assignments', 'haul.driver-assignments.driver'].join(','),
    haul: ['driver-assignments', 'driver-assignments.driver'].join(','),
    load_operation: ['location', 'location.address'].join(',')
  };

  exports.default = Ember.Route.extend(_routeModals.default, (0, _liveSync.default)(['driver', 'haul', 'load', 'load_operation'], mappings), {

    sync: inject.service(),

    queryParams: {
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      },
      sort_by: {
        refreshModel: true
      },
      direction: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var filter = {
        organization_id: this.get('session.currentOrganization.id')
      };

      if (params.status) {
        filter.status = params.status;
      }

      if (params.search) {
        filter.search = params.search;
      }

      var sort_by = '-created_at';
      if (params.sort_by) {
        if (params.direction === 'desc') {
          sort_by = '-' + params.sort_by;
        } else {
          sort_by = params.sort_by;
        }
      }

      return this.store.query('load', {
        filter: filter,
        include: ['organization', 'invoice', 'operations', 'operations.images', 'operations.location', 'operations.cargos', 'operations.location.address', 'haul', 'haul.driver-assignments', 'haul.driver-assignments.driver'].join(','),
        sort: sort_by,
        page: {
          number: params.page || 1,
          size: params.page_size || 10
        }
      }).then(function (loads) {
        loads.forEach(function (load) {
          return load.set('initial_status', load.get('status'));
        });
        return loads;
      });
    },

    // sync

    subscribe: on('activate', function () {
      this.get('sync').on('created', this, this.checkForUpdates);
      this.get('sync').on('updated', this, this.checkForUpdates);
    }),

    unsubscribe: on('deactivate', function () {
      this.get('sync').off('created', this, this.checkForUpdates);
      this.get('sync').off('updated', this, this.checkForUpdates);
    }),

    checkForUpdates: function checkForUpdates(model, type) {
      // We only need to pay attention to loads to add to the collection
      if (type === 'load') {
        var controller = this.controllerFor('organization.loads.index');
        model.set('initial_status', model.get('status'));
        model.set('hasUpdated', true);
        // only push the model if non-existent in the controller's model (loads)
        controller.get('model').addObject(model);
      }
    }
  });
});