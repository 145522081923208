define('webapp/serializers/fuel-stop', ['exports', 'webapp/pods/application/serializer'], function (exports, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      if (key !== 'date' && key !== 'time' && key !== 'step' && key !== 'quantity_of_fuel_liters' && key !== 'price_per_liter' && key !== 'odometer_in_kms') {
        return this._super.apply(this, arguments);
      }
    }
  });
});