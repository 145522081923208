define('webapp/models/load-delay', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    organization: {
      descriptionKey: "organizationMenuLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'),
      // Don't include belongs-to for validation
      (0, _emberCpValidations.validator)('presence', true)]
    },

    haul: {
      descriptionKey: "haul",
      validators: [(0, _emberCpValidations.validator)('ds-error'),
      // validator('belongs-to'),
      (0, _emberCpValidations.validator)('presence', true)]
    },

    load: {
      descriptionKey: "load",
      validators: [(0, _emberCpValidations.validator)('ds-error'),
      // validator('belongs-to'),
      (0, _emberCpValidations.validator)('presence', true)]
    }

  });

  exports.default = _model.default.extend(Validations, {

    latitude: (0, _attr.default)('number'),
    longitude: (0, _attr.default)('number'),
    reason: (0, _attr.default)('string'),
    delayed_at: (0, _attr.default)('datetime_utc'),

    organization: (0, _relationships.belongsTo)(),
    haul: (0, _relationships.belongsTo)(),
    load: (0, _relationships.belongsTo)()

  });
});