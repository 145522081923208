define('webapp/models/load-operation-audit', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'webapp/mixins/statuses', 'moment'], function (exports, _model, _attr, _relationships, _statuses, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend(_statuses.default, {

    status: (0, _attr.default)('string'),
    created_at: (0, _attr.default)('datetime_utc'),

    organization: (0, _relationships.belongsTo)(),
    haul: (0, _relationships.belongsTo)(),
    load: (0, _relationships.belongsTo)(),
    operation: (0, _relationships.belongsTo)('load-operation'),

    created_at_unix: computed('created_at', function () {
      return (0, _moment.default)(this.get('created_at')).valueOf();
    }),

    localCreatedAt: computed('created_at', function () {
      var tz = this.get('operation.location.address.timezone');
      var locale = this.get('intl').get('locale')[0];
      return _moment.default.utc(this.get('created_at')).tz(tz).locale(locale);
    }),

    tracking_message: computed('status', function () {
      var t_label = this.get('status') + this.get('operation.source_type');
      var company_name = this.get('operation.location.company_name');
      return this.get('intl').t(t_label, { company: company_name });
    })

  });
});