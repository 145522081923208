define('webapp/models/load-image', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      isBlank = Ember.isBlank;


  var Validations = (0, _emberCpValidations.buildValidations)({
    organization: {
      descriptionKey: "organizationMenuLabel",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },

    haul: {
      descriptionKey: "haul",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },

    load: {
      descriptionKey: "load",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    },

    operation: {
      descriptionKey: "operation",
      validators: [(0, _emberCpValidations.validator)('ds-error')]
    },

    file: {
      descriptionKey: "file",
      validators: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', true)]
    }
  });

  exports.default = _model.default.extend(Validations, {

    image_url: (0, _attr.default)('string'),
    created_at: (0, _attr.default)('datetime_utc'),
    updated_at: (0, _attr.default)('datetime_utc'),
    taken_at: (0, _attr.default)('datetime_utc'),

    organization: (0, _relationships.belongsTo)(),
    haul: (0, _relationships.belongsTo)(),
    load: (0, _relationships.belongsTo)(),
    operation: (0, _relationships.belongsTo)('load-operation'),

    image_extension: computed('image_url', function () {
      if (isBlank(this.get('image_url'))) {
        return "jpg";
      }
      return (this.get('image_url').substr(this.get('image_url').lastIndexOf('.') + 1) || 'jpg').toLowerCase();
    })

  });
});