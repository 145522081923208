define('webapp/initializers/warnings-to-ignore', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // removes useless Ember Data warnings such as:

  // ember-console.js:24 WARNING: You pushed a record of type 'organization' with a relationship 'hauls' configured as 'async: false'. You've included a link but no primary data, this may be an error in your payload.
  // ember-console.js:24 WARNING: You pushed a record of type 'organization' with a relationship 'locations' configured as 'async: false'. You've included a link but no primary data, this may be an error in your payload.

  // (the link is enough to synchronously resolve the model)

  var IGNORED_WARNINGS = ['ds.store.push-link-for-sync-relationship'];

  exports.default = {
    name: 'warnings-to-ignore',
    initialize: function initialize() {
      Ember.Debug.registerWarnHandler(function (message, options, next) {
        if (!ignoreWarning(options)) {
          next.apply(undefined, arguments);
        }
      });
    }
  };


  function ignoreWarning(options) {
    return options && options.id && IGNORED_WARNINGS.includes(options.id);
  }
});