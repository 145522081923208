define('webapp/models/load-path', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({

    order: (0, _attr.default)('number'),
    type: (0, _attr.default)('string'),
    starting: (0, _attr.default)('object'),
    ending: (0, _attr.default)('object'),
    coordinates: (0, _attr.default)('array'),
    path: (0, _attr.default)('array'),
    created_at: (0, _attr.default)('datetime_utc'),
    updated_at: (0, _attr.default)('datetime_utc'),

    organization: (0, _relationships.belongsTo)(),
    load: (0, _relationships.belongsTo)(),
    operation: (0, _relationships.belongsTo)('load-operation'),

    is_tracking: computed.equal('type', 'tracking'),
    is_missing: computed.equal('type', 'missing'),
    is_estimated: computed.equal('type', 'estimated')

  });
});