define('webapp/mixins/update-dropdown-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    updateDropdownList: function updateDropdownList(from, to) {
      if (this.$(from)) {
        this.$(to).text(this.$(from).text());
      }
    }
  });
});