define('webapp/services/pusher', ['exports', 'ember-pusher/services/pusher', 'npm:pusher-js'], function (exports, _pusher, _npmPusherJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _pusher.default;
    }
  });
});