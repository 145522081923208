define('webapp/pods/organization/loads/components/load-cargo/component', ['exports', 'common/mixins/colors'], function (exports, _colors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend(_colors.default, {

    submitted: false,

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('model.dropoffs.length') === 1) {
        this.get('model.pickups').forEach(function (pickup) {
          pickup.get('cargos').forEach(function (cargo) {
            cargo.set('destinations.firstObject.all_selected', true);
          });
        });
      }
    },


    // computed properties

    pickups: computed('model.pickups.[]', function () {
      return this.get('model.pickups').sortBy('actual_date');
    }),

    actions: {
      setRowMaxUnits: function setRowMaxUnits(cargo, destination) {
        var destinations = cargo.get('destinations');
        var selectedValue = !destination.get('all_selected'); // get value and toggle it
        // If checkbox is selected, uncheck the rest checkboxes of the same row
        if (selectedValue) {
          destinations.forEach(function (destination) {
            destination.set('all_selected', false);
          });
        }
        // Finally, set value of the clicked checkbox
        destination.set('all_selected', selectedValue);
      }
    }

  });
});