define('webapp/helpers/margin-calculator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.marginCalculator = marginCalculator;
  function marginCalculator(number) {
    if (number > 0) {
      return Ember.String.htmlSafe('calc(' + number + '% - 2px)');
    } else {
      return 0;
    }
  }

  exports.default = Ember.Helper.helper(marginCalculator);
});