define('webapp/models/daily-ifta-aggregate', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    state: (0, _attr.default)('string'),
    country: (0, _attr.default)('string'),
    fuel_type: (0, _attr.default)('string'),
    quantity_of_fuel: (0, _attr.default)('number'),
    fuel_cost: (0, _attr.default)('number'),
    miles_driven: (0, _attr.default)('number'),
    mpg: (0, _attr.default)('number')
  });
});