define('webapp/pods/user/record-fuel-purchase/step-one/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('fuel-stop', { step: 'one' });
    },
    setupController: function setupController(controller, model) {
      var org_id = this.get('session.currentOrganization.id');
      var org = this.store.findRecord('organization', org_id, { include: 'drivers,power-units' });
      var country = this.get('session.currentOrganization.address.country');
      model.set('country', country);
      controller.set('model', model);
      org.then(function (org) {
        controller.set('submitted', false);
        controller.set('drivers', org.get('active_drivers'));
        controller.set('power_units', org.get('power_units'));
      });
    }
  });
});