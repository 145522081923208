define('webapp/pods/itm-tracking/controller', ['exports', 'ember-data', 'common/mixins/map-colors', 'common/mixins/colors', 'common/mixins/icons'], function (exports, _emberData, _mapColors, _colors, _icons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var availableTimeFrames = [1, 3, 6, 12, 24, 48, 96, 168, 336];

  exports.default = Ember.Controller.extend(_mapColors.default, _colors.default, _icons.default, {
    color: null,
    current_style: null,
    current_latitude: 34.0800,
    current_longitude: -118.2500,
    current_zoom: 10,
    editing: false,
    timeFrame: 24,
    availableTimeFrames: availableTimeFrames,
    showPathTracking: false,
    showTray: false,
    startDate: null,
    endDate: null,

    initialize: Ember.on('init', function () {
      this.set('current_style', this.get('map_colors.gray'));

      // this.set('circle', {
      //   path: google.maps.SymbolPath.CIRCLE,
      //   fillColor: 'red',
      //   fillOpacity: 0.4,
      //   scale: 4.5,
      //   strokeColor: 'red',
      //   strokeWeight: 1
      // });
      // this.set('transparentCircle', {
      //   path: google.maps.SymbolPath.CIRCLE,
      //   fillOpacity: 0,
      //   strokeWeight: 0
      // });
      this.set('color', this.assign_color('dual_assigned_colors', this.get('hardware_unit_id'), 'next_dual_color'));
    }),

    todays_date: Ember.computed(function () {
      return new Date();
    }),

    isCustom: Ember.computed('timeFrame', function () {
      return this.get('timeFrame') === 'custom';
    }),

    actions: {
      refetchCoordinates: function refetchCoordinates() {
        if (this.get('startDate') && this.get('endDate')) {
          this.set('start_date', moment(this.get('startDate')).unix());
          this.set('end_date', moment(this.get('endDate')).unix() + 60 * 60 * 24);
        } else {
          this.set('start_date', null);
          this.set('end_date', null);
        }
        this.set('time_frame', this.get('timeFrame'));
      }
    }

  });
});