define('webapp/serializers/bid', ['exports', 'webapp/pods/application/serializer'], function (exports, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      // We're not sending amount or payment_terms since these are part of the last offer
      if (key !== 'amount' && key !== 'payment_terms') {
        return this._super.apply(this, arguments);
      }
    }
  });
});