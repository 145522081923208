define('webapp/pods/organization/dispatch/index/details/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        drivers: this.store.query('driver', {
          filter: {
            current_organization_id: this.get('session.currentOrganization.id'),
            assigned: false,
            status: 'active'
          },
          page: {
            size: 100
          }
        }),
        hauls: this.store.query('haul', {
          filter: {
            organization_id: this.get('session.currentOrganization.id'),
            status: 'active'
          }, include: ['active-driver', 'driver-assignments', 'driver-assignments.driver', 'loads', 'loads.operations', 'loads.operations.location', 'loads.operations.location.address', 'loads.paths', 'loads.delays', 'loads.listing.organization'].join(',')
        })

      });
    },
    setupController: function setupController(controller, hash) {
      controller.set('drivers', hash.drivers);
      controller.set('hauls', hash.hauls);
    }
  });
});