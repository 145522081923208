define('webapp/pods/organization/loads/components/delete-modal/component', ['exports', 'webapp/mixins/ss-modal'], function (exports, _ssModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_ssModal.default, {

    classNames: ['delete-modal'],

    actions: {
      deleteLoad: function deleteLoad() {
        var load = this.get('load');
        var transition = this.get('transition');
        return this.get('deleteTask').perform(load, transition);
      }
    }

  });
});