define('webapp/pods/organization/loads/components/cancel-modal/component', ['exports', 'semantic-ui-ember/components/ui-modal'], function (exports, _uiModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _uiModal.default.extend({

    closable: false,
    observeChanges: true,
    classNames: ['cancel-load-modal'],

    didReceiveAttrs: function didReceiveAttrs() {
      // set default cancellation reason
      this.set('cancellationReason', this.get('reasons.firstObject'));
    },


    reasons: ['shipper_cancelled', 'equipment_failure', 'major_accident', 'major_breakdown', 'theft_of_goods', 'vehicle_fire'],

    onDeny: function onDeny() {
      var load = this.get('load');
      var transition = this.get('transition');
      return this.get('cancelTask').perform(load, this.get('cancellationReason'));
    },


    actions: {
      cancel: function cancel() {
        this.execute('hide');
      }
    }

  });
});