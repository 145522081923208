define('webapp/components/masked-input', ['exports', 'ember-text-mask/components/masked-input'], function (exports, _maskedInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _maskedInput.default;
    }
  });
});