define('webapp/pods/users/signup/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var organization = this.store.createRecord('organization', {
        org_type: params.org_type,
        verified: false,
        delivers_in_usa: true,
        validate_dot_number: params.org_type === 'carrier'
      });
      var address = this.store.createRecord('organization-address', {
        organization: organization,
        country: 'US'
      });
      organization.set('address', address);
      var user = this.store.createRecord('user', {
        organization: organization,
        role: "owner",
        status: "active"
      });

      return { organization: organization, address: address, user: user };
    },
    setupController: function setupController(controller, hash) {
      controller.set('organization', hash.organization);
      controller.set('address', hash.address);
      controller.set('user', hash.user);
    },


    actions: {
      willTransition: function willTransition() {
        var hash = this.modelFor(this.routeName);
        if (hash.organization && hash.organization.get('isNew')) {
          hash.organization.destroyRecord();
        }
        if (hash.address && hash.address.get('isNew')) {
          hash.address.destroyRecord();
        }
        if (hash.user && hash.user.get('isNew')) {
          hash.user.destroyRecord();
        }
      }
    }
  });
});