define('webapp/models/card-payment-method', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Model$extend;

  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend((_Model$extend = {
    kind: attr('string'),
    cardType: attr('string'),
    cardLastFour: attr('string'),
    cardExpirationMonth: attr('string'),
    cardExpirationYear: attr('string'),
    cardholderName: attr('string'),
    organization: belongsTo(),
    cardExpires: Ember.computed('cardExpirationMonth', 'cardExpirationYear', function () {
      return this.get('cardExpirationMonth') + '/' + this.get('cardExpirationYear');
    })

  }, _Model$extend['organization'] = belongsTo(), _Model$extend));
});