define('webapp/helpers/format-currency', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject;
  exports.default = Ember.Helper.extend({

    intl: inject.service(),

    compute: function compute(_ref) {
      var number = _ref[0];

      var min = 0,
          max = 0;
      if (number && !isNaN(number)) {
        var isInteger = Number.isInteger(parseFloat(number));
        if (!isInteger) {
          min = 2;
          max = 2;
        }
      } else {
        number = 0;
      }

      number = this.intl.formatNumber(number, { minimumFractionDigits: min, maximumFractionDigits: max });

      return '$' + number;
    }
  });
});