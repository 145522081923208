define('webapp/initializers/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {

    Ember.Route.reopen({
      beforeModel: function beforeModel() {

        if (!this.hasOwnProperty('parentModel')) {

          Object.defineProperty(this, 'parentModel', {
            get: function get() {
              var handlerInfos = void 0,
                  i = void 0,
                  info = void 0,
                  len = void 0,
                  parent = void 0,
                  r = void 0;
              r = this.router._routerMicrolib;
              if (r.activeTransition) {
                handlerInfos = r.activeTransition.state.handlerInfos;
              } else {
                handlerInfos = r.state.handlerInfos;
              }
              if (!handlerInfos) {
                return null;
              }
              parent = this;
              for (i = 0, len = handlerInfos.length; i < len; i++) {
                info = handlerInfos[i];
                if (info.handler === this) {
                  break;
                }
                parent = info.handler;
              }

              if (parent === this) {
                return null;
              }

              return this.modelFor(parent.routeName);
            },

            enumerable: true
          });
        }

        this._super.apply(this, arguments);
      }
    });
  }

  exports.default = {
    name: 'route',
    initialize: initialize
  };
});