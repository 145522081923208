define('webapp/pods/organization/loads/components/view-driver-modal/component', ['exports', 'semantic-ui-ember/components/ui-modal'], function (exports, _uiModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _uiModal.default.extend({

    observeChanges: true,

    actions: {
      edit: function edit() {
        // Total hack
        Ember.run.later(this.get('controller'),
        // This is in the controllers context
        function (driver) {
          this.send("open_modal", "organization/loads/components/edit-driver-modal", { driver: driver });
        }, this.get('driver'), 1000);
        this.execute("hide");
      }
    }

  });
});