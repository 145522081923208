define('webapp/pods/organization/unverified/controller', ['exports', 'uncharted-ajax'], function (exports, _unchartedAjax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {
      redirect: function redirect() {
        this.transitionToRoute('organization.loads.index');
      }
    }
  });
});