define('webapp/serializers/organization', ['exports', 'webapp/pods/application/serializer'], function (exports, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      if (key !== 'validate_dot_number' && key !== 'eld_utc_offset' && key !== 'ifta_reporting') {
        return this._super.apply(this, arguments);
      }
    }
  });
});