define('webapp/models/itm-delay', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    latitude: (0, _attr.default)(),
    longitude: (0, _attr.default)(),
    start_timestamp: (0, _attr.default)(),
    end_timestamp: (0, _attr.default)()
  });
});