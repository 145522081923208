define('webapp/services/calendar-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    // The current item being dragged
    current: null

  });
});