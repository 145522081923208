define('webapp/utils/catch-real', ['exports', 'ember-data', 'ember-cp-validations/validations/result-collection'], function (exports, _emberData, _resultCollection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (execute_if_real) {
    return function (error) {
      // Validation error, will show up on the model
      if (error instanceof _emberData.default.InvalidError) {
        return;
      }
      if (error instanceof _resultCollection.default) {
        return;
      }
      if (error.name === 'TaskCancelation') {
        return;
      }
      return execute_if_real(error);
    };
  };
});