define('webapp/pods/organization/loads/components/load-equipment/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      inject = Ember.inject;
  exports.default = Ember.Component.extend({

    submitted: false,
    haul: computed.alias('model.haul'),

    equipment: inject.service(),

    actions: {
      confirm: function confirm(value) {
        var model = this.get('model');
        model.set('eld_exempt', value);
        if (value) {
          this.send("open_modal", "organization/loads/components/confirm-eld-exemption-modal", { load: model });
        }
      }
    }

  });
});